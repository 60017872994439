import { useCallback, useState } from "react";
import useHttp from "./useHttp";
import dayjs from "dayjs";
import { failureNotification } from "../pages/utils/CommonNotifications";

export default function useMessageLogs(appProperties) {
  const { fetchData, fetchDataByPagination } = useHttp();
  const [loading, setLoading] = useState(false);
  
  /**
   * Fetch message logs with optional filters
   * @param {Object} filters - Filter parameters (sender, receiver, status, startTime, endTime, userName)
   * @param {Object} pagination - Pagination parameters (limit, lastKey)
   * @returns {Promise<Object>} - Message log data and pagination info
   */
  const getMessageLogs = useCallback(async (filters = {}, pagination = {}) => {
    try {
      setLoading(true);
      // Get organization ID from app properties
      const orgId = appProperties.osyncId;
      
      // Construct the API URL with filters
      let url = `omessage/messagelogs?`;
      
      // Add filters
      if (filters.sender) {
        url += `&sender=${encodeURIComponent(filters.sender)}`;
      }
      
      if (filters.receiver) {
        url += `&receiver=${encodeURIComponent(filters.receiver)}`;
      }
      
      if (filters.status && filters.status !== 'all') {
        url += `&status=${encodeURIComponent(filters.status.toUpperCase())}`;
      }
      
      if (filters.errorCode) {
        url += `&errorCode=${encodeURIComponent(filters.errorCode)}`;
      }
      
      if (filters.startDate) {
        const startTime = filters.startDate.toISOString();
        url += `&startTime=${encodeURIComponent(startTime)}`;
      }
      
      if (filters.endDate) {
        const endTime = filters.endDate.toISOString();
        url += `&endTime=${encodeURIComponent(endTime)}`;
      }
      
      if (filters.userName) {
        url += `&userName=${encodeURIComponent(filters.userName)}`;
      }
      
      // Add campaignId filter if present
      if (filters.campaignId) {
        url += `&campaignId=${encodeURIComponent(filters.campaignId)}`;
      }
      
      // Add automationEventId filter if present
      if (filters.automationEventId) {
        url += `&automationEventId=${encodeURIComponent(filters.automationEventId)}`;
      }
      
      // Add sent_via filter if present (for campaign/automation type filtering)
      if (filters.sent_via) {
        url += `&sent_via=${encodeURIComponent(filters.sent_via)}`;
      }
      
      // Add pagination parameters
      const limit = pagination.limit || filters.limit || 100;
      url += `&limit=${limit}`;
      
      if (pagination.lastKey) {
        url += `&lastKey=${encodeURIComponent(pagination.lastKey)}`;
      }
      
      // If we're only requesting pagination info (metadata), add a flag
      if (pagination.paginationInfoOnly) {
        url += '&paginationInfoOnly=true';
      }
      
      
      // Call the API
      const response = await fetchData(
        url, 
        "GET", 
        null, 
        appProperties
      );
      
      if (response) {
        // Handle response that could be already parsed or still a string
        const responseData = typeof response === 'string' ? JSON.parse(response) : response;
        
        // Extract pagination info
        const paginationModel = responseData.paginationModel || responseData.data?.paginationModel || null;
        
        // Format the response data
        const messages = responseData.inboxLogs || responseData.data?.inboxLogs || [];
        
        // Extract phone numbers with friendly names if available
        const phoneNumbers = responseData.phoneNumbers || responseData.data?.phoneNumbers || {};
        
        const formattedMessages = messages.map(message => {
          const sender = message.sender || message.from;
          const receiver = message.receiver || message.to;
          
          // Get friendly names if available
          const senderFriendlyName = phoneNumbers[sender] || '';
          const receiverFriendlyName = phoneNumbers[receiver] || '';
          
          return {
            ...message,
            key: message.osyncId || message.messageId || message.logTime || Math.random().toString(36).substr(2, 9),
            createdTime: message.createdTime || message.logTime || message.sentTime || message.timestamp || new Date().toISOString(),
            status: (message.messageStatus || message.status || '').toLowerCase(),
            sender: message.sender || message.from,
            receiver: message.receiver || message.to,
            // Store original numbers and friendly names
            senderFriendlyName,
            receiverFriendlyName,
            messageBody: message.message || message.messageBody || message.content || message.body || '',
            userName: message.senderName || message.userName || message.user || message.sentBy || '',
            provider: message.serviceName || message.provider || message.channel || message.service || '',
            sent_via: message.sent_via,
            errorCode: message.errorCode || message.error || '',
            statusDetails: message.statusDetails || message.errorDetails || message.errorDescription || '',
            mediaFileList: message.mediaFileList || [],
          };
        });
        
        // Ensure we capture the lastKey from the response with better validation
        const hasValidLastKey = !!(paginationModel && 
                                 paginationModel.lastKey && 
                                 paginationModel.lastKey !== "" &&
                                 paginationModel.lastKey !== "null" &&
                                 paginationModel.lastKey !== "undefined");
        
        // If we have no messages, force hasMore to false regardless of lastKey
        // This ensures empty results don't show pagination
        const hasMore = formattedMessages.length > 0 ? hasValidLastKey : false;
        
        const result = {
          messages: formattedMessages,
          pagination: paginationModel,
          hasMore: hasMore
        };
        
        return result;
      }
      
      return {
        messages: [],
        pagination: null,
        hasMore: false
      };
    } catch (error) {
      console.error("Error fetching message logs:", error);
      failureNotification("Error fetching message logs");
      return {
        messages: [],
        pagination: null,
        hasMore: false
      };
    } finally {
      setLoading(false);
    }
  }, [fetchDataByPagination, appProperties]);
  
  /**
   * Fetch detailed information about a specific message
   * @param {string} messageId - ID of the message to fetch
   * @returns {Promise<Object>} - Detailed message data
   */
  const getMessageDetails = useCallback(async (messageId) => {
    try {
      setLoading(true);
      
      // Get organization ID from app properties
      const orgId = appProperties.osyncId || 'org123'; // Fallback for testing
      
      // const response = await fetchData(
      //   `omessage/${orgId}/message/${messageId}`, 
      //   "GET", 
      //   null, 
      //   appProperties
      // );
      
      // if (response) {
      //   // Handle response that could be already parsed or still a string
      //   const responseData = typeof response === 'string' ? JSON.parse(response) : response;
      //   if (responseData.data) {
      //     return {
      //       ...responseData.data,
      //       // Normalize field names in case the API returns different formats
      //       createdTime: responseData.data.createdTime || responseData.data.sentTime || responseData.data.timestamp,
      //       status: (responseData.data.status || '').toLowerCase(),
      //       sender: responseData.data.sender || responseData.data.from,
      //       receiver: responseData.data.receiver || responseData.data.to,
      //       messageBody: responseData.data.messageBody || responseData.data.content || responseData.data.body,
      //       userName: responseData.data.userName || responseData.data.user || responseData.data.sentBy,
      //       provider: responseData.data.provider || responseData.data.channel || responseData.data.service,
      //       errorCode: responseData.data.errorCode || responseData.data.error,
      //       statusDetails: responseData.data.statusDetails || responseData.data.errorDescription
      //     };
      //   }
      // }
      
      return null;
    } catch (error) {
      console.error("Error fetching message details:", error);
      failureNotification("Error fetching message details");
      return null;
    } finally {
      setLoading(false);
    }
  }, [fetchData, appProperties]);
  
  /**
   * Fetch all users who have sent messages
   * @returns {Promise<Array>} - List of users with their names/IDs
   */
  const getMessageUsers = useCallback(async () => {
    try {
      setLoading(true);
      
      const response = await fetchData(
        "users/list",  // This endpoint should be adjusted based on your API
        "GET", 
        null, 
        appProperties
      );
      
      if (response) {
        const responseData = JSON.parse(response);
        return responseData.data?.users || [];
      }
      
      return [];
    } catch (error) {
      console.error("Error fetching message users:", error);
      failureNotification("Error fetching users list");
      return [];
    } finally {
      setLoading(false);
    }
  }, [fetchData, appProperties]);
  
  return {
    loading,
    getMessageLogs,
    getMessageDetails,
    getMessageUsers,
  };
}