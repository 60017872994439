import { Badge, Button, Card, Col, Layout, Modal, Radio, Row, Skeleton, Typography } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import ".././PricingPage.css";
import YearlyPricingTag from "../assets/gif/YearlyTag.gif";
import {
  ASSIST_MAIL,
  Chargebee_PLAN_NAME_MONTHLY,
  Chargebee_PLAN_NAME_MONTHLY_OLD,
  Chargebee_PLAN_NAME_YEARLY,
  Chargebee_PLAN_NAME_YEARLY_OLD,
  COMPANY_NAME,
  HELLOSEND_BOOOKING_URL,
  LicensePlanCategory,
  VOICE_CHARGEBEE_PLAN_MONTHLY,
  VOICE_CHARGEBEE_PLAN_YEARLY,
  VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_MONTHLY,
  VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_YEARLY,
} from "../constants/AppConstants";
import useHttp from "../hooks/useHttp";
import { useScript } from "./../hooks/Hooks";
import { LoadingOutlined } from "@ant-design/icons";
import { getCurrentFeature, getPlanSwitchType } from "./../voice/utils/VoiceUtils";
import { messagePlanComparisonData, messagePlusVoicePlanComparisonData, voicePlanComparisonData } from "./pricingUtils/planComparisonData";
import { PlanModal } from "./utils/CommonNotifications";
import { UpgradeAlertPopup } from "./utils/CommonVessels";
import OTPModal from "../components/custom/OTPModal";

const { Header, Content } = Layout;
const { Text } = Typography;

function PricingPage(props) {
  const panelStyle = {
    borderTop: "2px solid #D7DEF0",
  };
  const [pricingPageData, setPricingPageData] = useState({});
  const [priceDisplayLoading, setPriceDisplayLoading] = useState(false);
  const [existingChargeBeeUser, setExistingChargeBeeUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedFeatureFromResponse, setSelectedFeatureFromResponse] = useState("");
  const [currentPlan, setCurrentPlan] = useState("");
  const [currentLicensePlanCategory, setCurrentLicensePlanCategory] = useState();
  const [switchingToPlanName, setSwitchingToPlanName] = useState();
  const [selectedFeature, setSelectedFeature] = useState("message+call");
  const [key, setKey] = useState(0);
  const [successModal, setSuccessModal] = useState(false);
  const [expandedKey, setExpandedKey] = useState(null); // Track the expanded FAQ item
  const [chargeBeePlans, setChargeBeePlans] = useState();
  const [licenseActiveUsersCount, setLicenseActiveUsersCount] = useState();
  const [upgradeAlertPopup, setUpgradeAlertPopup] = useState(false);
  const [messageMonthlyPrice, setMessageMonthlyPrice] = useState();
  const [messageYearlyPrice, setMessageYearlyPrice] = useState();
  const [messageYearlyDiscountPercentage, setMessageYearlyDiscountPercentage] = useState();

  const [voiceMonthlyPrice, setVoiceMonthlyPrice] = useState();
  const [voiceYearlyPrice, setVoiceYearlyPrice] = useState();
  const [voiceYearlyDiscountPercentage, setVoiceYearlyDiscountPercentage] = useState();

  const [voicePlusMessageMonthlyPrice, setVoicePlusMessageMonthlyPrice] = useState();
  const [voicePlusMessageYearlyPrice, setVoicePlusMessageYearlyPrice] = useState();
  const [voicePlusMessageYearlyDiscountPercentage, setVoicePlusMessageYearlyDiscountPercentage] = useState();

  const [isUpgradePlan, setIsUpgradePlan] = useState(false);
  const [isDowngradePlan, setIsDowngradePlan] = useState(false);
  const [showBookingForm, setShowBookingForm] = useState(false);
  const [spinButton, setSpinButton] = useState(false);
  const [planOptions, setPlanOptions] = useState([]); //[{ label: "Message + Call", value: "message+call" }]

  const switchPlan = (switchToPlanName) => {
    return new Promise((resolve, reject) => {
      fetchData("license/switch/plan?switchToPlan=" + switchToPlanName, "POST", null, pricingPageData)
        .then((response) => {
          const responseData = JSON.parse(response)?.data;
          console.log("Switch Plan Response", responseData);
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // Subscription Management Handlers
  const handleUpgradeSubscription = () => {
    // Upgrade logic implementation
    console.log("Upgrading subscription :: current plan: ", currentPlan, "switching to plan: ", switchingToPlanName);
    setSpinButton(true);
    switchPlan(switchingToPlanName).then((response) => {
      if (response) {
        console.log("handleUpgradeSubscription : Switch Plan Response", response);
        setIsUpgradePlan(false);
        setShowConfirmationModal(false);
        setSpinButton(false);
        setLicenseRelatedData(response?.license, null);
        setSuccessModal(true);
      }
    });
  };

  const handleDowngradeSupport = () => {
    // Downgrade or support contact logic
    console.log("Downgrading subscription :: current plan: ", currentPlan, "switching to plan: ", switchingToPlanName);
    setIsDowngradePlan(false);
    setSpinButton(false);
    setShowBookingForm(true);
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  let [isSuccessCheckout, setIsSuccessCheckout] = useState(false);
  const { fetchData } = useHttp();
  const queryString = window.location.search;
  const urlParamsObj = new URLSearchParams(queryString);
  var locationHref = window.location.origin;

  const handleRefresh = () => {
    setKey((prevKey) => prevKey + 1);
  };
  const urlParams = new URLSearchParams(window.location.search);

  const MODAL_TYPES = {
    SWITCH: "SWITCH",
    UPGRADE: "UPGRADE",
    DOWNGRADE: "DOWNGRADE",
  };

  // Modal Configuration
  const MODAL_CONFIGS = {
    [MODAL_TYPES.SWITCH]: {
      title: "Switch plan",
      message: "Switching plans will update your subscription. Prorated charges will apply and be reflected in your invoice.",
      confirmText: "Confirm",
    },
    [MODAL_TYPES.UPGRADE]: {
      title: "Plan Upgrade Confirmation",
      message:
        "Switching to this plan will upgrade your license. The prorated amount will be charged now. You can find the details in your invoice.",
      confirmText: "Confirm Upgrade",
    },
    [MODAL_TYPES.DOWNGRADE]: {
      title: "Plan Change Request",
      message: "You've chosen to downgrade your plan. Please contact support to proceed with the change.",
      confirmText: "Contact Support",
    },
  };

  const featureOptions = useMemo(
    () => ({
      message: {
        monthlyPrice: messageMonthlyPrice,
        yearlyPrice: messageYearlyPrice,
        discount: messageYearlyDiscountPercentage,
        userCount: 3,
        monthlyFeatures: ["One-on-One messaging", "Campaigns and Automation", "Assistance: Email Support"],
        yearlyFeatures: ["One-on-One messaging", "Campaigns and Automation", "Assistance: Email and Phone Support"],
        planComparisonData: messagePlanComparisonData,
        monthlyPlanNameChargeBee: Chargebee_PLAN_NAME_MONTHLY,
        yearlyPlanNameChargeBee: Chargebee_PLAN_NAME_YEARLY,
      },
      voice: {
        monthlyPrice: voiceMonthlyPrice,
        yearlyPrice: voiceYearlyPrice,
        discount: voiceYearlyDiscountPercentage,
        userCount: 3,
        monthlyFeatures: ["Intelligent Call Routing", "Call Recording ", "Assistance: Email Support"],
        yearlyFeatures: ["Intelligent Call Routing", "Call Recording ", "Assistance: Email and Phone Support"],
        planComparisonData: voicePlanComparisonData,
        monthlyPlanNameChargeBee: VOICE_CHARGEBEE_PLAN_MONTHLY,
        yearlyPlanNameChargeBee: VOICE_CHARGEBEE_PLAN_YEARLY,
      },
      "message+call": {
        monthlyPrice: voicePlusMessageMonthlyPrice,
        yearlyPrice: voicePlusMessageYearlyPrice,
        discount: voicePlusMessageYearlyDiscountPercentage,
        userCount: 3,
        monthlyFeatures: ["One-on-One/Campaigns/Automation", "Call Routing/Recording", "Assistance: Email Support"],
        yearlyFeatures: ["One-on-One/Campaigns/Automation", "Call Routing/Recording", "Assistance: Email and Phone Support"],
        planComparisonData: messagePlusVoicePlanComparisonData,
        monthlyPlanNameChargeBee: VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_MONTHLY,
        yearlyPlanNameChargeBee: VOICE_PLUS_MESSAGING_CHARGEBEE_PLAN_YEARLY,
      },
    }),
    [
      messageMonthlyPrice,
      messageYearlyPrice,
      messageYearlyDiscountPercentage,
      voiceMonthlyPrice,
      voiceYearlyPrice,
      voiceYearlyDiscountPercentage,
      voicePlusMessageMonthlyPrice,
      voicePlusMessageYearlyPrice,
      voicePlusMessageYearlyDiscountPercentage,
    ]
  );

  const calculateSavings = (monthlyRate, yearlyRate) => {
    // Monthly Plan Total Cost
    const monthlyPlanTotal = monthlyRate * 12;

    // Yearly Plan Total Cost
    const yearlyPlanTotal = yearlyRate * 12;

    // Calculate Savings
    const totalSavings = monthlyPlanTotal - yearlyPlanTotal;
    const savingsPercentage = Math.round((totalSavings / monthlyPlanTotal) * 100);

    return {
      monthlyPlanTotal: monthlyPlanTotal.toFixed(2),
      yearlyPlanTotal: yearlyPlanTotal.toFixed(2),
      totalSavings: totalSavings.toFixed(2),
      savingsPercentage: savingsPercentage + "%",
    };
  };

  useEffect(() => {
    if (!chargeBeePlans?.list) return;
    // setMonthlyPriceDisplayLoading(true);
    const findPlanPrice = (planId, divisor = 100) => {
      const plan = chargeBeePlans.list.find((item) => item.item_price.id === planId);
      return plan ? plan.item_price.price / divisor : null;
    };

    const pricingConfig = [
      {
        monthlyPlanId: featureOptions.message.monthlyPlanNameChargeBee,
        yearlyPlanId: featureOptions.message.yearlyPlanNameChargeBee,
        setMonthlyPrice: setMessageMonthlyPrice,
        setYearlyPrice: setMessageYearlyPrice,
      },
      {
        monthlyPlanId: featureOptions.voice.monthlyPlanNameChargeBee,
        yearlyPlanId: featureOptions.voice.yearlyPlanNameChargeBee,
        setMonthlyPrice: setVoiceMonthlyPrice,
        setYearlyPrice: setVoiceYearlyPrice,
      },
      {
        monthlyPlanId: featureOptions["message+call"].monthlyPlanNameChargeBee,
        yearlyPlanId: featureOptions["message+call"].yearlyPlanNameChargeBee,
        setMonthlyPrice: setVoicePlusMessageMonthlyPrice,
        setYearlyPrice: setVoicePlusMessageYearlyPrice,
      },
    ];

    pricingConfig.forEach((config) => {
      const monthlyPrice = findPlanPrice(config.monthlyPlanId);
      const yearlyPrice = findPlanPrice(config.yearlyPlanId, 1200);

      if (monthlyPrice !== null) config.setMonthlyPrice(monthlyPrice);
      if (yearlyPrice !== null) config.setYearlyPrice(yearlyPrice);
    });
    setPriceDisplayLoading(false);
  }, [chargeBeePlans, featureOptions]);
  useEffect(() => {
    if (messageMonthlyPrice && messageYearlyPrice) {
      setMessageYearlyDiscountPercentage(calculateSavings(messageMonthlyPrice, messageYearlyPrice)?.savingsPercentage);
    }
    if (voiceMonthlyPrice && voiceYearlyPrice) {
      setVoiceYearlyDiscountPercentage(calculateSavings(voiceMonthlyPrice, voiceYearlyPrice)?.savingsPercentage);
    }
    if (voicePlusMessageMonthlyPrice && voicePlusMessageYearlyPrice) {
      setVoicePlusMessageYearlyDiscountPercentage(
        calculateSavings(voicePlusMessageMonthlyPrice, voicePlusMessageYearlyPrice)?.savingsPercentage
      );
    }
  }, [voicePlusMessageMonthlyPrice, voicePlusMessageYearlyPrice, voiceMonthlyPrice, voiceYearlyPrice, messageMonthlyPrice, messageYearlyPrice]);

  const handleClickDomainUrl = () => {
    const urlParamsInbox = JSON.parse(urlParams.get("urlParams"));
    delete urlParamsInbox.sideBar;
    delete urlParamsInbox.topBar;

    const queryString = new URLSearchParams(urlParamsInbox).toString();
    window.open("/settings/users?purchased=true&" + queryString, "_self");
  };
  const [isChargebeeInitialized, setIsChargebeeInitialized] = useState(false);
  const businessEntityId = "__dev__XpbBuzpT02K2kd24";
  const chargeBeeScript = useScript("https://js.chargebee.com/v2/chargebee.js", businessEntityId);
  useEffect(() => {
    if (chargeBeeScript === "ready" && !isChargebeeInitialized) {
      var siteAttribute = "oapps";
      if (
        locationHref.indexOf("jupiter.rekzyl.com") !== -1 ||
        locationHref.indexOf("client4.rekzyl.com") !== -1 ||
        locationHref.indexOf("client2.rekzyl.com") !== -1 ||
        locationHref.indexOf("client3.rekzyl.com") !== -1 ||
        locationHref.indexOf("localhost") !== -1
      ) {
        siteAttribute = "oapps-test";
      }
      window.Chargebee.init({ site: siteAttribute });
      setIsChargebeeInitialized(true);
    }
  }, [chargeBeeScript, isChargebeeInitialized]);
  useEffect(() => {
    if (pricingPageData?.leftServiceName === "pdOmni" && currentLicensePlanCategory) {
      if (currentLicensePlanCategory !== LicensePlanCategory.MESSAGE_PLUS_VOICE) {
        setPlanOptions([
          { label: "Message", value: "message" },
          { label: "Message + Call", value: "message+call" },
        ]);
      }
    } else {
      if (currentLicensePlanCategory) {
        setPlanOptions((prevOptions) => {
          const newOptions = [
            { label: "Message", value: "message" },
            { label: "Call", value: "voice" },
            { label: "Message + Call", value: "message+call" },
          ];
          const filteredNewOptions = newOptions.filter(
            (newOption) => !prevOptions.some((existingOption) => existingOption.value === newOption.value)
          );
          return [...filteredNewOptions, ...prevOptions];
        });
      }
    }
  }, [currentLicensePlanCategory, pricingPageData]);

  const items = [
    {
      key: "1",
      label: <span className="FAQuestion hs-fs-16"> Does HelloSend extension support two-way communication?</span>,
      children: (
        <p>
          Yes, our extension supports two-way messaging, enabling interactive conversations with customers. Users can receive and respond to SMS
          / WhatsApp replies directly from the CRM system.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "2",
      label: <span className="FAQuestion hs-fs-16">How HelloSend extension help with lead conversion?</span>,
      children: (
        <p>
          By utilizing Campaigns, our HelloSend extension can nurture them by sending personalized messages, driving engagement and guiding
          prospects through the sales funnel.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label: <span className="FAQuestion hs-fs-16">Can HelloSend extension automate communication processes?</span>,
      children: (
        <p>
          Yes, our HelloSend extension allows for automated communication, such as sending automated notifications, reminders, or follow-ups
          based on CRM triggers or events
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "4",
      label: <span className="FAQuestion hs-fs-16"> What features are available during the free trial?</span>,
      children: (
        <p>
          During the free trial, you’ll have access to the complete range of features our app offers. Explore and evaluate its capabilities to
          make an informed decision.
        </p>
      ),
      style: panelStyle,
    },
    // {
    //   key: "5",
    //   label: <span className="FAQuestion hs-fs-18">How are SMS messages billed?</span>,
    //   children: (
    //     <p>
    //       With our SMS extension, we employ a subscription-based billing model that eliminates per SMS charges. Instead, you can choose from
    //       monthly or yearly subscription plans that include a specified number of SMS messages within the subscription cost. This approach
    //       ensures cost predictability and removes the need to pay for individual messages.
    //     </p>
    //   ),
    //   style: panelStyle,
    // },
    {
      key: "6",
      label: <span className="FAQuestion hs-fs-16">What’s the difference between annual and monthly billing?</span>,
      children: (
        <p>
          Monthly billing charges occur on the same day each month, while annual billing involves a single payment for the entire year, with the
          added benefit of a discounted price.
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "7",
      label: <span className="FAQuestion hs-fs-16">Are there any additional costs to consider?​</span>,
      children: <p>There are no additional costs apart from the subscription fee.</p>,
      style: panelStyle,
    },
    {
      key: "8",
      label: <span className="FAQuestion hs-fs-16">How can I contact customer support?</span>,
      children: (
        <p>
          For assistance, please email us at{" "}
          <a href={`mailto:${ASSIST_MAIL}`} style={{ textDecoration: "none" }}>
            {ASSIST_MAIL}
          </a>
          , and our team will promptly respond to your queries.
        </p>
      ),
      style: panelStyle,
    },
    // {
    //   key: "9",
    //   label: <span className="FAQuestion hs-fs-18">How is the refund processed for yearly subscriptions?</span>,
    //   children: (
    //     <p>If a yearly subscription is cancelled midway, the refund amount will be calculated based on the monthly subscription cost.</p>
    //   ),
    //   style: panelStyle,
    // },
    {
      key: "10",
      label: <span className="FAQuestion hs-fs-16">What are your phone support hours?</span>,
      children: <p>We are available from IST 10 am to IST 12 am for phone support.</p>,
      style: panelStyle,
    },
    {
      key: "11",
      label: <span className="FAQuestion hs-fs-16"> What is this subscription for?</span>,
      children: (
        <p>
          This subscription provides seamless integration with CRM services, allowing you to send and receive messages directly within your CRM
          system
        </p>
      ),
      style: panelStyle,
    },
    {
      key: "12",
      label: <span className="FAQuestion hs-fs-16"> Do I get a number with the subscription plans?</span>,
      children: (
        <p>
          No, you can purchase a number at additional cost and you need to purchase credits for sending SMS. Alternatively, you can bring your
          own number from Twilio or RingCentral{" "}
        </p>
      ),
      style: panelStyle,
    },
  ];
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    var osyncId = urlParams.get("osyncId");
    var leftServiceId = urlParams.get("leftServiceId");
    var integIds = urlParams.get("integIds");
    var leftServiceName = urlParams.get("service");
    var hash = urlParams.get("hash");
    var urlParamsObj = urlParams.get("urlParams");
    var requestedPlan = decodeURIComponent(urlParams.get("requestedPlan"));
    var decodedObj = decodeURIComponent(urlParamsObj);
    const parsedObj = JSON.parse(decodedObj);
    var urlParamsData = {};
    urlParamsData.hash = hash;
    urlParamsData.requestedPlan = requestedPlan;
    urlParamsData.companyId = parsedObj?.companyId;
    urlParamsData.osyncId = osyncId;
    urlParamsData.integIds = integIds?.split(",");
    urlParamsData.leftServiceId = leftServiceId;
    urlParamsData.leftServiceName = leftServiceName;
    urlParamsData.userId = parsedObj?.userId;
    urlParamsData.setOtpModalState = props?.props?.setOtpModalState;
    setPricingPageData(urlParamsData);

    console.log("urlParamsData>>>>>>>>>>>>>>", urlParamsData);
    if (hash) {
      setPriceDisplayLoading(true);

      fetchData("license/active/users", "GET", null, urlParamsData).then((response) => {
        console.log("responseData active>>>>>>>>>>>>>>", typeof response);
        let responseData = typeof response === "string" ? JSON.parse(response) : response;

        if (responseData) {
          let activeUsers = responseData?.data;
          let currentUserRoleId = activeUsers?.find((user) => user.remoteUserId === urlParamsData.userId)?.roleId;
          setLoading(false);
          if (currentUserRoleId === 13) {
            setUpgradeAlertPopup(true);
            return;
          }

          let activeUsersCount = responseData?.data?.length;
          console.log("responseData licenseActiveUsersCount activeUsersCount>>>>>>>>>>>>>>", activeUsersCount, responseData?.data);
          setLicenseActiveUsersCount(JSON.stringify(activeUsersCount));

          fetchData("chargebee/license", "GET", null, urlParamsData).then((response) => {
            const responseData = JSON.parse(response)?.data;
            setLicenseRelatedData(responseData, urlParamsData);

            fetchData("chargebee/plans", "GET", null, urlParamsData).then((response) => {
              const responseData = JSON.parse(response)?.data;
              console.log("responseData chi>>>>>>>>>>>>>>", responseData);

              let plansObj = typeof responseData === "string" ? JSON.parse(responseData) : responseData;
              if (plansObj) {
                let allPlansObj = typeof plansObj?.data === "string" ? JSON.parse(plansObj?.data) : plansObj?.data;
                if (allPlansObj) {
                  setChargeBeePlans(allPlansObj);
                }
              }
            });
          });
        }
      });
    }
  }, []);

  function setLicenseRelatedData(responseData, pricingPageData) {
    const planName = responseData?.planName;
    const licensePlanCategory = responseData?.licensePlanCategory;
    const subscriptionId = responseData?.subscriptionId;
    const customerId = responseData?.customerId;
    const planToUpdate = responseData?.planToUpdate;
    if (customerId && subscriptionId) {
      setExistingChargeBeeUser(true);
      if (planToUpdate) {
        setCurrentPlan(planToUpdate);
      } else {
        setCurrentPlan(planName);
      }
    }
    if (licensePlanCategory) {
      setCurrentLicensePlanCategory(licensePlanCategory);
    }
    if (planToUpdate) {
      const featureName = getCurrentFeature(null, planToUpdate);
      if (featureName === "Message") {
        setSelectedFeature("message");
      } else if (featureName === "Call") {
        setSelectedFeature("voice");
      } else {
        setSelectedFeature("message+call");
      }
    } else {
      if (pricingPageData?.requestedPlan === "message+call") {
        setSelectedFeature("message+call");
      } else if (
        planName === Chargebee_PLAN_NAME_MONTHLY ||
        planName === Chargebee_PLAN_NAME_YEARLY ||
        planName === Chargebee_PLAN_NAME_MONTHLY_OLD ||
        planName === Chargebee_PLAN_NAME_YEARLY_OLD
      ) {
        setSelectedFeature("message");
        setSelectedFeatureFromResponse("message");
      } else if (planName === VOICE_CHARGEBEE_PLAN_YEARLY || planName === VOICE_CHARGEBEE_PLAN_MONTHLY) {
        setSelectedFeature("voice");
        setSelectedFeatureFromResponse("voice");
      } else {
        setSelectedFeature("message+call");
        setSelectedFeatureFromResponse("message+call");
      }
    }
  }
  async function handleClick() {
    console.log("licenseActiveUsersCount handleClickNew >>>>>>>>>>>>>>>>>>");
    const urlParams = new URLSearchParams(window.location.search);
    var osyncId = urlParams.get("osyncId");
    var leftServiceId = urlParams.get("leftServiceId");
    var integIds = urlParams.get("integIds");
    var leftServiceName = urlParams.get("service");

    if (window.Chargebee !== undefined && window.Chargebee !== null) {
      await window.Chargebee.registerAgain();
    }
    const cbInstance = window?.Chargebee?.getInstance();
    const cart = cbInstance?.getCart();

    cart?.setCustomer({
      cf_oapps_id: osyncId,
      cf_leftserviceid: leftServiceId,
      cf_accounttype: "Omni",
      cf_servicename: leftServiceName,
    });

    cbInstance?.setCheckoutCallbacks(function (cart) {
      // you can define a custom callbacks based on cart object
      return {
        loaded: function () {},
        close: function () {
          handleRefresh();
          isSuccessCheckout ? setSuccessModal(true) : console.log("failure");
        },

        success: function (hostedPageId) {
          setIsSuccessCheckout(true);
          console.log("Hostedpageid>>", hostedPageId);
          if (hostedPageId !== null && hostedPageId !== "") {
            var url = `chargebee/license/add?hostedPageId=${hostedPageId}`;
            fetchData(url, "POST", null, pricingPageData).then((response) => {
              if (selectedFeature && selectedFeature !== "message+call") {
                let planToDeactivate = "";
                if (selectedFeature === "message") {
                  planToDeactivate = "voice";
                }
                fetchData(`plan/${planToDeactivate}/deactivate`, "PUT", null, pricingPageData).then((response) => {});
              }
            });
            handleRefresh();
          }
        },
        step: function (value) {
          if (value === "thankyou_screen") {
            isSuccessCheckout = true;
          }
        },
      };
    });
  }

  const FAQItem = ({ item, isExpanded, onToggle }) => {
    return (
      <div className={`faq-item mb-4 faq-item ${isExpanded ? "expand-outline" : ""}`}>
        <div className="hs-w-100">
          <Row>
            <Col md={20} xs={13}>
              <Row>
                <div className="hs-fs-18 hs-fw-500">{item.label}</div>
              </Row>
              <Row>{isExpanded && <div className=" pe-5  pb-0 hs-color-dark-grey faq-item-body">{item.children}</div>}</Row>
            </Col>
            <Col
              md={2}
              xs={6}
              className={` d-flex align-items-top hs-fs-24 cursor-pointer ${
                isExpanded ? "actionIconsSprite pricingExpandedTrue" : "actionIconsSprite pricingExpandedFalse"
              }`}
              onClick={onToggle}
            ></Col>
          </Row>
        </div>
      </div>
    );
  };
  const handleToggle = (key) => {
    setExpandedKey(expandedKey === key ? null : key);
  };

  const handleFeatureChange = (e) => {
    setSelectedFeature(e.target.value);
  };

  var {
    monthlyPrice,
    yearlyPrice,
    discount,
    monthlyFeatures,
    yearlyFeatures,
    planComparisonData,
    monthlyPlanNameChargeBee,
    yearlyPlanNameChargeBee,
  } = featureOptions[selectedFeature];
  const antIcon = <LoadingOutlined className="loading-button" spin />;

  return (
    <Layout className="h-100 w-100">
      {loading && !props?.props?.otpModalState ? (
        <div className="hs-h-100vh w-100 d-flex justify-content-center align-items-center">
          <LoadingOutlined spin className="hs-color-violet hs-fs-25" />
        </div>
      ) : props?.props?.otpModalState ? (
        <div>
          <OTPModal
            appPropsObject={pricingPageData}
            open={props?.props?.otpModalState}
            setLoading={setLoading}
            setOtpModalState={props?.props?.setOtpModalState}
          />
        </div>
      ) : upgradeAlertPopup ? (
        <PricingPageAccessDeniedPopup upgradeAlertPopup={upgradeAlertPopup} setUpgradeAlertPopup={setUpgradeAlertPopup} />
      ) : (
        <>
          <Header className=" pricingHeader bg-white hs-w-100 ">
            <div className=" actionIconsSprite helloSendLogoWithText w-100 h-100" alt="HelloSend"></div>
          </Header>
          <Content className="hs-bg-white  hs-h-50">
            <Row className="bg-white pricingRow  d-flex justify-content-center pricingPageHomeBackground hs-h-40-per">
              <Row className="hs-h-10 pt-4 w-100 d-flex align-items-center">
                <Col span={24} xl={24} md={22} xs={24} className="d-flex justify-content-center">
                  <h1 className="pt-5 hs-fw-700 pricingHeadingLevel d-flex align-items-center justify-content-center">
                    Flexible Pricing for Every Stage of Your journey
                  </h1>
                </Col>
              </Row>
              {planOptions.length > 0 ? (
                <Row className=" d-flex justify-content-center hs-h-10 align-items-center w-100 pt-3 pb-3">
                  <Col className="p-2 hs-border-light-violet hs-border-16">
                    <Radio.Group
                      rootClassName="pricingPage-radio-button"
                      block
                      options={planOptions}
                      value={selectedFeature}
                      optionType="button"
                      buttonStyle="solid"
                      onChange={handleFeatureChange}
                    />
                  </Col>
                </Row>
              ) : null}
              <Row className="d-flex justify-content-center  pt-4">
                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="d-flex justify-content-center monthlyPricingCard p-3">
                  <Card
                    className={` ${currentPlan === monthlyPlanNameChargeBee ? "yearlyPlanCard" : ""} hs-w-100 pricing-card-container`}
                    headStyle={{ borderBottom: "none" }}
                  >
                    <Row className="">
                      <Col>
                        <h5 className="hs-fw-700  pt-1">Monthly Plan</h5>
                      </Col>
                      <Col className="pb-4">
                        <div className="hs-me--1 pb-3">
                          {currentPlan === monthlyPlanNameChargeBee && (
                            <Badge.Ribbon text="Current Plan" rootClassName="hs-fs-12 hs-fw-600 current-plan-ribbon " color="cyan" />
                          )}
                        </div>
                      </Col>
                    </Row>
                    <div className="pt-2">
                      {priceDisplayLoading ? (
                        <Skeleton className="w-100%" paragraph={{ rows: 0 }} active />
                      ) : (
                        <span className="  hs-fs-32 hs-fw-700 ">
                          <span className="hs-fw-500 hs-fs-16">$</span>
                          <span>{monthlyPrice}</span>

                          <span className="hs-fs-12 hs-fw-400">/ user / month</span>
                        </span>
                      )}
                      <p className="hs-fw-400 hs-fs-12">Minimum 3 users billed</p>
                    </div>
                    <div className="pt-1">
                      {existingChargeBeeUser ? (
                        currentPlan === monthlyPlanNameChargeBee ? (
                          <ManageSubscriptionButton
                            planNameChargeBee={monthlyPlanNameChargeBee}
                            handleClick={handleClick}
                            btnClass={`${
                              currentPlan === monthlyPlanNameChargeBee ? "yearlyCbItem" : "monthlyPlanGetStarted"
                            } hs-fs-14 hs-fw-700  hs-h-40 hs-w-100`}
                          />
                        ) : (
                          <SwitchPlanButton
                            planNameChargeBee={monthlyPlanNameChargeBee}
                            isDowngradePlan={isDowngradePlan}
                            setSwitchingToPlanName={setSwitchingToPlanName}
                            setIsDowngradePlan={setIsDowngradePlan}
                            setIsUpgradePlan={setIsUpgradePlan}
                            setShowConfirmationModal={setShowConfirmationModal}
                            currentPlan={currentPlan}
                          />
                        )
                      ) : (
                        <GetStartedButton
                          planNameChargeBee={monthlyPlanNameChargeBee}
                          licenseActiveUsersCount={licenseActiveUsersCount}
                          handleClick={handleClick}
                        />
                      )}
                    </div>
                    <div className="pt-3">
                      <h6 className="hs-fw-700">Features</h6>
                      <ul className="list-unstyled ">
                        {monthlyFeatures?.map((feature, index) => (
                          <li key={index} className="d-flex pt-2 ps-2 pb-2 align-items-center hs-fw-500">
                            <div className="actionIconsSprite pricingFeatureIcon"></div>
                            <span className="ps-2">{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Card>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12} className="d-flex justify-content-start p-3 yearlyPricingCard">
                  <Card
                    className={` ${currentPlan === monthlyPlanNameChargeBee ? "" : "yearlyPlanCard"} hs-w-100 pricing-card-container`}
                    headStyle={{ borderBottom: "none" }}
                  >
                    <Row justify="space-between" align="middle">
                      <Col>
                        <div className="d-flex flex-row align-items-center">
                          <h5 className="hs-fw-700">Yearly Plan</h5>
                          <Text type="secondary" className="hs-fw-700 hs-fs-12 ps-1 yearlyPlanDiscount">
                            (Save {discount})
                          </Text>
                        </div>
                      </Col>
                      <Col span={2}>
                        {currentPlan === yearlyPlanNameChargeBee ? (
                          <div className="ps-4">
                            <div className="ps-3">
                              <Badge.Ribbon text="Current Plan" color="cyan"></Badge.Ribbon>
                            </div>
                          </div>
                        ) : currentPlan === monthlyPlanNameChargeBee ? (
                          ""
                        ) : (
                          <img src={YearlyPricingTag} alt="YearlyPricingTag" className="ps-2" style={{ width: 43 }} />
                        )}
                      </Col>
                    </Row>
                    <div className="pt-2">
                      {priceDisplayLoading ? (
                        <Skeleton className="w-100%" paragraph={{ rows: 0 }} active />
                      ) : (
                        <span className="  hs-fs-32 hs-fw-700 ">
                          <span className="hs-fw-500 hs-fs-16">$</span>
                          <span>{yearlyPrice}</span>
                          <span className="hs-fs-12 hs-fw-400">/ user / month</span>
                        </span>
                      )}
                      <p className="hs-fw-400 hs-fs-12">Minimum 3 users billed</p>
                    </div>
                    <div className={` ${currentPlan === monthlyPlanNameChargeBee ? "pt-3" : "pt-2"}`}>
                      {existingChargeBeeUser ? (
                        currentPlan === yearlyPlanNameChargeBee ? (
                          <ManageSubscriptionButton
                            planNameChargeBee={yearlyPlanNameChargeBee}
                            handleClick={handleClick}
                            btnClass={`${
                              currentPlan === monthlyPlanNameChargeBee && selectedFeature === selectedFeatureFromResponse
                                ? "monthlyPlanGetStarted"
                                : "yearlyCbItem"
                            } d-flex align-items-center justify-content-center hs-fw-700 hs-fs-14 hs-h-40  hs-w-100`}
                          />
                        ) : (
                          <SwitchPlanButton
                            planNameChargeBee={yearlyPlanNameChargeBee}
                            isDowngradePlan={isDowngradePlan}
                            setSwitchingToPlanName={setSwitchingToPlanName}
                            setIsDowngradePlan={setIsDowngradePlan}
                            setIsUpgradePlan={setIsUpgradePlan}
                            setShowConfirmationModal={setShowConfirmationModal}
                            currentPlan={currentPlan}
                          />
                        )
                      ) : (
                        <GetStartedButton
                          planNameChargeBee={yearlyPlanNameChargeBee}
                          licenseActiveUsersCount={licenseActiveUsersCount}
                          handleClick={handleClick}
                        />
                      )}
                    </div>
                    <div className="pt-4">
                      <h6 className="hs-fw-700">Features</h6>
                      <ul className="list-unstyled ">
                        {yearlyFeatures?.map((feature, index) => (
                          <li key={index} className="d-flex pt-2 ps-2 pb-2 align-items-center hs-fw-500">
                            <div className="actionIconsSprite pricingFeatureIcon"></div>
                            <span className="ps-2">{feature}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Row>
            <Row
              id="exploreFeatures"
              className="d-flex justify-content-center align-items-center pt-5 pb-5 cursor-pointer hs-h-10 w-100"
              onClick={() => document.getElementById("seeWhat").scrollIntoView({ behavior: "smooth" })}
            >
              <Col xs={24} sm={24} md={19} className="hs-fw-500 hs-fs-16 exploreFeatures p-2 pt-3 pb-3 d-flex justify-content-center">
                Explore Features
              </Col>
            </Row>

            <Row className="justify-content-center align-items-center hs-h-20 pt-4 pb-4">
              <Col xs={12} md={11} className="text-center">
                <Col>
                  <h2 className="font-weight-bold hs-fw-500 hs-fs-5min">“Over 500+ businesses have chosen us”</h2>
                </Col>
                <Col className="pt-2">
                  <p className="text-muted hs-fw-500 hs-fs-18 hs-color-mediumDark">
                    Certified solution partner: Empowering success through expert solutions
                  </p>
                </Col>
                <Col className="pt-2">
                  <p className="text-muted hs-fw-500 hs-fs-13 hs-color-mediumDark">
                    Transparent pricing | 7 days cashback guarantee | Cancel anytime
                  </p>
                </Col>

                <Row className="d-flex justify-content-center">
                  <Col xs={24} md={24} xl={24} className="d-flex justify-content-center">
                    <img className="responsive-image" src={require("../assets/images/PartnersLogo.png")} alt="HelloSend" />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row id="seeWhat" justify="center" className="pt-5 pb-4 seeWhat hs-h-5">
              <Col xs={24} md={20}>
                <h2 className="text-center pt-2 ">See what's included in each plan</h2>
              </Col>
            </Row>
            <Row justify="center" className="hs-h-30">
              <Col xs={24} md={22} xl={18}>
                <Row>
                  {["feature", "monthly", "yearly"].map((col, idx) => (
                    <Col key={col} span={idx === 2 ? 7 : 8} className={`comparison-${col}-column`}>
                      {planComparisonData?.map((item) => (
                        <div key={item.key} className={`bottom-shadow comparison-${col}`}>
                          {item[col]}
                        </div>
                      ))}
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
            <Row justify="center" className="hs-h-5 d-flex align-items-center pt-5">
              <div className="hs-fs-12 pb-5">Subscriptions powered by Chargebee and Stripe</div>
            </Row>
          </Content>
          <Content className="hs-bg-GhostWhite hs-h-50">
            <Row className="pb-5 pt-4 FAQRow container d-flex justify-content-center">
              <Col className="pb-3" span={16} xs={20} md={24} xl={17}>
                <div className="FAQDiv">
                  <div className="text-center mb-5">
                    <p className="FAQTitle mb-0 hs-fw-500 hs-fs-32">Frequently Asked Questions</p>
                  </div>

                  <div>
                    {items.map((item) => (
                      <FAQItem key={item.key} item={item} isExpanded={expandedKey === item.key} onToggle={() => handleToggle(item.key)} />
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
            <Modal
              title={
                <Row>
                  <div className="d-flex justify-content-center w-100">
                    <span
                      className="d-flex justify-content-center actionIconsSprite successTickIcon"
                      style={{
                        width: 60,
                        height: 60,
                      }}
                    ></span>
                  </div>
                </Row>
              }
              centered
              width={425}
              onOk={() => {}}
              className="successPricingModel"
              closable={false}
              open={successModal}
              footer={null}
            >
              <>
                <div className="mt-4 mb-2">
                  <Row>
                    <div className="d-flex justify-content-center w-100">
                      <span className="hs-fs-16 hs-fw-bolder hs-color-BlackRussian">You're All Set!</span>
                    </div>
                  </Row>
                  <Row className="d-flex justify-content-center">
                    <Col className="d-flex justify-content-center ms-3">
                      <div className="hs-fs-14">Your subscription is now active!</div>
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-center">
                    <Col className="d-flex justify-content-center ms-3">
                      <p className="hs-fs-12">Happy texting!</p>
                    </Col>
                  </Row>
                </div>

                <div className="d-flex justify-content-center justify-content-between">
                  <Button className="hs-w-100 goToServicePricingBtn" onClick={handleClickDomainUrl}>
                    Done
                  </Button>
                </div>
              </>
            </Modal>

            {showBookingForm && (
              <>
                <Modal
                  title=" "
                  centered
                  open={showBookingForm}
                  onOk={() => setShowBookingForm(false)}
                  onCancel={() => setShowBookingForm(false)}
                  width={1000}
                  footer={null}
                >
                  <div>
                    {HELLOSEND_BOOOKING_URL && (
                      <iframe className="iframe-popup" src={HELLOSEND_BOOOKING_URL} frameBorder="0" allowFullScreen title="Schedule"></iframe>
                    )}
                  </div>
                </Modal>
              </>
            )}

            {isDowngradePlan && (
              <PlanModal
                isOpen={isDowngradePlan}
                type={MODAL_TYPES.DOWNGRADE}
                modalConfigs={MODAL_CONFIGS}
                onClose={() => setIsDowngradePlan(false)}
                onConfirm={handleDowngradeSupport}
                spinButton={spinButton}
              />
            )}
            {isUpgradePlan && (
              <PlanModal
                isOpen={isUpgradePlan}
                type={MODAL_TYPES.UPGRADE}
                modalConfigs={MODAL_CONFIGS}
                onClose={() => setIsUpgradePlan(false)}
                onConfirm={handleUpgradeSubscription}
                spinButton={spinButton}
              />
            )}
            {showConfirmationModal && (
              <PlanModal
                isOpen={showConfirmationModal}
                type={MODAL_TYPES.SWITCH}
                modalConfigs={MODAL_CONFIGS}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={handleUpgradeSubscription}
                spinButton={spinButton}
              />
            )}
          </Content>
        </>
      )}
    </Layout>
  );
}

export const ManageSubscriptionButton = (props) => {
  let { monthlyPlanNameChargeBee: planNameChargeBee, handleClick, btnClass } = props;
  return (
    <>
      <Button key={planNameChargeBee} id="subscribe" data-cb-type="portal" onMouseEnter={handleClick} value="large" className={btnClass}>
        Manage Subscription
      </Button>
    </>
  );
};
export const SwitchPlanButton = (props) => {
  let {
    planNameChargeBee: switchingToPlanName,
    setSwitchingToPlanName,
    setIsDowngradePlan,
    setIsUpgradePlan,
    currentPlan,
    setShowConfirmationModal,
  } = props;
  const showSwitchPlanModal = async () => {
    let switchingType = getPlanSwitchType(currentPlan, switchingToPlanName);
    if (switchingType === 1) {
      setShowConfirmationModal(true);
    } else if (switchingType === 2) {
      setIsDowngradePlan(true);
    } else if (switchingType === 3) {
      setIsUpgradePlan(true);
    }
    setSwitchingToPlanName(switchingToPlanName);
  };
  return (
    <>
      <Button
        key={switchingToPlanName}
        value="large"
        className={`monthlyPlanGetStarted hs-fs-14 hs-fw-700  hs-h-40 hs-w-100`}
        onClick={showSwitchPlanModal}
      >
        Switch Plan
      </Button>
    </>
  );
};

export const GetStartedButton = (props) => {
  let { licenseActiveUsersCount, planNameChargeBee, handleClick } = props;

  console.log("licenseActiveUsersCount >>>>>>>>>>>>>>>>>", licenseActiveUsersCount);
  return (
    <>
      <Button
        key={planNameChargeBee}
        id="subscribe"
        data-cb-type={licenseActiveUsersCount ? "checkout" : ""}
        data-cb-item-0={licenseActiveUsersCount ? planNameChargeBee : ""}
        data-cb-item-0-quantity={licenseActiveUsersCount}
        onMouseEnter={handleClick}
        value="large"
        className={`yearlyCbItem hs-fs-14 hs-fw-700 hs-w-100`}
      >
        Get started
      </Button>
    </>
  );
};
export default PricingPage;

export const PricingPageAccessDeniedPopup = (props) => {
  const { upgradeAlertPopup, setUpgradeAlertPopup } = props;
  const modalBody = (
    <div className="d-flex justify-content-between gap-3 flex-column ">
      <div className="d-flex justify-content-center">
        <div className="actionIconsSpriteForSideBar modal-upgrade-icon" />
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div className="d-flex justify-content-center align-items-center flex-column">
          <h5 className="hs-color-dark-blue">Access Denied</h5>
          <div className="hs-color-dark-blue">You don’t have permission to view this page. Please contact your admin for further details.</div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <Modal
        className="hs-h-80 d-flex align-items-center"
        width={400}
        mask={true}
        maskClosable={false}
        open={upgradeAlertPopup}
        modalCloseIcon={false}
        footer={false}
        closable={false}
        onCancel={() => setUpgradeAlertPopup(false)}
      >
        {modalBody}
      </Modal>
    </>
  );
};
