import { Avatar, Col, List, Radio, Row, Spin, Typography } from "antd";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "../assets/css/campaignAnalytics.css";
import InfiniteVirtualTable from "../components/custom/InfiniteVirtualTable";
import { AppContext } from "../context/AppContext";
import useCampaignApi from "../hooks/useCampaign";
import useHttp from "../hooks/useHttp";
import CampaignContent from "./campaign/components/campaign/CampaignContent";
import { getCampaignStatusFromStatusCode } from "./campaign/utils/campaignUtils";
import { failureNotification } from "./utils/CommonNotifications";
import { CountUpStatistic, RefreshButton } from "./utils/CommonVessels";
import { capitalizeFirstLetter } from "./utils/commonUtils";
import MessageLogs from "./MessageLogs";

const { Text, Paragraph } = Typography;

const columns = [
  {
    title: "Name",
    dataIndex: "receiver",
    key: "receiver",
    className: "p-auto",
    width: 270,
    ellipsis: true,
    render: (receiver, item) => {
      return (
        <div className="h-100 d-flex align-items-center">
          <div className="ms-lg-3 me-2 hs-bg-light-orange rounded-circle d-flex align-items-center justify-content-center list-row-avatar">
            {item?.receiverPhoto ? (
              <Avatar rootClassName="w-100 h-100" src={item?.receiverPhoto} />
            ) : item?.receiverName ? (
              <span className="hs-color-dark-orange hs-fw-600 hs-fs-18">{item?.receiverName?.slice(0, 1)?.toUpperCase()}</span>
            ) : (
              <div className="h-100 w-100 avatarIcon rounded-circle staticIconsSprite" />
            )}
          </div>
          {item?.receiverName && (
            <>
              <span className="mx-1">{capitalizeFirstLetter(item?.receiverName)}</span>
              <span className="mx-1">{`-`}</span>
            </>
          )}
          <span className="mx-1">{receiver}</span>
        </div>
      );
    },
  },
  {
    title: "Date Sent",
    dataIndex: "createdTime",
    key: "createdTime",
    className: "p-auto",
    width: 200,
    render: (text) => {
      return (
        <div className="d-flex h-100 align-items-center">
          <Text ellipsis={{ tooltip: { text } }}>{text}</Text>
        </div>
      );
    },
  },
  {
    title: "Error Code",
    key: "errorCode",
    dataIndex: "errorCode",
    className: "p-auto",
    width: 500,
    render: (errorCode, item) => {
      let { statusDetails } = item;
      statusDetails = statusDetails ?? "Something went wrong ;(";
      return (
        <div className="d-flex align-items-center h-100">
          {errorCode ? `${errorCode} : ` : ""}
          {statusDetails}
        </div>
      );
    },
  },
];

const campaignReportCards = [
  {
    img: "totalProcessed",
    title: "Total Planned",
    id: "totalProcessed",
  },
  {
    img: "inProgress",
    title: "In Progress",
    id: "inProgress",
  },
  {
    img: "sent",
    title: "Awaiting Status",
    id: "sent",
  },
  {
    img: "success",
    title: "Delivered",
    id: "success",
  },
  {
    img: "failure",
    id: "failure",
    title: "Failure",
  },
  {
    id: "no_phone_number",
    img: "skipped",
    title: "Skipped",
  },
];

// Campaign analytics configuration

function CampaignAnalytics(props) {
  const { currentItem } = props;
  const [currentMenuItem, setCurrentMenuItem] = useState(currentItem);
  const [appProperties, setAppProperties] = useContext(AppContext);
  const { getCampaignByCampaignId, getCampaignOverview } = useCampaignApi(appProperties);
  const [campaignInfo, setCampaignInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const { fetchData } = useHttp();
  const [graphData, setGraphData] = useState([]);
  const [campaignOverview, setCampaignOverview] = useState();
  const location = useLocation();
  const paginationRef = useRef(null);
  const [tableData, setTableData] = useState();
  const pathSegments = location.pathname.split("/");
  const campaignIdFromPath = pathSegments[4];
  const [campaignId] = useState(campaignIdFromPath);

  const fetchAPi = useCallback(async () => {
    if (appProperties?.serviceInstalled) {
      try {
        handleLoading(true);
        appProperties?.setLoading?.(true);
        let response = await getCampaignByCampaignId(campaignId);
        let responseData = response?.data;
        let initialValues = {
          campaignStatus: responseData?.campaign?.campaignStatus,
          disabled: true,
          campaign: responseData?.campaign,
          source: "analytics",
          campaignName: responseData?.campaign?.campaignName,
          campaignStatusName: getCampaignStatusFromStatusCode(responseData?.campaign?.campaignStatus),
          campaignId: campaignId,
          fromNumber: responseData?.campaign?.sender,
          moduleName: responseData?.campaign?.moduleName,
          moduleOptions: responseData?.modules,
          filterName: responseData?.campaign?.filterName,
          messageComponents: responseData?.messageComponents,
          filterOptions: responseData?.filters,
          filterId:
            responseData?.campaign?.campaignStatus === 53 && (responseData?.campaign?.selectedIds || responseData?.campaign?.excludedIds)
              ? null
              : responseData?.campaign?.filterId,
          csvFileName: responseData?.campaign?.csvFileName,
          phoneFieldApi: responseData?.campaign?.phoneFieldApi,
          selectedIds: responseData?.campaign?.selectedIds,
          excludedIds: responseData?.campaign?.excludedIds,
          csvFileId: responseData?.campaign?.csvFileId,
          sharedFilter: responseData?.sharedFilter ?? false,
          testTo: "",
          message: responseData?.campaign?.messageBody,
          date: dayjs(responseData?.campaign?.scheduledTime ?? responseData?.campaign?.createdTime),
          time: dayjs(responseData?.campaign?.scheduledTime ?? responseData?.campaign?.createdTime),
          type: responseData?.campaign?.scheduledCampaign === 0 ? "immediate" : "scheduled",
          mediaFileList:
            responseData?.campaign?.mediaFileList &&
            responseData?.campaign?.mediaFileList.map((item) => {
              const parsedItem = JSON.parse(item);
              parsedItem.status = "done";
              parsedItem.type = parsedItem?.contentType;
              parsedItem.disabled = true;
              return parsedItem;
            }),
          phoneField: responseData?.campaign?.phoneField,
          moduleFields: responseData?.campaign?.moduleFields,
        };
        if (responseData?.campaign?.campaignName) {
          setAppProperties((prev) => ({
            ...prev,
            headerTitleName: responseData?.campaign?.campaignName,
          }));
        }
        setCampaignOverview(initialValues);
        if (responseData?.campaign?.recordsObj) {
          let recordsObj = JSON.parse(responseData?.campaign?.recordsObj);
          initialValues.recordsObj = recordsObj;
        }
        appProperties.setLoading(false);
      } catch {
        failureNotification("Error in fetching campaign information");
      } finally {
        handleLoading(false);
      }
    }
  }, [campaignId, fetchData, appProperties?.serviceInstalled]);

  const handleLoading = (loadingState) => {
    setLoading(loadingState);
  };

  const handleScrollApi = useCallback(async () => {
    handleLoading(true);
    if (!loading) {
      try {
        const response = await getCampaignOverview(campaignId, paginationRef);
        if (response && response.data) {
          const campaignStats = getCampaignStatsFromResponse(response.data.campaign);
          response.data.campaign = campaignStats;
          setCampaignInfo(response.data);

          // Check metrics exist before sorting
          if (response.data.metrics && Array.isArray(response.data.metrics)) {
            response.data.metrics = response.data.metrics.sort((a, b) => b.count - a.count);
            const metricData = response.data.metrics.map((obj) => {
              const { color, fillArea } = getRandomColor();
              return {
                ...obj,
                label: `Error code ${obj.code}`,
                data: [0, 0, 0, 0, obj.count < 9999 ? `${obj.count}` : `9999+`],
                fill: true,
                backgroundColor: fillArea,
                borderColor: color,
                lineTension: 0.6,
              };
            });
            setGraphData(metricData);
          } else {
            setGraphData([]);
          }

          if (response.data.messages && response.data.messages.length > 0) {
            let updatedData = response.data.messages.map((obj) => {
              return {
                ...obj,
                campaignName: response.data.campaign?.campaignName,
                createdTime: dayjs(obj.createdTime).format("YYYY-MM-DD HH:mm A"),
              };
            });
            setTableData(updatedData);
            if (response.data.pagination) {
              return false;
            } else {
              return true;
            }
          } else {
            setTableData([]);
            return true;
          }
        } else {
          // Handle null or incomplete response
          setGraphData([]);
          setTableData([]);
          return true;
        }
      } catch (e) {
        console.error("Error getting campaign metrics:", e);
        failureNotification("Error getting campaign metrics", e?.message || "An unexpected error occurred");
        // Reset data on error
        setGraphData([]);
        setTableData([]);
        return true;
      } finally {
        handleLoading(false);
      }
    }
  }, [fetchData, campaignId]);

  const getCampaignStatsFromResponse = (campaign) => {
    if (campaign) {
      const noPhoneNumber = campaign.no_phone_number ?? 0;
      const failure = campaign?.failure ?? 0;
      const totalProcessed = campaign?.totalProcessed ?? 0;
      const sent = campaign?.sent ?? 0;
      const success = campaign?.success ?? 0;
      const inProgress = campaign?.inProgress ?? 0;

      campaign.sent = sent;
      campaign.no_phone_number = noPhoneNumber;
      campaign.totalProcessed = totalProcessed;
      campaign.failure = failure;
      campaign.success = success;
      campaign.inProgress = inProgress;
    }
    return campaign;
  };

  // Add effect to run initial API call
  useEffect(() => {
    if (appProperties.serviceInstalled) {
      fetchAPi();
    }
  }, [fetchAPi, appProperties.serviceInstalled]);

  // Handle menu click
  const handleMenuClick = (e) => {
    setCurrentMenuItem(e.target.value);

    // Load metrics data when switching to Reports tab
    if (e.target.value === "error") {
      handleScrollApi();
    }
  };

  // Add effect to load data when Reports tab is selected
  useEffect(() => {
    // Only run this effect if we're on the Reports tab
    if (currentMenuItem === "error" && appProperties.serviceInstalled) {
      console.log("Loading campaign metrics for Reports tab");
      handleScrollApi();
    }
  }, [currentMenuItem, handleScrollApi, appProperties.serviceInstalled]);
  return (
    <>
      <Spin spinning={loading}>
        <div className="d-flex align-items-center ms-4 me-3 mt-2 mb-1 gap-2">
          <Radio.Group
            borderColorDisabled
            className="campaign-menu compact-tabs"
            style={{ border: 0 }}
            bordered="false"
            onChange={handleMenuClick}
          >
            <Radio.Button
              className={`campaign-menu-button hs-border-10 ${currentMenuItem === "overview" ? "overview-btn" : "anti-hover"}`}
              value="overview"
              bordered="false"
            >
              Overview
            </Radio.Button>
            <Radio.Button
              className={`campaign-menu-button hs-border-10 ${currentMenuItem === "error" ? "overview-btn" : "anti-hover"}`}
              value="error"
            >
              Reports
            </Radio.Button>
          </Radio.Group>
          {currentMenuItem === "error" && (
            <RefreshButton
              loading={loading}
              onClickFunction={() => {
                // Reset pagination reference to initial state
                paginationRef.current = null;
                // Then fetch data
                handleScrollApi();
              }}
              className="compact"
            />
          )}
        </div>
        {currentMenuItem === "overview" ? (
          campaignOverview ? (
            <CampaignContent
              appProperties={appProperties}
              initialValues={campaignOverview}
              setInitialValues={setCampaignOverview}
              setAppProperties={setAppProperties}
              showOnlyStop={campaignOverview?.selectedIds || campaignOverview?.excludedIds}
            />
          ) : (
            <></>
          )
        ) : (
          <CampaignInfo
            graphData={graphData}
            campaignInfo={campaignInfo}
            handleScrollApi={handleScrollApi}
            tableData={tableData}
            appProperties={appProperties}
          />
        )}
      </Spin>
    </>
  );
}
const CampaignInfo = React.memo((props) => {
  const { campaignInfo, graphData, handleScrollApi, appProperties, tableData } = props;

  // Ensure campaign stats are initialized with zeros when not yet loaded
  const campaign = campaignInfo?.campaign || {
    totalProcessed: 0,
    inProgress: 0,
    sent: 0,
    success: 0,
    failure: 0,
    no_phone_number: 0,
  };
  return (
    <div className="d-flex justify-content-center w-100">
      <div className="d-flex flex-column align-items-center w-100">
        {/* Campaign Stats Cards */}
        <div className="d-flex justify-content-center w-100 hs-border-12" style={{ padding: "2px 6px" }}>
          <div className="stats-cards-container">
            {campaignReportCards.map((obj, index) => (
              <div key={index} className="stats-card">
                <div className="card-analytics">
                  <div className="d-flex w-100 align-items-center flex-nowrap gap-2">
                    <div className={`campaign-analytic-sprites ci-${obj.img}`} style={{ minWidth: "24px", height: "24px" }} />
                    <span style={{ fontSize: "14px", whiteSpace: "nowrap" }}>{obj?.title}:</span>
                    <span className={obj.id === "success" || obj.id === "failure" ? "important-stat fw-bold" : "fw-bold"}>
                      {campaign[obj?.id] < 999999 ? campaign[obj?.id] : "999999+"}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Side-by-side layout for Message Logs and Error Codes (swapped positions) */}
        <Row gutter={[12, 8]} className="mt-2 w-100 ps-4">
          {/* Message Logs Panel (now on the left) */}
          <Col xs={24} md={16} lg={18} className="message-logs-panel">
            <CampaignErrors handleScrollApi={handleScrollApi} tableData={tableData} appProperties={appProperties} />
          </Col>

          {/* Error Codes Panel (now on the right) */}
          <Col xs={24} md={8} lg={6} className="error-codes-panel pe-4">
            <GraphRow graphData={graphData} createdTime={campaignInfo?.campaign?.createdTime} campaignMetrics={campaignInfo?.metrics} />
          </Col>
        </Row>
      </div>
    </div>
  );
});

const GraphRow = React.memo((props) => {
  const { campaignMetrics, createdTime, graphData } = props;

  return (
    <div className="error-codes h-100">
      <div className="errorCodes p-0 h-100">
        <div className="p-2 d-flex justify-content-between align-items-center border-bottom">
          <h6 className="mb-0 panel-heading">Error Codes</h6>
        </div>
        <div className="errorCodesListContainer pt-2 px-3">
          <List
            dataSource={graphData}
            className="error-codes-list"
            locale={{ emptyText: "No errors found for this campaign" }}
            renderItem={(obj, index) => (
              <List.Item key={index}>
                <List.Item.Meta
                  className="d-flex align-items-center"
                  avatar={
                    <div
                      className="colorDefine"
                      style={{
                        backgroundColor: obj.borderColor,
                        height: 16,
                        width: 16,
                      }}
                    />
                  }
                  title={
                    <div style={{ fontSize: "13px" }}>
                      {`${obj?.code}: ${obj?.message}`}
                      <div className="text-muted">Count: {obj?.count < 999999 ? obj?.count : "999999+"}</div>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </div>
  );
});

const CampaignErrors = React.memo((props) => {
  const { handleScrollApi, tableData, appProperties } = props;
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const campaignId = pathSegments[4];

  return (
    <div className="bg-white w-100 h-100">
      {/* Use the MessageLogs component with campaign filter */}
      <div className="campaign-message-logs pt-2" style={{ width: "100%", padding: "0" }}>
        <MessageLogs
          initialFilter={{ sent_via: 4, campaignId: campaignId }}
          hideColumns={["sent_via"]}
          preventHorizontalScroll={true}
          filterConfig={{
            title: true,
            status: true,
            errorCode: false,
            receiver: true,
            sender: true,
            user: true,
            dateRange: false,
            tableSize: "373",
          }}
        />
      </div>
    </div>
  );
});
export default CampaignAnalytics;
function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  let colorObj = {
    color: color,
    fillArea: color + "1A",
  };
  return colorObj;
}
