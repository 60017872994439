import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined, PushpinOutlined } from "@ant-design/icons";
import Contentbar from "../components/main/Contentbar";
import Headerbar from "../components/main/Headerbar";
import Sidebar from "../components/main/Sidebar";
const AppLayout = ({ setLoading, breadCrumbItems, setBreadCrumbItems }) => {
  const [headerTitle, setHeaderTitle] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [isPinned, setIsPinned] = useState(() => {
    // Get pinned state from localStorage
    const pinnedState = localStorage.getItem("sideBarPinned");
    return pinnedState !== null ? JSON.parse(pinnedState) : true;
  });
  
  const [sideBarAbsent, setSideBarAbsent] = useState(() => {
    // Get saved state from localStorage, default to true if not found
    const savedState = localStorage.getItem("sideBarHidden");
    const isPinnedValue = localStorage.getItem("sideBarPinned") === "true";
    
    // If pinned, always show sidebar (not absent)
    if (isPinnedValue) {
      return false;
    }
    
    return savedState !== null ? JSON.parse(savedState) : true;
  });
  
  const [isFloating, setIsFloating] = useState(false);
  let path = window.location.pathname;

  let urlParams = new URLSearchParams(window.location.search);
  let sideBar = urlParams.get("sideBar");
  
  const toggleSidebar = () => {
    if (sideBarAbsent) {
      // Opening the sidebar
      setSideBarAbsent(false);
      
      // If not pinned, set to floating mode
      if (!isPinned) {
        setIsFloating(true);
      } else {
        // Store state in localStorage only when pinned
        localStorage.setItem("sideBarHidden", JSON.stringify(false));
      }
    } else {
      // Closing the sidebar
      if (isPinned) {
        // If pinned, update localStorage but don't close
        localStorage.setItem("sideBarHidden", JSON.stringify(false));
      } else {
        // If not pinned, close it
        setSideBarAbsent(true);
        setIsFloating(false);
        localStorage.setItem("sideBarHidden", JSON.stringify(true));
      }
    }
  };
  
  const togglePin = () => {
    setIsPinned((prev) => {
      const newPinState = !prev;
      localStorage.setItem("sideBarPinned", JSON.stringify(newPinState));
      
      if (newPinState) {
        // When pinning, ensure sidebar is visible and save to localStorage
        setSideBarAbsent(false);
        setIsFloating(false);
        localStorage.setItem("sideBarHidden", JSON.stringify(false));
      } else {
        // When unpinning, hide the sidebar immediately unless in temporary floating mode
        setSideBarAbsent(true);
        setIsFloating(false);
        localStorage.setItem("sideBarHidden", JSON.stringify(true));
      }
      
      return newPinState;
    });
  };
  
  // Handle navigation - close sidebar if in floating mode
  const handleNavigation = () => {
    // Only close the sidebar if it's NOT pinned
    if (!isPinned) {
      setSideBarAbsent(true);
      setIsFloating(false);
    }
  };
  useEffect(() => {
    // If sidebar is pinned, always show it regardless of path (except special cases)
    const isSpecialPath = path === "/zoho-crm/webhook" || 
                          path === "/zoho-crm/landing" || 
                          path === "/teams/landing";
    
    if (isPinned && !isSpecialPath) {
      setSideBarAbsent(false);
    } else if (
      isSpecialPath ||
      (sideBar === "false" &&
        path !== "/messages" &&
        path !== "/zoho-crm/webhook" &&
        path !== "/content" &&
        path !== "/sendsms" &&
        path !== "/zoho-crm/landing" &&
        path !== "/teams/landing")
    ) {
      setSideBarAbsent(false);
    } else if (sideBar === "false" && (path === "/sendsms" || path === "/messages" || path === "/content")) {
      // Only hide if not pinned
      if (!isPinned) {
        setSideBarAbsent(true);
      }
    }
  }, [path, sideBar, isPinned]);

  return (
    <Layout>
      {!sideBarAbsent && (
        <div
          className={`sidebar-overlay ${sideBarAbsent ? "hide-sidebar" : "show-sidebar"} ${isFloating && !isPinned ? "floating-overlay" : ""}`}
          onClick={(e) => {
            // Only close when clicking the overlay, not the sidebar itself
            if (e.target === e.currentTarget && (!isPinned || isFloating)) {
              setSideBarAbsent(true);
              setIsFloating(false);
            }
          }}
        >
          <Sidebar 
            toggleSidebar={toggleSidebar} 
            isPinned={isPinned} 
            togglePin={togglePin} 
            handleNavigation={handleNavigation}
            isFloating={isFloating}
          />
        </div>
      )}
      
      {/* Toggle sidebar button - only visible when sidebar is hidden */}
      {sideBarAbsent && (
        <div 
          className="sidebar-toggle-button"
          onClick={toggleSidebar}
          style={{
            position: 'fixed',
            left: '10px',
            top: '60px',
            zIndex: 1000,
            background: 'white',
            padding: '8px',
            borderRadius: '50%',
            boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <MenuUnfoldOutlined style={{ fontSize: '16px' }} />
        </div>
      )}
      
      <Layout className="hs-bg-off-white h-100">
        <Headerbar toggleSidebar={toggleSidebar} headerTitle={headerTitle} setServiceName={setServiceName} />
        <Contentbar
          breadcrumbItems={breadCrumbItems}
          setBreadCrumbItems={setBreadCrumbItems}
          serviceName={serviceName}
          setLoading={setLoading}
          setHeaderTitle={setHeaderTitle}
        />
      </Layout>
      {/* <VoiceLayout/> */}
    </Layout>
  );
};

export default AppLayout;
