import { Col, Menu, Row, Tag, notification } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../../assets/css/omessage.css";
import { useVoiceContext } from "../../../voice/context/VoiceContext";
import {
  Chargebee_PLAN_NAME_MONTHLY,
  Chargebee_PLAN_NAME_YEARLY,
  VOICE_CHARGEBEE_PLAN_MONTHLY,
  VOICE_CHARGEBEE_PLAN_YEARLY,
  HELLOSEND_BOOOKING_URL,
  Chargebee_PLAN_NAME_YEARLY_OLD,
  Chargebee_PLAN_NAME_MONTHLY_OLD,
} from "./../../../constants/AppConstants";
import { AppContext } from "./../../../context/AppContext";
import SideBarCredits from "./SideBarCredits";
import SideBarSupportBooking from "./SideBarSupportBooking";
import SubAccountsListing from "../../../pages/subAccount/SubAccountsListing";
import { getCurrentFeature } from "../../../voice/utils/VoiceUtils";
import HSelect from "../../custom/input/select/Select";
import { UpgradeAlertPopup } from "../../../pages/utils/CommonVessels";

const { SubMenu } = Menu;

export default function SideBarMenuItem(props) {
  const [api, contextHolder] = notification.useNotification();
  const { toggleSidebar, handleNavigation } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [appProperties] = useContext(AppContext);
  // Track which menu items are open
  const [subMenuOpenKeys, setSubMenuOpenKeys] = useState([]);

  // Track whether messaging or voice was last opened
  const [sideBarBorderShow, setSideBarBorderShow] = useState(false);
  const [queryParams, setQueryParams] = useState(null);
  const [upgradeAlertPopup, setUpgradeAlertPopup] = useState(false);
  const [callProperties, setCallProperties] = useVoiceContext();
  const [selectedKeys, setSelectedKeys] = useState([window.location.pathname]);
  const pathName = window.location.pathname;
  const isAutomationEnabled = appProperties?.controller?.sideBarExtend?.automationSideBar;
  const showCredits = appProperties?.savedNumbers?.filter?.((obj) => obj?.isHelloSendPhoneNumber === "true")?.length > 0 ? true : false;

  useEffect(() => {
    if (appProperties?.osyncId) {
      appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then(function (queryParamsFromApp) {
        setQueryParams(queryParamsFromApp);
      });
    }
  }, [appProperties, appProperties?.osyncId]);
  const callStateOptions = [
    { value: " ", label: "Inbound" },
    { value: "idle", label: "Idle" },
    { value: "answered", label: " Answered" },
    { value: "ringing", label: "Ringing" },
    { value: "save-close", label: "Save and CLose" },
  ];
  const handleStatusOnChange = (value) => {
    let mockData = {
      incomingCallTo: "+13512136494",
      recordId: "3885812000080914067",
      recordLink: "https://crm.zoho.com/crm/org685955847/tab/Contacts/3885812000080914067",
      module: "Contacts",
      avatar: "https://img.freepik.com/premium-photo/natural-beauty-portrait-woman-autumn-forest_1351942-2475.jpg",
      lastName: "Sophia Carter",
      phone: "+12562861007",
    };

    setCallProperties((prev) => ({
      ...prev,
      isDialPadVisible: true,
      callStatus: value,
      callMockData: mockData,
      incomingCall: value === " ",
    }));
  };
  var locationHref = window.location.origin;
  const topItems = useMemo(
    () =>
      [
        appProperties?.agencyAccount && {
          key: "subAccount",
        },
        {
          key: "inbox",
          label: "Inbox",
          icon: <span className="actionIconsSpriteForSideBar inboxIcon display-inline-block"></span>,
          path: "/inbox",
        },
        {
          key: "messaging",
          label: "Messaging",
          icon: <span className="actionIconsSpriteForSideBar messaging-icon display-inline-block"></span>,
          children: [
            {
              key: "campaigns",
              parentKey: "messaging",
              label: "Campaign",
              path: "/messaging/campaigns/list",
            },
            {
              key: "messagelogs",
              parentKey: "messaging",
              label: "Message Logs",
              path: "/messaging/message-logs",
              // Only show for admin (roleId 12) and account-owner (roleId 11)
              hidden: ![11, 12].includes(appProperties?.currentUser?.roleId),
            },
            {
              key: "automation",
              parentKey: "messaging",
              label: <div style={{ paddingLeft: "5px" }}>Automation</div>,

              sub_children: [
                {
                  key: "workflow",
                  parentKey: "automation",
                  label: "Webhook",
                  path: "/messaging/automation",
                },
              ],
            },
          ],
        },
        {
          key: "voice",
          label: (
            <div className="d-flex flex-row">
              <div>Call</div>
              <div className="ps-lg-1">
                {" "}
                <Tag bordered={false} color="#EEF2FF" className="hs-border-1-25-rem ">
                  <span className="hs-color-blue-1">Beta</span>
                </Tag>
              </div>
            </div>
          ),
          icon: <span className="actionIconsSpriteForSideBar voice-side-bar-icon display-inline-block"></span>,
          children: [
            {
              key: "teams",
              parentKey: "voice",
              label: "Teams",
              path: "/voice/teams",
            },
            {
              key: "history",
              parentKey: "voice",
              label: "Call History",
              path: "/voice/history",
            },
          ],
        },
        {
          key: "settings",
          label: "Settings",
          icon: <span className="actionIconsSpriteForSideBar settingIcon display-inline-block"></span>,
          path: "/settings",
        },
        // !appProperties?.hideChannels && {
        //   key: "channels",
        //   label: "Channels",
        //   icon: <span className="actionIconsSpriteForSideBar channelsIcon display-inline-block"></span>,
        //   path: "/channels",
        //   settingsSidebarAboveSpace: "100px",
        // },
      ].filter(Boolean),
    [appProperties?.agencyAccount, appProperties?.hideChannels, isAutomationEnabled]
  );
  const bottomItems = useMemo(
    () =>
      [
        showCredits && {
          key: "credits",
          label: "Check Balance",
          supportBody: "Credits",
          class: "creditsBalance supportBook lh-2",
          type: "credits",
        },
        {
          key: "bookAgent",
          label: "Support",
          description: "Book Agent",
          class: "supportBook",
          icon: <span className="actionIconsSpriteForSideBar bookAgentIcon display-inline-block"></span>,
          type: "popup",
          iframeUrl: HELLOSEND_BOOOKING_URL,
        },
      ].filter(Boolean),
    [showCredits]
  );

  const getSelectedAndOpenKeys = () => {
    let selectedKey = "";
    let subMenuOpenKey = [];

    const pathSegments = location.pathname.split("/");
    const pathSegment = pathSegments[1];
    const moduleName = pathSegments[2];

    const menuItems = [...topItems, ...bottomItems];

    menuItems.forEach((item) => {
      // Normal path matching
      if (pathSegment === item.key) {
        if (pathSegment === "messaging" || pathSegment === "voice") {
          if (moduleName !== "automation") {
            selectedKey = moduleName;
            subMenuOpenKey.push(item.key);
          } else {
            selectedKey = "workflow";
            subMenuOpenKey.push("messaging", "automation");
          }
        } else {
          selectedKey = item.key;
        }
      } else if (item.children) {
        item.children.forEach((child) => {
          if (pathSegment === child?.key) {
            selectedKey = child?.key;
            subMenuOpenKey.push(item.key);
          }
        });
      }
    });

    return { selectedKey, subMenuOpenKey };
  };

  const { selectedKey, subMenuOpenKey } = getSelectedAndOpenKeys();

  useEffect(() => {
    console.log("Debug--SelectedKey:", selectedKey, "subMenuOpenKey:", subMenuOpenKey);
    setSelectedKeys([selectedKey]);
    setSubMenuOpenKeys(subMenuOpenKey);
  }, [bottomItems, location, topItems, selectedKey]);

  const generateMenuItems = (menuItems) => {
    let updatedSearch = "?" + queryParams.toString();
    return menuItems.map((item) => {
      if (item.key === "subAccount") {
        return (
          <div className={`${sideBarBorderShow ? "px-3 py-2" : "px-1 pb-2 d-md-none"}`}>
            <SubAccountsListing
              closeOffCanvas={() => toggleSidebar()}
              selectComponentWidth={sideBarBorderShow ? "90%" : "94%"}
              selectComponentHeight={sideBarBorderShow ? 50 : 60}
            />
          </div>
        );
      }

      if (item.children) {
        return (
          <SubMenu
            key={item.key}
            className={`${
              getCurrentFeature(appProperties) === "Message" && item?.key === "voice"
                ? "show-crown-icon"
                : getCurrentFeature(appProperties) === "Call" && item?.key === "messaging"
                ? "show-crown-icon"
                : ""
            }`}
            title={
              <Row align="middle">
                <Col span={7}>{item.icon}</Col>
                <Col span={16} className="interFontWeightMedium">
                  {item.label}
                </Col>
              </Row>
            }
          >
            {item.children.map((child) => {
              // Skip rendering menu items that are hidden
              if (child.hidden) {
                return null;
              }

              if (child.sub_children) {
                return (
                  <SubMenu
                    key={child.key}
                    title={
                      <Row align="middle">
                        <Col span={16} className="interFontWeightMedium">
                          {child.label}
                        </Col>
                      </Row>
                    }
                  >
                    {child.sub_children.map((subChild) => (
                      <Menu.Item key={subChild.key}>
                        <Link
                          to={subChild.path + updatedSearch}
                          target={sideBarBorderShow ? "_blank" : "_self"}
                          style={{ textDecoration: "none" }}
                          onClick={handleNavigation}
                        >
                          <Row align="middle">
                            <Col span={14} className="interFontWeightMedium">
                              {subChild.label}
                            </Col>
                          </Row>
                        </Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                );
              }

              return (
                <Menu.Item key={child.key}>
                  <Link
                    to={child.path + updatedSearch}
                    target={sideBarBorderShow ? "_blank" : "_self"}
                    style={{ textDecoration: "none" }}
                    onClick={handleNavigation}
                  >
                    <Row align="middle">
                      <Col span={14} className="interFontWeightMedium ms-2">
                        {child.label}
                      </Col>
                    </Row>
                  </Link>
                </Menu.Item>
              );
            })}
          </SubMenu>
        );
      }

      return (
        <Menu.Item
          onClick={(e) => {
            toggleSidebar();
          }}
          key={item.key}
          className={item.class ?? ""}
        >
          {item?.type === "popup" ? (
            <SideBarSupportBooking item={item} appProperties={appProperties} />
          ) : item?.type === "credits" ? (
            <SideBarCredits item={item} appProperties={appProperties} />
          ) : (
            <>
              <Link
                to={item.path + updatedSearch}
                style={{ textDecoration: "none" }}
                target={sideBarBorderShow ? "_blank" : "_self"}
                className="mb-3"
                onClick={handleNavigation}
              >
                <Row align="middle">
                  <Col span={6}>{item.icon}</Col>
                  <Col span={18} className="interFontWeightMedium" style={{ color: "black" }}>
                    {item.label}
                  </Col>
                </Row>
              </Link>
            </>
          )}
        </Menu.Item>
      );
    });
  };
  useEffect(() => {
    if (callProperties?.voicePlanRequestAccess) {
      const currentFeature = getCurrentFeature(appProperties);
      if (currentFeature) {
        api.open({
          message: `${currentFeature} Capability Enabled 🎉`,
          description: `Messaging and calling are now enabled for you. Start connecting with your customers effortlessly.`,
          showProgress: true,
          pauseOnHover: true,
        });

        setCallProperties((prev) => ({
          ...prev,
          voicePlanRequestAccess: false,
        }));
      }
    }
  }, [api, appProperties?.licenseObj?.licenseDetails?.planName, callProperties?.voicePlanRequestAccess]);

  useEffect(() => {
    if (props.sideBarCommonHandling) {
      setSideBarBorderShow(true);
    }
  }, []);
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={`d-flex flex-column justify-content-between ${sideBarBorderShow ? "" : "sidebar-menuRightBorder"}`}
      style={{ height: "84vh", borderInlineEnd: 0 }}
    >
      {contextHolder}
      {queryParams && (
        <>
          <Menu
            className={`sidebar-menu hs-border-inline-end-0 hs-max-w-fit-content border-end-0  ${
              sideBarBorderShow ? "sendSmsSideBar" : "sidebar-menuRightBorder"
            }`}
            mode={"inline"}
            openKeys={subMenuOpenKeys}
            onOpenChange={(e) => {
              if (
                Array.isArray(e) &&
                e.includes("messaging") &&
                [VOICE_CHARGEBEE_PLAN_MONTHLY, VOICE_CHARGEBEE_PLAN_YEARLY].includes(appProperties?.licenseObj?.licenseDetails?.planName)
              ) {
                setUpgradeAlertPopup(true);
              } else if (
                Array.isArray(e) &&
                e.includes("voice") &&
                [
                  Chargebee_PLAN_NAME_MONTHLY,
                  Chargebee_PLAN_NAME_YEARLY,
                  Chargebee_PLAN_NAME_YEARLY_OLD,
                  Chargebee_PLAN_NAME_MONTHLY_OLD,
                ].includes(appProperties?.licenseObj?.licenseDetails?.planName)
              ) {
                setUpgradeAlertPopup(true);
              } else {
                setSubMenuOpenKeys(e);
              }
            }}
            selectedKeys={selectedKeys}
            onSelect={({ key }) => setSelectedKeys([key])}
          >
            {generateMenuItems(topItems)}
          </Menu>
          <Menu
            className={`sideBarBottomMenu hs-border-inline-end-0 hs-max-w-fit-content border-end-0 sidebar-menu ${
              sideBarBorderShow ? "sendSmsSideBar" : "sidebar-menuRightBorder"
            }`}
            mode={"inline"}
            selectedKeys={[selectedKey]}
            defaultOpenKeys={[subMenuOpenKey]}
          >
            {generateMenuItems(bottomItems)}
            {locationHref.indexOf("localhost") !== -1 && (
              <HSelect
                defaultValue={callProperties?.callStatus}
                options={callStateOptions}
                enableSearch={false}
                customProps={{
                  className: "music-type-select typesOfPlayAudio w-100",
                  onChange: handleStatusOnChange,
                }}
                suffixIcon={<div className="call-action-icons callDropdownIcon" />}
              />
            )}
          </Menu>

          {upgradeAlertPopup && <UpgradeAlertPopup upgradeAlertPopup={upgradeAlertPopup} setUpgradeAlertPopup={setUpgradeAlertPopup} />}
        </>
      )}
    </div>
  );
}
