import React, { useState, useContext, useEffect } from "react";
import { useVoiceContext } from "../../context/VoiceContext.js";
import { Select } from "antd";
import { AppContext } from "../../../context/AppContext.js";
import useHttp from "../../../hooks/useHttp.jsx";
import { voiceAgentStatus } from "../../utils/VoiceUtils.js";

const AgentStatusSelect = (props) => {
  let { classNameProps, expandIcon, setShowOfflineMessageWhileInit, restrictAccess } = props;
  const { fetchData } = useHttp();
  const [appProperties] = useContext(AppContext);
  const [voiceIntegId, setVoiceIntegId] = useState();
  const [callProperties, setCallProperties] = useVoiceContext();

  const handleStatusChange = (value) => {
    const leftServiceId = appProperties?.leftServiceId;
    if (leftServiceId && appProperties?.leftServiceId && voiceIntegId) {
      fetchData(
        `voice/${voiceIntegId}/agents/${appProperties.userId}?status=${value}&leftServiceId=${leftServiceId}`,
        "PUT",
        { payload: "" },
        appProperties
      )
        .then((response) => {
          const statusValueResponse = JSON.parse(response);
          const statusValue = statusValueResponse?.data?.voiceAgentStatusEntity?.voiceAgentStatus
            ? statusValueResponse?.data?.voiceAgentStatusEntity?.voiceAgentStatus
            : voiceAgentStatus.OFFLINE;
          if (statusValue === voiceAgentStatus.ON_CALL || statusValue === voiceAgentStatus.AFTER_CALL_PROCESS) {
            setCallProperties((prev) => ({
              ...prev,
              agentStatusValue: voiceAgentStatus.BUSY,
            }));
          } else {
            setCallProperties((prev) => ({
              ...prev,
              agentStatusValue: statusValue,
            }));
          }

          if (statusValue === voiceAgentStatus.ONLINE_WEB && setShowOfflineMessageWhileInit) {
            setShowOfflineMessageWhileInit(false);
          }
          const token = statusValueResponse?.data?.capToken;
          if (token) {
            setCallProperties((prev) => ({
              ...prev,
              twilioCapToken: token,
            }));
          }
        })

        .catch((error) => {
          console.error("Error updating status:", error);
        });
    }
  };

  useEffect(() => {
    if (appProperties?.installedApps) {
      const voiceIntegrations = appProperties?.installedApps?.filter((integration) => integration.right.serviceType === "VOICE");
      if (voiceIntegrations?.[0]?.integProps?.integId) {
        setVoiceIntegId(voiceIntegrations?.[0]?.integProps?.integId);
      }
    }
  }, [appProperties?.installedApps]);

  return (
    <div>
      {" "}
      <div className={`${classNameProps} `}>
        <Select
          defaultValue={callProperties?.agentStatusValue !== undefined ? callProperties?.agentStatusValue : 6}
          value={callProperties?.agentStatusValue}
          rootClassName="dialpadSelectList cursor-pointer"
          suffixIcon={<div className={`${expandIcon} call-action-icons `} />}
          options={[
            {
              value: 1,
              label: (
                <div className="p-1 ">
                  <span className="onlineIndicatorDot"></span>
                  Online
                </div>
              ),
            },
            {
              value: 5,
              label: (
                <div className="p-1 ">
                  <span className="busyIndicatorDot"></span>
                  Busy
                </div>
              ),
            },
            {
              value: 6,
              label: (
                <div className="p-1 ">
                  <span className="offlineIndicatorDot"></span>
                  Offline
                </div>
              ),
            },
          ]}
          disabled={callProperties?.disableAgentStatusChange || restrictAccess}
          onChange={handleStatusChange}
        ></Select>
      </div>
    </div>
  );
};

export default AgentStatusSelect;
