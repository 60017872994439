import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Card, Form, Input, Button, DatePicker, Select, Typography, Modal, Spin, Space, Divider, Tooltip } from "antd";
import { FilterOutlined, SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { AppContext } from "../context/AppContext";
import MessageLogsTable from "../components/custom/MessageLogsTable";
import useMessageLogs from "../hooks/useMessageLogs";
import useUsers from "../hooks/useUsers";
import dayjs from "dayjs";
import "../assets/css/messageLogs.css";
import ReusableFilePreview from "../components/custom/ReusableFilePreview";
import { mapToMediaList } from "../pages/utils/commonUtils";

const { Title, Text, Paragraph } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

function MessageLogs({
  initialAutomationEventId,
  initialFilter,
  locationState,
  hideColumns = [],
  preventHorizontalScroll = false,
  filterConfig = {
    status: false,
    errorCode: false,
    sender: true,
    receiver: true,
    user: true,
    dateRange: true,
    tableSize: "270",
  },
  className = "",
}) {
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [form] = Form.useForm();
  const [smsLogs, setSMSLogs] = useState([]);
  const [filters, setFilters] = useState({});
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showFilters, setShowFilters] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [searching, setSearching] = useState(false);
  const [lastKey, setLastKey] = useState(null);
  const location = useLocation();

  // Combine location state with props
  const combinedLocationState = locationState || location.state;

  // Initialize the hooks
  const { loading, getMessageLogs, getMessageDetails, getMessageUsers } = useMessageLogs(appProperties);
  const { fetchCurrentUsers } = useUsers();

  // Set page title
  useEffect(() => {
    setAppProperties((prev) => ({
      ...prev,
      headerTitleName: "Message Logs",
    }));
  }, [setAppProperties]);

  let initialValues = {
    disabled: true,
  };

  // Initial setup when component first mounts - only runs once on osyncId available
  useEffect(() => {
    if (appProperties?.osyncId) {
      fetchUsersList();
    }
  }, [appProperties?.osyncId]);

  // Set up filters and fetch data when needed properties are available
  // This effect manages the filter setup and initial data fetch
  useEffect(() => {
    if (appProperties?.osyncId) {
      // Check URL parameters for filters
      const urlParams = new URLSearchParams(window.location.search);
      const sent_via = urlParams.get("sent_via");
      const urlCampaignId = urlParams.get("campaignId");

      // Priority for filter values:
      // 1. Dedicated props (initialAutomationEventId or initialFilter)
      // 2. Combined location state
      // 3. URL parameters

      // Get automationEventId with priority order
      const automationEventId = initialAutomationEventId || initialFilter?.automationEventId || combinedLocationState?.automationEventId;

      // Get campaignId with priority order
      const campaignId = initialFilter?.campaignId || combinedLocationState?.campaignId || urlCampaignId;

      // Get sent_via with priority order
      const sentVia = initialFilter?.sent_via || combinedLocationState?.sent_via || (sent_via ? Number(sent_via) : undefined);

      // Set initial filters
      const initialFilters = {};

      // Build form values to set
      const formValues = {};

      // Add automationEventId filter if present
      if (automationEventId) {
        initialFilters.automationEventId = automationEventId;
        formValues.automationEventId = automationEventId;
      }

      // Add campaignId filter if present
      if (campaignId) {
        initialFilters.campaignId = campaignId;
        formValues.campaignId = campaignId;
      }

      // Add sent_via filter if present
      if (sentVia) {
        initialFilters.sent_via = sentVia;
        formValues.sent_via = sentVia;
      }

      // Set the form values all at once
      if (Object.keys(formValues).length > 0) {
        form.setFieldsValue(formValues);
      }

      // Set the filters state
      setFilters(initialFilters);

      // Initial fetch with parameters if present
      // Add flag to identify this as the initial load
      fetchMessageLogs(initialFilters, { limit: 100, initialLoad: true });
    }
  }, [appProperties?.osyncId]);

  // Function to fetch users list
  const fetchUsersList = async () => {
    try {
      setLoadingUsers(true);
      const users = await fetchCurrentUsers(appProperties);
      if (users && users.allLicensedUserDetails) {
        const formattedUsers = users.allLicensedUserDetails.map((user) => ({
          id: user.osyncUserId || user.remoteUserId,
          name: user.email,
          email: user.email,
        }));
        setUsersList(formattedUsers);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoadingUsers(false);
    }
  };

  // Function to fetch message logs
  const fetchMessageLogs = async (filterValues = {}, pagination = {}, attemptCount = 0) => {
    try {
      const result = await getMessageLogs(filterValues, pagination);

      // Check if we have messages or if we should try again with the lastKey
      if (result?.messages && result.messages.length > 0) {
        // If we have messages, update the state
        if (pagination.isPaginationRequest) {
          // For pagination requests, REPLACE the data instead of appending (important change)
          setSMSLogs(result.messages);
        } else if (pagination.lastKey && !pagination.isPaginationRequest) {
          // For auto-pagination (infinite scroll), append data
          setSMSLogs((prevLogs) => [...prevLogs, ...result.messages]);
        } else {
          // For initial load or filter change, replace data
          setSMSLogs(result.messages);
        }

        // Update the lastKey state for the parent component
        if (
          result?.pagination?.lastKey &&
          result.pagination.lastKey !== "" &&
          result.pagination.lastKey !== "null" &&
          result.pagination.lastKey !== "undefined"
        ) {
          setLastKey(result.pagination.lastKey);
        } else {
          // Explicitly set to null when no lastKey or invalid lastKey
          setLastKey(null);
        }

        // Always return the result with pagination info, even for empty results
        // This ensures the table component gets the latest lastKey
        return result;
      } else if (result?.pagination?.lastKey) {
        // No messages but we have a lastKey
        // Don't automatically retry - this was causing issues with empty filter results

        // Clear logs for initial load to prevent showing old data
        if (!pagination.lastKey || pagination.initialLoad) {
          setSMSLogs([]);
        }

        // Just return the empty result
        return {
          messages: [],
          pagination: result.pagination,
          hasMore: false,
        };
      } else {
        // Either we got messages, or we've reached max attempts, or there's no lastKey
        if (pagination.lastKey && !pagination.paginationInfoOnly) {
          // If this was a pagination request, keep the existing logs
          return result;
        } else if (pagination.paginationInfoOnly) {
          // If this was just a request for pagination info, return the result without modifying state
          return result;
        } else {
          // If this was an initial request, clear the logs
          setSMSLogs([]);
        }
        // Update the lastKey state for the parent component
        if (
          result?.pagination?.lastKey &&
          result.pagination.lastKey !== "" &&
          result.pagination.lastKey !== "null" &&
          result.pagination.lastKey !== "undefined"
        ) {
          setLastKey(result.pagination.lastKey);
        } else {
          // Explicitly set to null when no lastKey or invalid lastKey
          setLastKey(null);
        }

        // Always return the result with pagination info for empty results too
        return result;
      }
    } catch (error) {
      console.error("Error in fetchMessageLogs:", error);
      if (!pagination.lastKey && !pagination.paginationInfoOnly) {
        // Only clear logs if this was an initial request, not a pagination request or pagination info request
        setSMSLogs([]);
      }
      return {
        messages: [],
        pagination: null,
        hasMore: false,
      };
    }
  };

  // Function to scroll to top of table
  const scrollTableToTop = () => {
    // Find the table body element and scroll to top
    const tableBody = document.querySelector(".ant-table-body");
    if (tableBody) {
      tableBody.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  // Handle filter form submission
  const handleFilterSubmit = async (values) => {
    setSearching(true);

    const filterParams = {};

    if (values.receiver) {
      filterParams.receiver = values.receiver;
    }

    // Add status filter if present
    if (values.status) {
      filterParams.status = values.status;
    }

    // Add error code filter if present
    if (values.errorCode) {
      filterParams.errorCode = values.errorCode;
    }

    if (values.dateRange && values.dateRange.length === 2) {
      // Using startTime and endTime to match the new API
      filterParams.startDate = values.dateRange[0];
      filterParams.endDate = values.dateRange[1];
    }

    // Add automation event ID if present
    if (values.automationEventId) {
      filterParams.automationEventId = values.automationEventId;
    }

    // Add sent_via filter if present
    if (values.sent_via) {
      filterParams.sent_via = values.sent_via;
    }

    // Add campaignId filter if present
    if (values.campaignId) {
      filterParams.campaignId = values.campaignId;
    }

    setFilters(filterParams);
    try {
      // Always use the standard limit of 100 for consistency
      // Add initialLoad flag to identify filter-initiated loads
      await fetchMessageLogs(filterParams, { limit: 100, initialLoad: true });

      // Scroll to top after data is loaded
      setTimeout(scrollTableToTop, 100);
    } catch (error) {
      console.error("Error during search:", error);
    } finally {
      setSearching(false);
    }
  };

  // Handle viewing message details
  const handleViewMessage = async (message) => {
    // Check for any valid ID field
    const messageId = message.id || message.messageId || message.osyncId;

    if (messageId) {
      try {
        const details = await getMessageDetails(messageId);
        if (details) {
          setSelectedMessage({ ...message, ...details });
        } else {
          // If no details returned, just use the message we have
          setSelectedMessage(message);
        }
      } catch (error) {
        console.error("Error fetching message details:", error);
        // Fall back to showing what we have
        setSelectedMessage(message);
      }
    } else {
      // If no ID available, just use what we have
      setSelectedMessage(message);
    }

    setMessageModalVisible(true);
  };

  // Reset filters, but preserve any initial filters
  const handleResetFilters = async () => {
    setSearching(true);

    // Initialize preserved filters
    const preservedFilters = {};

    // Fields that should be preserved when resetting
    const preservedFields = ["automationEventId", "campaignId", "sent_via"];

    // Add initialAutomationEventId if present
    if (initialAutomationEventId) {
      preservedFilters.automationEventId = initialAutomationEventId;
    }

    // Preserve any field from initialFilter
    if (initialFilter) {
      Object.keys(initialFilter).forEach((key) => {
        if (initialFilter[key] !== undefined && initialFilter[key] !== null) {
          preservedFilters[key] = initialFilter[key];
        }
      });
    }

    // Reset all form fields except the preserved ones
    const fieldValues = form.getFieldsValue();
    const fieldsToReset = Object.keys(fieldValues).filter((name) => !preservedFields.includes(name));

    // Start with a clean form
    form.resetFields();

    // Set only the preserved fields back
    if (Object.keys(preservedFilters).length > 0) {
      form.setFieldsValue(preservedFilters);
    }

    // Update filters
    setFilters(preservedFilters);

    try {
      // Always use the standard limit of 100 for consistency
      // Add initialLoad flag to identify filter-reset loads
      await fetchMessageLogs(preservedFilters, { limit: 100, initialLoad: true });

      // Scroll to top after data is loaded
      setTimeout(scrollTableToTop, 100);
    } catch (error) {
      console.error("Error resetting filters:", error);
    } finally {
      setSearching(false);
    }
  };

  // Toggle filter visibility
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  // Get automation event ID from filters or combined location state
  const automationEventId = filters.automationEventId || combinedLocationState?.automationEventId;

  return (
    <div className={`m-2 mt-lg-3 ms-lg-4 ps-lg-2 message-logs-container filter-panel ${className}`}>
      {/* Hidden clear button for automation filter - only shown in specific contexts */}
      {automationEventId && !initialAutomationEventId && (
        <div className="text-end mb-2">
          <Button
            type="link"
            size="small"
            onClick={() => {
              // Clear the automationEventId filter
              form.setFieldsValue({ automationEventId: undefined });

              // Create new filters without the automationEventId
              const newFilters = { ...filters };
              delete newFilters.automationEventId;

              setFilters(newFilters);

              // Clear the location state by replacing current history entry
              window.history.replaceState({ ...window.history.state, state: undefined }, document.title, window.location.pathname);

              // Reload data without the filter
              fetchMessageLogs(newFilters, { limit: 100, initialLoad: true });
            }}
          >
            Clear Filter
          </Button>
        </div>
      )}

      {/* Filter panel */}
      <div className={`rounded mb-3 d-flex justify-content-between align-items-center ${filterConfig.status ? "border-bottom pb-0" : ""}`}>
        {filterConfig.title && (
          <Col span={5} style={{ minWidth: "150px" }}>
            <div className="p-2 d-flex justify-content-between align-items-center">
              <h6 className="mb-0 panel-heading">Campaign Message Logs</h6>
            </div>
          </Col>
        )}
        <Form form={form} layout="inline" onFinish={handleFilterSubmit} className={`d-flex flex-wrap align-items-center w-100 ${className}`}>
          {/* Hidden fields for filters */}
          <Form.Item name="automationEventId" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="campaignId" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="sent_via" hidden>
            <Input />
          </Form.Item>

          {filterConfig.receiver && (
            <Col span={4} flex="1" style={{ minWidth: "250px" }} className="me-2 mb-2 form-item-receiver">
              <Form.Item name="receiver" className="w-100 mb-0 messageReceiver">
                <Input
                  placeholder="Receiver"
                  className="persistent-outline-input"
                  allowClear
                  style={{
                    borderColor: "#d9d9d9",
                    borderRadius: "4px",
                    boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1)",
                  }}
                />
              </Form.Item>
            </Col>
          )}

          {filterConfig.status && (
            <Col span={6} flex="1" style={{ minWidth: "200px" }} className="me-2 mb-2 filter-status form-item-status">
              <Form.Item name="status" className="w-100 mb-0">
                <Select placeholder="Status" allowClear style={{ width: "100%" }} className="status-filter-select">
                  <Option value="delivered">Delivered</Option>
                  <Option value="sent">Sent</Option>
                  <Option value="failed">Failed</Option>
                  <Option value="queued">Queued</Option>
                  <Option value="read">Read</Option>
                  <Option value="received">Received</Option>
                </Select>
              </Form.Item>
            </Col>
          )}

          {filterConfig.errorCode && (
            <Col flex="1" style={{ minWidth: "200px" }} className="me-2 mb-2 filter-error-code form-item-error-code">
              <Form.Item name="errorCode" className="w-100 mb-0">
                <Input
                  placeholder="Error Code"
                  className="persistent-outline-input error-code-filter-input"
                  allowClear
                  style={{
                    borderColor: "#d9d9d9",
                    borderRadius: "4px",
                    boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.1)",
                  }}
                />
              </Form.Item>
            </Col>
          )}

          {filterConfig.dateRange && (
            <Col span={4} flex="1" style={{ minWidth: "350px" }} className="me-2 mb-2 form-item-date-range">
              <Form.Item name="dateRange" className="w-100 mb-0 messageRange">
                <RangePicker
                  style={{ width: "100%" }}
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  placeholder={["Start Date", "End Date"]}
                  disabledDate={(current) => {
                    // Can't select days after today
                    const isAfterToday = current && current > dayjs().endOf("day");

                    // Can't select days before Feb 26, 2025 (feature launch date)
                    const isBeforeFeatureLaunch = current && current < dayjs("2025-02-26");

                    return isAfterToday || isBeforeFeatureLaunch;
                  }}
                />
              </Form.Item>
            </Col>
          )}
          <Col className="mb-2">
            <Button
              type="primary"
              htmlType="submit"
              icon={<SearchOutlined />}
              className="me-2"
              loading={searching}
              disabled={searching}
              style={{ backgroundColor: "var(--hs-violet)", borderColor: "var(--hs-violet)" }}
            >
              {searching ? "Searching" : "Search"}
            </Button>
            <Button icon={<ReloadOutlined />} onClick={() => handleResetFilters()} disabled={searching}>
              {initialAutomationEventId ? "Clear Filters" : "Reset"}
            </Button>
          </Col>
        </Form>
      </div>

      {/* Table */}
      <Row>
        <div className="rounded h-100 w-100 bg-white">
          <MessageLogsTable
            data={smsLogs}
            loading={loading}
            fetchFn={fetchMessageLogs}
            filters={filters}
            onViewMessage={handleViewMessage}
            initialPaginationSize={100}
            initialLoadDone={true} // Flag to prevent auto-loading on mount
            hideColumns={["userName", ...hideColumns]} // Combining default and external hideColumns (status now shown)
            preventHorizontalScroll={preventHorizontalScroll} // Pass through the preventHorizontalScroll prop
            filterConfig={filterConfig}
            // Pass the lastKey directly from the API response if available
            initialLastKey={lastKey} // From the state that's updated when API calls complete
          />
        </div>
      </Row>

      <Modal
        title="Message Details"
        open={messageModalVisible}
        onCancel={() => setMessageModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setMessageModalVisible(false)}>
            Close
          </Button>,
        ]}
        width={700}
      >
        {selectedMessage ? (
          <div>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Text strong>Sender:</Text>
                <Paragraph>
                  {selectedMessage.senderFriendlyName ? (
                    <Tooltip title={`${selectedMessage.sender} (${selectedMessage.senderFriendlyName})`}>
                      <span className="friendly-name">
                        {selectedMessage.senderFriendlyName.length > 23
                          ? selectedMessage.senderFriendlyName.substring(0, 20) + "..."
                          : selectedMessage.senderFriendlyName}
                      </span>
                    </Tooltip>
                  ) : (
                    selectedMessage.sender
                  )}
                </Paragraph>
              </Col>
              <Col span={12}>
                <Text strong>Receiver:</Text>
                <Paragraph>
                  {selectedMessage.receiverFriendlyName ? (
                    <Tooltip title={`${selectedMessage.receiver} (${selectedMessage.receiverFriendlyName})`}>
                      <span className="friendly-name">
                        {selectedMessage.receiverFriendlyName.length > 23
                          ? selectedMessage.receiverFriendlyName.substring(0, 20) + "..."
                          : selectedMessage.receiverFriendlyName}
                      </span>
                    </Tooltip>
                  ) : (
                    selectedMessage.receiver
                  )}
                </Paragraph>
              </Col>
              <Col span={12}>
                <Text strong>Status:</Text>
                <Paragraph>{selectedMessage.status || selectedMessage.messageStatus || "Unknown"}</Paragraph>
              </Col>
              <Col span={12}>
                <Text strong>Sent At:</Text>
                <Paragraph>{dayjs(selectedMessage.createdTime).format("YYYY-MM-DD HH:mm:ss")}</Paragraph>
              </Col>
              <Col span={12}>
                <Text strong>Provider:</Text>
                <Paragraph>{selectedMessage.provider || "Unknown"}</Paragraph>
              </Col>
              <Col span={12}>
                <Text strong>Sent By:</Text>
                <Paragraph>{selectedMessage.userName || "Unknown"}</Paragraph>
              </Col>
              <Col span={12}>
                <Text strong>Message ID:</Text>
                <Paragraph>{selectedMessage.messageId || selectedMessage.internalMessageId || "Unknown"}</Paragraph>
              </Col>
              {selectedMessage.errorCode && (
                <Col span={12}>
                  <Text strong>Error Code:</Text>
                  <Paragraph>{selectedMessage.errorCode}</Paragraph>
                </Col>
              )}
              {selectedMessage.statusDetails && (
                <Col span={24}>
                  <Text strong>Status Details:</Text>
                  <Paragraph>{selectedMessage.statusDetails}</Paragraph>
                </Col>
              )}
              <Col span={24}>
                <Text strong>Message Content:</Text>
                <Card size="small" className="mt-2">
                  <Paragraph style={{ whiteSpace: "pre-wrap" }}>
                    {selectedMessage.messageBody || selectedMessage.message || "No message content"}
                  </Paragraph>
                </Card>
              </Col>
              {selectedMessage.mediaFileList && selectedMessage.mediaFileList.length > 0 && (
                <Col span={24}>
                  <Text strong>Media Attachments:</Text>
                  <Card size="small" className="mt-2">
                    <ReusableFilePreview
                      fileList={mapToMediaList(selectedMessage.mediaFileList, true)}
                      shape="card"
                      direction="horizontal"
                      customClasses="media-preview-container"
                      initialValues={initialValues}
                    />
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        ) : (
          <div className="text-center py-5">
            <Spin />
            <div className="mt-3">Loading message details...</div>
          </div>
        )}
      </Modal>
    </div>
  );
}

// Default props
MessageLogs.defaultProps = {
  initialAutomationEventId: null,
  initialFilter: null,
  locationState: null,
  hideColumns: [],
  preventHorizontalScroll: false,
  filterConfig: {
    status: false,
    errorCode: false,
    sender: true,
    receiver: true,
    user: true,
    dateRange: true,
    tableSize: "270",
  },
};

export default MessageLogs;
