import { Card } from "antd";
import React, { useContext } from "react";
import HButton from "../../../components/custom/input/button/Button";
import { AppContext } from "../../../context/AppContext";
function NoAccess(props) {
  let { leftServiceName, hasVoiceIntegration } = props;
  return (
    <div className="h-100 overflow-hidden w-100">
      {hasVoiceIntegration ? (
        <NoAccessCard
          leftServiceName={leftServiceName}
          title="Call Access Restricted"
          description={`Contact your administrator to request Call access permissions`}
          button={false}
        />
      ) : (
        <NoAccessCard
          leftServiceName={leftServiceName}
          title="Grant Access:"
          description={`Integrate HelloSend Calling with ${leftServiceName}`}
          button={true}
          buttonText="Connect Now"
        />
      )}
    </div>
  );
}

export default NoAccess;

export const NoAccessCard = (props) => {
  const { button, title, description, buttonText } = props;
  const [appProperties] = useContext(AppContext);
  const handleConnectNow = (e) => {
    appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then(function (params) {
      var redirectLink = "/settings/number/twilio_voice?" + params;
      window.open(redirectLink);
    });
  };
  return (
    <div className="hs-h-80 d-flex justify-content-center align-items-center">
      <Card
        style={{
          width: 300,
          background: "none",
          border: "1px solid #374151",
        }}
      >
        <div className="w-100 d-flex justify-content-center hs-h-20">
          <div className="call-action-icons cursor-pointer voice-restrict-access-indicator" />
        </div>
        <div className="w-100 d-flex justify-content-center hs-h-7">
          <span className="customer-in-offline-message text-white"> {title} </span>
        </div>
        <div className="w-100 d-flex justify-content-center hs-h-7">
          <span className="customer-in-offline-message-secondary"> {description}</span>
        </div>
        {button && (
          <div className="d-flex justify-content-center hs-h-10 w-100 pt-1">
            <HButton size="sm" onClick={handleConnectNow}>
              {buttonText}
            </HButton>
          </div>
        )}
      </Card>
    </div>
  );
};
