import { Badge, Button, Col, Collapse, Drawer, Dropdown, Image, List, Menu, Row, Table, Tag } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/css/omessage.css";
import EmtpyBoxSVG from "../assets/svg/empty-box .svg";
import HSelect from "../components/custom/input/select/Select.jsx";
import { ASSIST_MAIL, urlParams } from "../constants/AppConstants";
import { AppContext } from "../context/AppContext";
import useUsers from "../hooks/useUsers";
import "./../assets/css/users.css";
import {
  failureNotification,
  failureNotificationWithDuration15Seconds,
  failureNotificationWithLink,
  successNotifyWithDescription,
} from "./utils/CommonNotifications";
import {
  checkTheCurrentCustomerIsNotPurchasedUser,
  CommonLoading,
  DeleteComponent,
  EmptyListView,
  RefreshLicenseDetails,
} from "./utils/CommonVessels";
import { showPauseOnHoverSuccessNotification } from "./utils/PauseOnHoverNotifications.js";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import HButton from "../components/custom/input/button/Button.jsx";
import TagWithPopover from "../voice/components/common/TagWithPopover.jsx";
import { useVoiceContext } from "../voice/context/VoiceContext.js";
import { useVoiceTeams } from "../voice/hooks/useVoiceTeams.jsx";
import { getCurrentPlanName } from "../voice/utils/VoiceUtils.js";
import { getCurrentUserDetails } from "./utils/commonUtils.js";

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("local");

function Users() {
  const { getVoiceTeams, deleteAgentsFromMultipleTeam } = useVoiceTeams();
  const [selectedTeams, setSelectedTeams] = useState([]);

  const [voiceTeams, setVoiceTeams] = useState([]);
  const navigate = useNavigate();
  const [appProperties, setAppProperties] = useContext(AppContext);
  const { deleteUser } = useUsers();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [savedUsers, setSavedUsers] = useState([]);
  const [callSavedUserTable, setCallSavedUserTable] = useState(true);
  const [updateTeam, setUpdateTeam] = useState(false);

  let [userId, setUserId] = useState("");
  const [userModal, setUserModal] = useState("");
  const [voiceTeamOptionList, setVoiceTeamOptionList] = useState([]);
  const [openPopover, setOpenPopover] = useState({});
  const [showTable, setShowTable] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [totalPurchasedUser, setTotalPurchasedUser] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [status, setStatus] = useState();
  const [visibleDropdown, setVisibleDropdown] = useState(null);
  const [phoneNumberObj, setPhoneNumberObj] = useState();
  const [addUserButton, setAddUserButton] = useState();
  const [usersList, setUsersList] = useState([]);
  const [osyncUsersId, setOsyncUsersId] = useState();
  const [rolesBased, setRolesBased] = useState();
  const [updatePhoneNumberAccess, setUpdatePhoneNumberAccess] = useState();
  const [selectedVoiceTeam, setSelectedVoiceTeam] = useState([]);
  const [responseObj, setResponseObj] = useState();
  const [isTrialCustomer, setIsTrialCustomer] = useState(false);
  const [licenseType, setLicenseType] = useState();

  useEffect(() => {
    if (appProperties) {
      getVoiceTeams()
        .then((teams) => {
          const formattedOptions = teams.map((team) => ({
            label: team.friendlyName,
            value: team.teamId,
          }));
          setVoiceTeamOptionList(formattedOptions);
          setVoiceTeams(teams);
          setUpdateTeam(false);
        })
        .catch((error) => {
          console.error("Error fetching voice teams:", error);
        });

      setIsTrialCustomer(checkTheCurrentCustomerIsNotPurchasedUser(appProperties));
    }
  }, [appProperties]);

  // useEffect(() => {

  //   if (status && licenseType && (status === 4 || status === 5) && licenseType === "system") {
  //     setIsTrialCustomer(true);
  //   }
  // }, [status, licenseType]);

  useEffect(() => {
    const user = getCurrentUserDetails(appProperties);
    if (user?.roleId === 13) {
      const currentParams = new URLSearchParams(window.location.search);
      const newParams = currentParams?.toString();
      navigate(`/settings/?${newParams}`);
    }
  }, [appProperties, navigate]);
  const [callProperties, setCallProperties] = useVoiceContext();
  useEffect(() => {
    if (callProperties?.openUserPageDrawer === true) {
      setIsDrawerOpen(true);
      setUserModal("AddUsers");
      setCallProperties((prev) => ({
        ...prev,
        openUserPageDrawer: false,
      }));
    }
  }, [callProperties?.openUserPageDrawer]);

  const getUsers = () => {
    setIsDrawerOpen(true);
    setUserId("");
    setUserModal("AddUsers");
  };

  const editUserRole = (record) => {
    setIsDrawerOpen(true);
    setUserId("");
    setUserModal("UpdateUsers");
    setVisibleDropdown(null);
    setSelectedVoiceTeam([]);
    setOsyncUsersId(record?.osyncUserId);
    setUsersList([record?.email]);
    setRolesBased(record?.roleId);
    setUpdatePhoneNumberAccess(record);

    const selectedTeamsWithDetails = record?.voiceTeams?.map((team) => {
      const matchedTeam = voiceTeams.find((existingTeam) => existingTeam.teamId === team.value);
      return {
        label: team.label,
        value: matchedTeam?.teamId || team.value,
        voicePermission: matchedTeam?.voiceAgentsEntity?.find((user) => user.osyncUserId === record.osyncUserId)?.voicePermission || null,
      };
    });

    setSelectedTeams(selectedTeamsWithDetails);
  };

  const deleteUsers = (userData) => {
    userId = userData.remoteUserId;

    let payload = {
      leftServiceId: appProperties.leftServiceId,
      rightServiceId: appProperties.rightServiceId,
      osyncUserId: userData.osyncUserId,
    };
    deleteUser(appProperties, payload).then((response) => {
      if (response?.status === 200) {
        setAppProperties((prev) => ({ ...prev, licenseObj: response.data }));
        setResponseObj(response);
        setCallSavedUserTable(true);
        if (response?.data?.status === "failed") {
          failureNotificationWithLink("At least one user must be retained; deletion is restricted.");
        } else {
          successNotifyWithDescription("Delete User", "User deleted. Feel free to add a new one when needed.");
          const selectedTeamsWithDetails = userData?.voiceTeams?.map((teamLabel) => {
            const matchedTeam = voiceTeams.find((team) => team.friendlyName === teamLabel);
            return {
              label: teamLabel,
              value: matchedTeam?.teamId || null,
              voicePermission:
                matchedTeam?.allLicensedUserDetails?.find((user) => user.osyncUserId === userData.osyncUserId)?.voicePermission || null,
            };
          });
          const payload = {
            teamDetailsArray: selectedTeamsWithDetails,
            agentId: userData.osyncUserId,
          };

          deleteAgentsFromMultipleTeam(payload).then((response) => {
            setUpdateTeam(true);
          });
        }
      }
      setOpenPopover((prevOpenPopover) => ({
        ...prevOpenPopover,
        [userId]: false,
      }));
    });
  };
  let urlParams = new URLSearchParams(window.location.search);
  userId = urlParams.get("userId");

  const handleMenuClick = (e, record) => {
    setVisibleDropdown(visibleDropdown === record.remoteUserId ? null : record.remoteUserId);
  };

  const menu = (record) => <UserDelete record={record} />;

  const UserDelete = (props) => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const { record } = props;

    const handleDeleteClick = () => {
      setDeleteModalOpen(true);
      setVisibleDropdown(null);
    };

    return (
      <>
        <Menu>
          <Menu.Item key="editTemplate" onClick={() => editUserRole(record)}>
            <div className="actionIconsSprite editTemplate-icon">
              <span className="ps-4 hs-fs-12">Edit</span>
            </div>
          </Menu.Item>
          <Menu.Item key="deletetemplate" onClick={() => handleDeleteClick(record)}>
            <div className="actionIconsSprite deleteTemplate-icon">
              <span className="ps-4 hs-fs-12">Delete</span>
            </div>
          </Menu.Item>
        </Menu>
        <DeleteComponent
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          record={record}
          confirmDeleteIcon={"hs-delete-icon"}
          confirmDeleteBtnName={"Delete"}
          confirmDeleteComponentTitle={"Delete Users?"}
          confirmDeleteComponent={"Deleting a user will revoke their access to HelloSend. Do you want to proceed?"}
          appProperties={appProperties}
          handleDelete={deleteUsers}
        />
      </>
    );
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "11rem",
    },
    {
      title: "Role",
      dataIndex: "roleName",
      key: "role",
      width: "9rem",
    },
    {
      title: "Messaging Access",
      dataIndex: "roleBasedNumber",
      key: "phoneNumber",
      width: "15rem",
      render: (roleBasedNumber) => (
        <TagWithPopover
          items={[roleBasedNumber]}
          displayLimit={2}
          itemFormatter={(item) => {
            return String(item)
              .replace(/\s*\(.*?\)/, "")
              .trim();
          }}
          itemValidator={(item) => /^[+]?[\d\s-]+$/.test(item)}
          title="Phone Numbers"
          tagColor="hs-bg-light-silver"
        />
      ),
    },
    {
      title: "Call Access",
      dataIndex: "voiceTeams",
      key: "voiceTeams",
      width: "15rem",
      render: (voiceTeams) => (
        <TagWithPopover
          items={voiceTeams}
          truncateStyle="users-tag-text-truncate"
          title="Teams"
          tagColor="hs-bg-light-silver"
          itemFormatter={(team) => team?.label || ""}
        />
      ),
    },

    {
      key: "editDeleteSettings",
      render: (record) => (
        <>
          {addUserButton &&
          record.remoteUserId !== (appProperties.userId || userId) &&
          appProperties.extensionInstalledUserEmailId &&
          record.email !== appProperties.extensionInstalledUserEmailId &&
          activeUsers > 0 ? (
            <Dropdown
              rootClassName="editDeleteSettingDropdown"
              overlay={menu(record)}
              trigger={["click"]}
              visible={visibleDropdown === record.remoteUserId}
              onVisibleChange={() => handleMenuClick(null, record)}
            >
              <div className="actionIconsSprite  editDeleteSettingsIcon cursor-pointer"></div>
            </Dropdown>
          ) : (
            ""
          )}
        </>
      ),
      width: "5%",
    },
  ];

  useEffect(() => {
    if (callSavedUserTable) {
      let numberOfActiveUsers = 0;
      let licensedUserDetails = null;
      if (appProperties?.licenseObj) {
        licensedUserDetails = appProperties?.licenseObj?.allLicensedUserDetails;
        var phoneObj = appProperties?.allNumbers?.filter((obj) => obj.phoneNumber);
        setPhoneNumberObj(phoneObj);
      } else if (responseObj?.data) {
        licensedUserDetails = responseObj?.data?.allLicensedUserDetails;
      }
      const savedNumberList = appProperties?.allNumbers?.map((phoneObj) => phoneObj?.phoneNumber);

      if (licensedUserDetails) {
        setShowTable(true);
        const rolesMapping = appProperties?.rolesBasedObject;
        const updatedUserDetails = licensedUserDetails?.map((user) => {
          const phoneNumbers = appProperties?.allNumbers
            ?.filter((obj) => obj?.associatedUsers?.includes(user?.osyncUserId))
            ?.map(
              (e) =>
                e?.phoneNumber +
                " (" +
                appProperties?.installedApps?.find((numberObj) => numberObj?.integProps?.integId === e?.integId)?.right?.service_display_name +
                ")"
            );
          if (voiceTeams) {
            var teamOptions = voiceTeams
              ?.filter((team) => team?.voiceAgentsEntity?.some((licensedUser) => licensedUser?.osyncUserId === user?.osyncUserId))
              ?.map((team) => ({
                label: team?.friendlyName,
                value: team?.teamId,
              }));
          }

          const roleBasedNumber = user?.phoneAccess === 1 ? "All Numbers" : user?.phoneAccess === 2 ? phoneNumbers : "System Default";

          return {
            ...user,
            roleName: rolesMapping[user?.roleId],
            roleBasedNumber,
            voiceTeams: teamOptions?.length ? teamOptions : [],
          };
        });

        const currentUserObj = licensedUserDetails?.find((user) => user?.remoteUserId === appProperties?.userId);
        const roleId = currentUserObj?.roleId;
        const userRoleKey = Object?.entries(appProperties?.rolesBasedObject)?.find(([_, value]) => value === "USER")?.[0];

        if (roleId?.toString() === userRoleKey) {
          setAddUserButton(false);
        } else {
          setAddUserButton(true);
        }

        setSavedUsers(updatedUserDetails);
        numberOfActiveUsers = updatedUserDetails?.length ?? 0;
      }
      setActiveUsers(numberOfActiveUsers);
      setTotalPurchasedUser(appProperties?.licenseObj?.licenseDetails?.totalUsersPurchased);
      setStatus(appProperties?.licenseObj?.licenseDetails?.licenseStatus);
      setLicenseType(appProperties?.licenseObj?.licenseDetails?.licenseType);
      setCallSavedUserTable(true);
      setShowLoading(false);
    }
    let showAddUser = urlParams.get("showAddUser");
    if (showAddUser && showAddUser === "true") {
      setIsDrawerOpen(true);

      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      params.delete("showAddUser");
      const newEndpoint = "/users";
      const newUrl = `${url.origin}${newEndpoint}?${params.toString()}`;
      window.history.pushState({}, "", newUrl);
    }
  }, [appProperties, appProperties?.allNumbers, callSavedUserTable, responseObj, voiceTeams]);

  useEffect(() => {
    if (updateTeam) {
      getVoiceTeams()
        .then((teams) => {
          const formattedOptions = teams.map((team) => ({
            label: team.friendlyName,
            value: team.teamId,
          }));
          setVoiceTeamOptionList(formattedOptions);
          setVoiceTeams(teams);
          setUpdateTeam(false);
        })
        .catch((error) => {
          console.error("Error fetching voice teams:", error);
        });
    }
  }, [appProperties, responseObj, updateTeam]);
  return (
    <>
      <div className="mt-3 m-2">
        <Row align="middle">
          <Col xs={0} lg={9} className="ps-4">
            <div className="d-flex">
              <Row className="mt-2 flex-row d-flex align-items-center">
                <Col style={{ cursor: "default" }} className="actionIconsSprite authInfo-icon"></Col>
                <Col className="ms-2 contentbarHeadingColor">Manage user access for seamless customer interactions.</Col>
              </Row>
            </div>
          </Col>
          <>
            <Col xs={24} lg={11}>
              {isTrialCustomer ? null : (
                <Row align="middle">
                  <Col span={24}>
                    <div className="d-flex justify-content-end">
                      <RefreshLicenseDetails usersTabRefreshBtn={true} />
                      <Tag
                        style={{
                          backgroundColor: "#fff",
                          padding: 8,
                          border: 0,
                          borderRadius: 50,
                        }}
                      >
                        Total purchased users
                        <Badge
                          className="ps-1"
                          style={{
                            backgroundColor: "#D9D8FF",
                            color: "#605BFF",
                          }}
                          count={totalPurchasedUser}
                        ></Badge>
                      </Tag>
                      <Tag
                        style={{
                          backgroundColor: "#fff",
                          padding: 8,
                          border: 0,
                          borderRadius: 50,
                        }}
                      >
                        Active users
                        <Badge
                          className="ps-1"
                          style={{
                            backgroundColor: "#D9D8FF",
                            color: "#605BFF",
                          }}
                          count={activeUsers ? activeUsers : 0}
                        ></Badge>
                      </Tag>
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
          </>
          {addUserButton ? (
            <>
              <Col xs={24} lg={4}>
                <div className="d-flex justify-content-center align-items-center">
                  <Button
                    className="hs-border-10 hs-button  hs-big-button hs-fs-16 px-3 hs-button-primary"
                    type=""
                    onClick={getUsers}
                    icon={<div className="actionIconsSprite plusIconWhite" />}
                  >
                    Add User
                  </Button>
                </div>
              </Col>
            </>
          ) : null}
        </Row>

        {isDrawerOpen ? (
          <AddUsers
            voiceTeams={voiceTeams}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            setVoiceTeams={setVoiceTeams}
            voiceTeamOptionList={voiceTeamOptionList}
            setVoiceTeamOptionList={setVoiceTeamOptionList}
            selectedVoiceTeam={selectedVoiceTeam}
            setSelectedVoiceTeam={setSelectedVoiceTeam}
            setIsDrawerOpen={setIsDrawerOpen}
            isDrawerOpen={isDrawerOpen}
            appProperties={appProperties}
            setAppProperties={setAppProperties}
            setCallSavedUserTable={setCallSavedUserTable}
            savedUsers={savedUsers}
            setResponseObj={setResponseObj}
            activeUsers={activeUsers}
            setUserModal={setUserModal}
            userModal={userModal}
            phoneNumberObj={phoneNumberObj}
            usersList={usersList}
            setUsersList={setUsersList}
            osyncUsersId={osyncUsersId}
            rolesBased={rolesBased}
            updatePhoneNumberAccess={updatePhoneNumberAccess}
            updateTeam={updateTeam}
            setUpdateTeam={setUpdateTeam}
            isTrialCustomer={isTrialCustomer}
          />
        ) : null}

        {showLoading ? (
          <CommonLoading />
        ) : showTable ? (
          <Table
            className="savedTempTable automationTable ms-lg-3"
            scroll={{ x: 1100, y: 520 }}
            pagination={false}
            columns={columns}
            dataSource={savedUsers}
          />
        ) : (
          <div className="d-flex justify-content-center">
            <Image style={{ width: "10rem", marginTop: "5rem" }} src={EmtpyBoxSVG} preview={false} />
          </div>
        )}
      </div>
    </>
  );
}

export default Users;

const AddUsers = React.memo((props) => {
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    appProperties,
    setAppProperties,
    setCallSavedUserTable,
    setResponseObj,
    userModal,
    activeUsers,
    savedUsers,
    phoneNumberObj,
    usersList,
    setUsersList,
    osyncUsersId,
    userUpdateList,
    rolesBased,
    selectedTeams,
    setSelectedTeams,
    updatePhoneNumberAccess,
    selectedVoiceTeam,
    setSelectedVoiceTeam,
    setUpdateTeam,
    voiceTeamOptionList,
    isTrialCustomer,
  } = props;

  const [drawerOpen, setDrawerOpen] = useState(isDrawerOpen);

  const { fetchUsers, addUsers } = useUsers();
  const [loadingUserResponse, setLoadingUserResponse] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userRole, setUserRole] = useState(13);
  const [showAllPhoneNumbers, setShowAllPhoneNumbers] = useState("systemDefault");
  const [assignPhoneNumber, setAssignPhoneNumber] = useState();
  const { updateAgentsInMultipleTeam } = useVoiceTeams();
  const [teamsToRemove, setTeamsToRemove] = useState([]);
  const [displayConsentBanner, setDisplayConsentBanner] = useState(false);

  useEffect(() => {
    if (isTrialCustomer || appProperties?.licenseHandledBy === "STRIPE") {
      setDisplayConsentBanner(false);
    } else {
      var remainingAllowedUsersCount = appProperties?.licenseObj?.licenseDetails?.totalUsersPurchased - activeUsers;
      if (remainingAllowedUsersCount <= 0) {
        setDisplayConsentBanner(true);
      }
    }
  }, [activeUsers, appProperties?.licenseHandledBy, appProperties?.licenseObj?.licenseDetails?.totalUsersPurchased, isTrialCustomer]);

  useEffect(() => {
    if (appProperties) {
      setLoadingUserResponse(true);
      fetchUsers(appProperties).then((response) => {
        if (response !== null) {
          let usersList = response.data;
          usersList = usersList?.filter((user) => !savedUsers.some((savedUser) => savedUser.email === user.email));
          setUsers(usersList);
        }
        setLoadingUserResponse(false);
      });
    }
  }, [appProperties]);

  useEffect(() => {
    if (userModal === "UpdateUsers") {
      setUserRole(rolesBased);
      if (Array.isArray(updatePhoneNumberAccess?.roleBasedNumber) && updatePhoneNumberAccess?.phoneAccess === 2) {
        setShowAllPhoneNumbers("assignedNumbersOnly");
        setAssignPhoneNumber(updatePhoneNumberAccess?.roleBasedNumber);
      } else if (updatePhoneNumberAccess?.phoneAccess === 1) {
        setShowAllPhoneNumbers("allNumbers");
      } else {
        setShowAllPhoneNumbers("systemDefault");
      }
    }
  }, [userModal, updatePhoneNumberAccess?.fullPhoneAccess]);

  const addUser = () => {
    setLoading(true);
    const addedUserObjList = users?.filter((obj) => usersList?.includes(obj?.email));
    let addedUser;
    let url;
    let urlMethod;
    if (userModal === "UpdateUsers") {
      url = "update";
      urlMethod = "PUT";
      addedUser = usersList;
    } else {
      url = "add";
      urlMethod = "POST";
      addedUser = addedUserObjList?.map((item) => `${item.id}::${item?.email}`);
    }
    let showAllPhoneNumbersView;
    let phoneAccess;
    if (showAllPhoneNumbers === "allNumbers") {
      showAllPhoneNumbersView = true;
      phoneAccess = 1;
    } else {
      showAllPhoneNumbersView = false;
      phoneAccess = 0;
    }
    let cleanedPhoneNumbers;

    if (showAllPhoneNumbers === "assignedNumbersOnly") {
      if (assignPhoneNumber?.length > 0) {
        cleanedPhoneNumbers = assignPhoneNumber?.map((item) => item?.replace(/\s*\(.*?\)/, ""));
        phoneAccess = 2;
      } else {
        setLoading(false);
        failureNotification("Add user", "Please assign a number to add the user.");
        return;
      }
    }
    if (usersList.length > 0) {
      let payload = {
        leftServiceId: appProperties?.leftServiceId,
        rightServiceId: appProperties?.rightServiceId,
        remoteIdentifier: urlParams.get("companyId"),
        users: addedUser,
        role: userRole,
        fullPhoneAccess: showAllPhoneNumbersView,
        numbers: cleanedPhoneNumbers,
        osyncUserId: osyncUsersId,
        phoneAccess: phoneAccess,
        avatar: addedUserObjList?.[0]?.avatar,
        firstName: addedUserObjList?.[0]?.firstName,
        lastName: addedUserObjList?.[0]?.lastName,
        fullName: addedUserObjList?.[0]?.firstName + " " + addedUserObjList?.[0]?.lastName ? addedUserObjList?.[0]?.lastName : "",
      };
      addUsers(appProperties, payload, url, urlMethod).then((response) => {
        if (response && response.status === 200 && response.data) {
          const allLicensedUserDetails = response.data?.allLicensedUserDetails || [];
          const addedUserRemoteUserIdFromRes = response?.data?.licensedUserDetails?.remoteUserId;
          const addedUserRemoteUserId = addedUser?.map((user) => user.split("::")[0]) || [];
          const osyncUserIdFromResponse = addedUserRemoteUserId
            .map((remoteId) => {
              const recentlyAddedUser = allLicensedUserDetails.find((user) => user.remoteUserId === remoteId);
              return recentlyAddedUser
                ? {
                    osyncUserId: recentlyAddedUser.osyncUserId,
                    remoteUserId: recentlyAddedUser.remoteUserId,
                  }
                : null;
            })
            .filter(Boolean);
          let teamsPayload = {};

          if (
            Array.isArray(selectedTeams) &&
            selectedTeams.length > 0 &&
            Array.isArray(osyncUserIdFromResponse) &&
            osyncUserIdFromResponse.length > 0
          ) {
            teamsPayload = {
              teamDetailsArray: selectedTeams,
              agentIdArray: osyncUserIdFromResponse,
              agentToRemoveFromTheTeam: teamsToRemove,
            };
          } else if (
            osyncUsersId &&
            userModal === "UpdateUsers" &&
            Array.isArray(selectedTeams) &&
            selectedTeams.length > 0 &&
            addedUserRemoteUserIdFromRes
          ) {
            teamsPayload = {
              teamDetailsArray: selectedTeams,
              agentIdArray: [{ osyncUserId: osyncUsersId, remoteUserId: addedUserRemoteUserIdFromRes }],
              agentToRemoveFromTheTeam: teamsToRemove,
            };
          }
          if (Object.keys(teamsPayload).length > 0) {
            updateAgentsInMultipleTeam(teamsPayload)
              .then((updateResponse) => {
                if (updateResponse) {
                  setSelectedVoiceTeam([]);
                  setUpdateTeam(true);
                  setTeamsToRemove([]);
                  setSelectedTeams([]);
                } else {
                  console.error("Update failed:", updateResponse);
                }
              })
              .catch((error) => {
                console.error("Error during updateTeam API call:", error);
              });
          } else {
            console.warn("No valid payload generated: Update operation skipped.");
          }

          setUsersList([]);
          var license = response.data;
          const userNumbersMap = license?.numbers?.reduce((acc, obj) => {
            acc[obj.phoneNumber] = obj?.associatedUsers;

            return acc;
          }, {});

          if (license?.allLicensedUserDetails) {
            if (cleanedPhoneNumbers) {
              setAppProperties((prev) => ({
                ...prev,
                allNumbers: prev?.allNumbers?.map((obj) => {
                  if (Object.keys(userNumbersMap)?.includes(obj.phoneNumber)) {
                    return {
                      ...obj,
                      associatedUsers: userNumbersMap?.[obj.phoneNumber],
                    };
                  }
                  return obj;
                }),
                licenseObj: license,
              }));
            } else {
              setAppProperties((prev) => ({
                ...prev,
                licenseObj: response.data,
              }));
            }
          } else {
            setAppProperties((prev) => ({
              ...prev,
              allNumbers: cleanedPhoneNumbers
                ? prev?.allNumbers?.map((obj) => {
                    if (Object.keys(userNumbersMap).includes(obj.phoneNumber)) {
                      return {
                        ...obj,
                        associatedUsers: userNumbersMap?.[obj.phoneNumber],
                      };
                    }
                    return obj;
                  })
                : !response?.data?.numbers
                ? prev?.allNumbers?.map((obj) => {
                    return {
                      ...obj,
                      associatedUsers: obj?.associatedUsers?.filter((userId) => userId !== osyncUsersId),
                    };
                  })
                : prev?.allNumbers,
              licenseObj: {
                ...prev.licenseObj,
                allLicensedUserDetails: prev?.licenseObj?.allLicensedUserDetails.map((user) =>
                  user?.osyncUserId === license?.licensedUserDetails?.osyncUserId ? { ...license.licensedUserDetails } : user
                ),
              },
            }));
          }

          let responseData = response?.data;

          if (responseData && (responseData?.status === "failed" || responseData?.status === "success_with_failure")) {
            if (responseData?.status === "success_with_failure") {
              setResponseObj(response);
              setCallSavedUserTable(true);
              setIsDrawerOpen(false);
              successNotifyWithDescription("Add Users", "User added! They're now part of the team." + responseData?.successUserList);
            }
            if (responseData?.existingEmailIdsList) {
              failureNotificationWithDuration15Seconds("User Already Part of Team", responseData?.existingEmailIdsList);
            }
            if (responseData?.failedUsersList) {
              failureNotification("Add user", responseData?.failedUsersList);
            }
            setIsLoading(false);
            setLoading(false);
          } else {
            setResponseObj(response);
            setCallSavedUserTable(true);
            setIsDrawerOpen(false);

            successNotifyWithDescription("Add User", "User have been updated successfully.");
          }
        } else {
          var userLimit = `Some error occured while adding users. Please contact ${ASSIST_MAIL} to add more users.`;
          failureNotificationWithLink(userLimit, `${ASSIST_MAIL}`, `mailto: ${ASSIST_MAIL}`);
        }
      });
    } else {
      setLoading(false);
      failureNotification("Add user", "Please select one user to continue.");
    }
  };

  const handleClose = () => {
    setDrawerOpen(false);
    setIsDrawerOpen(false);
    setUsersList([]);
    setSelectedTeams([]);
    setTeamsToRemove([]);
  };

  const handleRoleChange = (value, key) => {
    setUserRole(value);
  };
  const handlePhoneAccessChange = (value) => {
    setShowAllPhoneNumbers(value);
  };
  const handleSelectTeam = (value) => {
    if (value && !selectedTeams.some((team) => team.value === value)) {
      const selectedTeam = voiceTeamOptionList.find((team) => team.value === value);
      setSelectedTeams([{ ...selectedTeam, voicePermission: 1 }, ...selectedTeams]);
    }
    setSelectedVoiceTeam(value);
  };

  const handleRemoveTeam = (teamValue) => {
    setSelectedTeams(selectedTeams.filter((team) => team.value !== teamValue));
    setTeamsToRemove((prev) => [...prev, teamValue]);
  };

  const handlePermissionChange = (permissionValue, teamId) => {
    setSelectedTeams((prevTeams) => prevTeams.map((team) => (team.value === teamId ? { ...team, voicePermission: permissionValue } : team)));
  };

  const phoneNumberoptions = phoneNumberObj?.map((user) => {
    return {
      value: `${user?.phoneNumber} (${
        appProperties?.installedApps?.find((obj) => obj?.integProps?.integId === user?.integId)?.right?.service_display_name
      })`,
      label: (
        <>
          <span
            className={
              "me-2 ps-3 numberlist-sprites users-number-list users-number-list-" +
              appProperties?.installedApps?.find((obj) => obj?.integProps?.integId === user?.integId)?.right?.service_name
            }
          ></span>{" "}
          {user?.phoneNumber}
        </>
      ),
      labelText: user?.phoneNumber,
    };
  });
  const configChannelItems = [
    {
      key: "1",
      label: (
        <div className="d-flex flex-row align-items-center">
          <div className="actionIconsSprite add-user-drawer-messaging-icon" />
          <div className="hs-fw-600 ps-2">Messaging Settings</div>
        </div>
      ),
      children: (
        <Row className="mt-3">
          <Col xs={24} md={24}>
            Phone Number Access
            <div className="mt-2">
              <HSelect
                customProps={{
                  onSelect: handlePhoneAccessChange,
                  value: showAllPhoneNumbers,
                }}
                loading={loadingUserResponse}
                options={[
                  {
                    value: "allNumbers",
                    label: "All Numbers",
                  },
                  {
                    value: "systemDefault",
                    label: "System Default",
                  },
                  {
                    value: "assignedNumbersOnly",
                    label: "Assigned Numbers Only",
                  },
                ]}
              />
            </div>
            <div className="mt-2 hs-color-mediumDark hs-fs-12">
              {
                {
                  allNumbers: "Access to all phone numbers in the account.",
                  systemDefault: (
                    <>
                      <span className="text-decoration-underline">Twilio & WhatsApp</span>: All numbers will be accessible to everyone.
                      <br />
                      <span className="text-decoration-underline">RingCentral</span>: Users must grant authorization to use their numbers.
                    </>
                  ),
                  assignedNumbersOnly: "Access restricted to specific numbers assigned by the admin.",
                }[showAllPhoneNumbers]
              }
            </div>
          </Col>

          {showAllPhoneNumbers === "assignedNumbersOnly" && (
            <Col xs={24} md={24} className="mt-4">
              Phone Numbers
              <div className="mt-2 hs-h-70">
                <HSelect
                  mode="multiple"
                  size="large"
                  options={phoneNumberoptions}
                  customProps={{
                    onChange: (values) => {
                      setAssignPhoneNumber(values);
                    },
                    value: assignPhoneNumber,
                  }}
                  defaultTagRender={{
                    onCloseSetState: setAssignPhoneNumber,
                    tagItemToRender: "value",
                  }}
                  placeholder="Choose Phone numbers"
                  bordered={false}
                  loading={loadingUserResponse}
                  defaultValue={
                    userModal === "UpdateUsers" && Array.isArray(userUpdateList?.roleBasedNumber) && userUpdateList?.roleBasedNumber.length > 0
                      ? userUpdateList?.roleBasedNumber
                      : []
                  }
                >
                  {phoneNumberObj?.map((user, index) => (
                    <HSelect.Option
                      key={index}
                      value={`${user?.phoneNumber}(${
                        appProperties?.installedApps?.find((obj) => obj?.integProps?.integId === user?.integId)?.right?.service_display_name
                      })`}
                    >
                      <span
                        className={
                          "me-2 ps-3 numberlist-sprites users-number-list users-number-list-" +
                          appProperties?.installedApps?.find((obj) => obj?.integProps?.integId === user?.integId)?.right?.service_name
                        }
                      ></span>{" "}
                      <span className="">{user?.phoneNumber}</span>
                    </HSelect.Option>
                  ))}
                </HSelect>
              </div>
            </Col>
          )}
        </Row>
      ),
      collapsible: getCurrentPlanName(appProperties?.licenseObj?.licenseDetails?.planName) === "Call" ? "disabled" : undefined,
    },
    {
      key: "2",
      label: (
        <div className="d-flex flex-row align-items-center">
          <div className="actionIconsSprite add-user-drawer-voice-icon" />
          <div className="hs-fw-600 ps-2">Call Settings</div>
        </div>
      ),
      children: (
        <Row>
          <Col xs={24} md={24} className="pb-2 pt-1">
            <div className="hs-fw-500 pb-2">Teams</div>
            <HSelect
              customProps={{
                onSelect: handleSelectTeam,
                value: selectedVoiceTeam,
              }}
              allowClear
              placeholder="Select Teams to add below"
              options={voiceTeamOptionList}
            />
            <div className="hs-fs-11-9-px hs-color-mediumDark pt-3">Assign this user to an available Team</div>
          </Col>
          <Col xs={24} md={24} className="pb-1">
            <List
              header={
                <div className="d-flex flex-row justify-content-between hs-w-75">
                  <div className="hs-fs-13 hs-fw-400">Teams</div>
                  <div className="hs-fs-13 hs-fw-400">Access</div>
                </div>
              }
              className="users-selectedTeams-list "
              dataSource={selectedTeams}
              renderItem={(team) => (
                <List.Item
                  actions={[
                    <HSelect
                      size="small"
                      customProps={{
                        value: team?.voicePermission ?? 1,
                        className: "users-voice-permission-select mb-2",
                        suffixIcon: <div className="actionIconsSprite smallSize-fromNumberDropDownIcon" />,
                        onSelect: (value) => handlePermissionChange(value, team?.value),
                      }}
                      placeholder="Select Permission"
                      options={[
                        { label: "All", value: 1 },
                        { label: "Inbound", value: 2 },
                        { label: "Outbound", value: 3 },
                      ]}
                    />,
                    <div className="actionIconsSprite small-close-icon" onClick={() => handleRemoveTeam(team?.value)} />,
                  ]}
                >
                  <span className="hs-fs-12 hs-fw-500">{team.label}</span>
                </List.Item>
              )}
              locale={{
                emptyText: (
                  <EmptyListView
                    // tagLineOne={`No Teams added`}
                    // icon={`emptyTeamsPageIcon display-inline-block`}
                    tagLineTwo={`No Teams added`}
                    tagHeight={"13vh"}
                  />
                ),
              }}
            />
          </Col>
        </Row>
      ),
      collapsible: getCurrentPlanName(appProperties?.licenseObj?.licenseDetails?.planName) === "Message" ? "disabled" : undefined,
    },
  ];

  const usersOption = users?.map((user) => ({
    label: `${user?.firstName} (${user?.email})`,
    value: user?.email,
  }));
  return (
    <>
      <Drawer
        className="userDrawer"
        title={userModal === "AddUsers" ? "Add user" : "Edit user"}
        open={drawerOpen}
        onClose={handleClose}
        placement="right"
        width={"30rem"}
        height={"100rem"}
        footer={
          <div className="w-100">
            {displayConsentBanner ? (
              <Row className="w-100 hs-bg-light-silver hs-border-light-lavender hs-color-violet hs-border-8 mb-3 p-2 ">
                <Col span={2} className="hs-fs-12 hs-fw-500">
                  Note:
                </Col>
                <Col span={22} className="hs-fs-12 hs-fw-400">
                  The licensed user limit has been reached. Adding more users will adjust your subscription, and you will be charged for the new
                  user at a prorated amount.
                </Col>
              </Row>
            ) : null}
            <Row className="w-100">
              <Col className="w-100">
                <div className="templatesAddButton w-100">
                  <HButton buttonClassName="hs-fs-16 px-3 w-100" loading={loading} size="l" onClick={addUser}>
                    {userModal === "AddUsers" ? "Add" : "Update"}
                  </HButton>
                </div>
              </Col>
            </Row>
          </div>
        }
      >
        <div className="userModal">
          <Row>
            <Col xs={24} md={24}>
              User
              <div className="mt-2 h-50">
                <div className="h-100">
                  <HSelect
                    options={usersOption}
                    placeholder="Choose users"
                    customProps={{
                      disabled: userModal === "UpdateUsers" ? true : false,
                      className: " h-100",
                      value: usersList,
                      onChange: (e) => {
                        setUsersList((prev) => [e]);
                      },
                    }}
                    loading={loadingUserResponse}
                  ></HSelect>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={24} md={24}>
              Role
              <div className="mt-2">
                <HSelect
                  placeholder="Choose Roles"
                  customProps={{
                    value: userRole ? userRole : "",
                    onSelect: handleRoleChange,
                  }}
                  loading={loadingUserResponse}
                  options={
                    appProperties?.rolesBasedObject
                      ? Object.entries(appProperties?.rolesBasedObject)
                          .filter(([_, value]) => value !== "ACCOUNT OWNER")
                          .map(([key, value]) => ({ label: value, value: Number(key) }))
                      : []
                  }
                ></HSelect>
              </div>
            </Col>
          </Row>
          <Row className="w-100 mt-3">
            <Collapse accordion ghost rootClassName="users-configuration-collapse w-100" items={configChannelItems} expandIconPosition={"end"} />
          </Row>
        </div>
      </Drawer>
    </>
  );
});
