import { urlParams } from "./../constants/AppConstants";
import SendSmsDemo from "../assets/video/ZohoCRMSendSmsVideo.mp4";
import Thumbnail from "../assets/images/ZohoCrmSendsms-thumbnail.png";

function getAllUrlParams() {
  var allUrlParams = new URLSearchParams(window.location.search);
  var resourceObj = {};
  for (var key of allUrlParams.keys()) {
    resourceObj[key] = allUrlParams.get(key);
  }
  var resourceStr = JSON.stringify(resourceObj);
  return encodeURIComponent(resourceStr);
}

const ZohoCRM = {
  primaryPhoneApiName: "Phone",
  hideAutoFocusMessageArea: true,
  secondaryPhoneApiName: "Mobile",
  serviceInitialized: false,
  selectedEntityIdsFromZohoCRM: "",
  selectedEntityModuleFromZohoCRM: "",
  configData: {},
  service: "Zoho CRM",
  isConversationViewInSendSMS: true,
  isCustomModuleEnabled: true,
  workFlowIconInTemplates: true,
  isWorkflowTableFormat: false,
  redirectUrl: true,
  contactNumber: true,
  api: "api/v2/",
  addUserButton: true,
  associatedLookup: true,
  landingPage: true,
  landingPagePathname: "/zoho-crm/landing",
  SMS_GALLERY: [
    {
      videoUrl: "https://www.youtube.com/embed/AAKbzpdPStA",
    },
    {
      videoUrl: "https://www.youtube.com/embed/YU49UlzFTZw",
    },
    {
      videoUrl: "https://www.youtube.com/embed/f24zG7tPHLE",
    },
  ],
  VOICE_GALLERY: [
    {
      videoUrl: "https://www.youtube.com/embed/tbvIB0WfFVQ",
    },
    {
      videoUrl: "https://www.youtube.com/embed/s_actXvJJHY",
    },
  ],
  WHATSAPP_GALLERY: [
    {
      headline: "Boost Sales with Direct WhatsApp Messaging in Zoho CRM",
      sourceUrl: "https://www.youtube.com/embed/Wtvb0a4FCis?si=wbH3DxkMm5IlZOFE",
      steps: ["Send WhatsApp messages to Zoho CRM prospects ", `Track all conversation history within Zoho CRM`],
    },
    {
      headline: `Automate SMS and WhatsApp Reminders from Zoho CRM with HelloSend`,
      sourceUrl: "https://www.youtube.com/embed/BVLCy3R6C6E?si=CWHOgwAKK7eKaxpY",
      steps: ["Save WhatsApp templates and turn on automation", `Drive more sales through engagement`],
    },
    {
      headline: `Automate SMS / WhatsApp message When Deal Stages Change in Zoho CRM`,
      sourceUrl: "https://www.youtube.com/embed/41GWpzW8jXw?si=8Yn0cLu8HMVIy_jS",
      steps: ["Send templated messages based on deal stage", `Engage at the right time and boost conversions`],
    },
  ],
  automationPageHelpVideo: [
    {
      headline: "Automate SMS / WhatsApp messages  for Deal Stage Changes",
      sourceUrl: "https://www.youtube.com/embed/41GWpzW8jXw?si=4zKwo2AKhpJ2Vwpu",
      steps: ["Send automatic messages to clients when deals progress in Zoho CRM"],
    },
    {
      headline: "Automate SMS / WhatsApp messages on Specific Date in Zoho CRM",
      sourceUrl: "https://www.youtube.com/embed/BEifAzENT_M",
      steps: ["Send automatic SMS / WhatsApp reminders for important dates like appointments, renewals, and more"],
    },
    {
      headline: "Automate SMS / WhatsApp Reminders from Zoho CRM",
      sourceUrl: "https://www.youtube.com/embed/BVLCy3R6C6E",
      steps: ["Set up automatic SMS / WhatsApp reminders for meetings directly in Zoho CRM"],
    },
    {
      headline: "Automate welcome SMS / WhatsApp messages  to your new leads in Zoho CRM",
      sourceUrl: "https://www.youtube.com/embed/YU49UlzFTZw",
      steps: ["Automate sending welcome SMS / WhatsApp messages to leads as soon as they're added to Zoho CRM."],
    },
    {
      headline: "Configuring SMS / WhatsApp Automation",
      sourceUrl: "https://www.youtube.com/embed/g2G-HGeOiyI?si=Wouh6BQfScDbaqEi",
      steps: ["Learn how to set up automatic SMS / WhatsApp notification in 2 simple steps"],
    },
    {
      headline: `Automate SMS / WhatsApp messages in Zoho CRM`,
      sourceUrl: "https://www.youtube.com/embed/g2G-HGeOiyI?si=Wouh6BQfScDbaqEi",
      steps: [`Learn to automate SMS / WhatsApp messages in Zoho CRM for your business needs.`],
    },
  ],
  sendSmsVideoComponent: <video width={"100%"} height={"100%"} className="mt-2" poster={Thumbnail} src={SendSmsDemo} controls />,
  sideBarExtend: {
    homeSideBar: false,
    serviceSideBar: true,
    inboxSidebar: true,
    conversationSidebar: false,
    sendSmsSidebar: true,
    sendBulkSmsSidebar: false,
    phoneSidebar: false,
    pagesSidebar: false,
    templateSideBar: true,
    workFlowSideBar: true,
    usersSideBar: true,
    workflowTabName: "Webhook",
    workflowTabSingularName: "Webhook",
    hideSMSTabsInSideBar: false,
    campaignSidebar: true,
    campaignOverviewSidebar: true,
    automationSideBar: true,
  },
  isPhoneConfigurationEnabled: true,
  isBulkSMSPhoneConfigureDefaultValue: "Contacts",
  redirectPage: urlParams.get("serviceOrigin") ? urlParams.get("serviceOrigin") + "/" : "https://crm.zoho.com/",
  redirectPath: urlParams.get("serviceOrigin") ? urlParams.get("serviceOrigin") + "/" : "https://crm.zoho.com/",
  campaignLink: true,
  associateLookupField: true,
  filterTypeMap: {},

  loadScripts: function () {
    return new Promise((resolve, reject) => {
      resolve("https://live.zwidgets.com/js-sdk/1.2/ZohoEmbededAppSDK.min.js");
    });
  },
  pricingLink: function (appProperties) {
    return new Promise((resolve, reject) => {
      const { hash, osyncId, domain, companyId, userId, leftServiceName, installedApps, randomIntegId } = appProperties;
      const integIds = installedApps?.map((integration) => integration?.integProps?.integId);
      var leftServiceId = urlParams.get("leftServiceId");
      var allUrlParams = getAllUrlParams();
      const decodedParams = decodeURIComponent(allUrlParams);
      let paramsObject = JSON.parse(decodedParams);
      if (paramsObject.service === "zohocrm") {
        paramsObject.service = "zohocrmredirect";
        paramsObject.domain = "org" + companyId;
        paramsObject.userId = userId;
        paramsObject.companyId = companyId;
      }
      const updatedUrlParams = encodeURIComponent(JSON.stringify(paramsObject));

      var serviceOrigin = urlParams.get("serviceOrigin");
      var hrefUrl = `/pricing?productId=102&groupName=OMNI_CHANNEL&domain=${domain}&hash=${hash}&leftServiceId=${leftServiceId}&osyncId=${osyncId}&urlParams=${updatedUrlParams}&companyId=${companyId}&userId=${userId}&integIds=${integIds}&serviceOrigin=${serviceOrigin}&service=${leftServiceName}`;

      resolve(hrefUrl);
    });
  },
  getUrlParamsForRedirection: function (isNewWindow, appProperties) {
    return new Promise((resolve, reject) => {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete("sideBar");
      urlParams.delete("topBar");
      urlParams.delete("fullView");
      urlParams.delete("source");
      var rightServiceId = appProperties?.rightServiceId;
      if (isNewWindow) {
        urlParams.delete("service");
        urlParams.append("service", "zohocrmredirect");
      }
      if (!rightServiceId) {
        rightServiceId = urlParams.get("rightServiceId");
      }

      if (rightServiceId) {
        urlParams.delete("rightServiceId");
        urlParams.append("rightServiceId", rightServiceId);
      }

      let companyId = appProperties?.companyId;
      let userId = appProperties?.userId;

      if (!companyId) {
        companyId = urlParams.get("companyId");
      }
      if (companyId) {
        urlParams.delete("companyId");
        urlParams.append("companyId", companyId);
        urlParams.delete("domain");
        // urlParams.set("domain", "org" + companyId);
        urlParams.append("domain", "org" + companyId);
      }

      if (!userId) {
        userId = urlParams.get("userId");
      }

      if (userId) {
        urlParams.delete("userId");
        urlParams.append("userId", userId);
      }
      console.log("urlParams>>>>Controller", urlParams.toString());
      resolve(urlParams.toString());
    });
  },
  getUserData: function () {
    var companyId = urlParams.get("companyId");
    var emailId = urlParams.get("email");
    var userId = urlParams.get("userId");
    var domain = urlParams.get("domain");
    var companyOrgId = companyId;
    return new Promise((resolve, reject) => {
      var userData = {
        companyId: companyId,
        userId: userId,
        companyOrgId: companyOrgId,
        domain: domain,
        emailId: emailId,
      };
      resolve(userData);
    });
  },
  getAssociatedObjectTypeInPlural: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve(ZohoCRM.selectedEntityModuleFromZohoCRM);
    });
  },
  getFields: function (fieldsModule) {
    return new Promise((resolve, reject) => {
      window.ZOHO.CRM.META.getFields({ Entity: fieldsModule }).then(function (data) {
        resolve(data);
      });
    });
  },
  getAssociatedId: function (fieldsModule) {
    return new Promise((resolve, reject) => {
      this.getFields(fieldsModule).then(function (data) {
        // let parentModule = (moudule === module) ? "" : module;
        var lookupData = data?.fields;
        var fieldLabels = [];
        lookupData?.forEach(function (field) {
          var associateModuleData = {
            associateModuleField: field.field_label,
            associateIdModuleField: field.id,
            module: fieldsModule,
          };
          fieldLabels.push(associateModuleData);
        });
        resolve(fieldLabels);
      });
    });
  },

  fetchAssociateModuleByFields: function (moduleToFetch, moduleFieldsApiNameToFetch) {
    return new Promise((resolve, reject) => {
      this.getFields(moduleToFetch).then(function (data) {
        let parentModule = moduleToFetch === ZohoCRM.selectedEntityModuleFromZohoCRM ? "" : ZohoCRM.selectedEntityModuleFromZohoCRM;
        var lookupData = data?.fields;
        var fieldLabels = [];
        lookupData?.forEach(function (field) {
          if (field?.data_type === "phone") {
            var associateModuleData = {};
            if (moduleFieldsApiNameToFetch === moduleToFetch) {
              associateModuleData = {
                currentFieldModule: moduleToFetch,
                associateModuleField: field.field_label,
                associateModuleFieldAPIName: field.api_name,
                parentModule: parentModule,
                toNumber: "${!" + moduleFieldsApiNameToFetch + "." + field.api_name + "}",
              };
            } else {
              associateModuleData = {
                currentFieldModule: moduleToFetch,
                associateModuleField: field.field_label,
                associateModuleFieldAPIName: field.api_name,
                parentModule: parentModule,
                toNumber: "${!" + ZohoCRM.selectedEntityModuleFromZohoCRM + "." + moduleFieldsApiNameToFetch + "." + field.api_name + "}",
              };
            }
            fieldLabels.push(associateModuleData);
          }
        });
        resolve(fieldLabels);
      });
    });
  },

  fetchAssociateModule: function (moduleToFetch) {
    return new Promise((resolve, reject) => {
      this.getFields(moduleToFetch).then(function (data) {
        var lookupData = data?.fields;
        var fieldLabels = [];
        lookupData?.forEach(function (field) {
          if (field?.data_type === "lookup") {
            if (field.lookup && field.lookup.module && field.lookup.module.api_name) {
              var associateModuleData = {
                associateModuleField: field.field_label,
                associateFieldModule: field.lookup.module.api_name,
                associateFieldModuleAPIName: field.api_name,
                module: moduleToFetch,
              };
              fieldLabels.push(associateModuleData); // Collect field labels
            }
          }
        });
        resolve(fieldLabels);
      });
    });
  },
  fetchContactsCount: function (fetchFilterInfoByFilterId, appProperties, integId, module) {
    return new Promise((resolve, reject) => {
      resolve(ZohoCRM.selectedEntityIdsFromZohoCRM?.length);
    });
  },
  getWebhookContent: function (fetchData, fetchWebhookNotifications, appProperties, templateId) {
    return new Promise((resolve, reject) => {
      fetchWebhookNotifications(appProperties, templateId).then((response) => {
        resolve(response);
      });
    });
  },
  getSettingPreference: function (settingsObj) {
    return new Promise((resolve, reject) => {
      const keysToRemove = ["emailNotification", "defaultCountryCode", "inboxAccessSettings"];
      const filteredArray = settingsObj.filter((item) => item && (keysToRemove.includes(item.key) || keysToRemove.includes(item.parentKey)));
      resolve(filteredArray);
    });
  },
  getDefaultModuleForAGivenModule: function (module) {
    return new Promise((resolve) => {
      if (module === "Deals" || module === "Tasks") {
        resolve(["Contact_Name", "Account_Name"]);
      }
      resolve("");
    });
  },
  openRecord: function (sdk, id, module, recordLink) {
    return new Promise((resolve, reject) => {
      window.open(recordLink, "_blank");
    });
  },
};

export default ZohoCRM;
