import { Table, Typography, Avatar, Tooltip, Button, Spin, Pagination } from "antd";
import { LoadingOutlined, EyeOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import { capitalizeFirstLetter } from "../../pages/utils/commonUtils";
import dayjs from "dayjs";

const { Text } = Typography;

const MessageLogsTable = React.memo((props) => {
  const {
    data,
    loading,
    fetchFn,
    filters,
    onViewMessage,
    initialPaginationSize = 10,
    hideColumns = [], // Array of column keys to hide
    filterConfig, // Filter configuration for the table
    initialLastKey = null, // Initial lastKey value from parent
  } = props;

  // Using AppContext
  const [_] = useContext(AppContext);
  const [hasMore, setHasMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentLastKey, setCurrentLastKey] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const initialLoadRef = useRef(true);

  // We no longer need this effect as we're getting the lastKey directly from the parent
  // which handles all API calls and pagination state
  // This removes the loop that was occurring

  // Update hasMore when currentLastKey changes, ensuring proper state
  useEffect(() => {
    // A null/undefined/empty string lastKey means no more pages
    const isLastKeyEmpty =
      !currentLastKey ||
      (typeof currentLastKey === "string" && currentLastKey.trim() === "") ||
      currentLastKey === "null" ||
      currentLastKey === "undefined";

    // Only enable hasMore if we have data and a valid lastKey
    // This is crucial - if there's no data, we shouldn't show pagination even with lastKey
    const shouldEnablePagination = !isLastKeyEmpty && data && data.length > 0;

    setHasMore(shouldEnablePagination);
  }, [currentLastKey, data]);

  // Reset state when filters change, but don't reset lastKey to null
  // This fixes the issue where the Next button is disabled after filtering
  useEffect(() => {
    setCurrentPage(1);
    // Set initialLoadRef to true so we'll check for lastKey on the next data load
    initialLoadRef.current = true;
    // Don't reset the lastKey to null when filters change
    // The lastKey will be updated when the new data is fetched
  }, [filters]);

  // Update currentLastKey when initialLastKey prop changes
  useEffect(() => {
    // Even when initialLastKey is null/undefined, update the state
    // This is important to correctly disable the Next button when no more pages
    setCurrentLastKey(initialLastKey);
  }, [initialLastKey]);

  // Column definitions - with more equal widths for compact mode
  const columns = [
    {
      title: "Sender",
      dataIndex: "sender",
      key: "sender",
      className: "message-logs-cells",
      width: "15%",
      ellipsis: true,
      render: (sender, item) => {
        // Display friendly name if available, otherwise show the number
        let displayText = sender;
        let tooltipContent = sender;

        if (item.senderFriendlyName) {
          // Check if the friendly name is longer than 23 chars
          if (item.senderFriendlyName.length > 23) {
            displayText = item.senderFriendlyName.substring(0, 20) + "...";
          } else {
            displayText = item.senderFriendlyName;
          }
          // Set tooltip to show both original number and full friendly name
          tooltipContent = `${sender} (${item.senderFriendlyName})`;
        }

        const getServiceIconClass = (serviceName) => {
          const lowerCaseServiceName = serviceName.toLowerCase();
          if (["twilio", "ringcentral", "whatsapp"].includes(lowerCaseServiceName)) {
            return `staticIconsSprite smallSize-${lowerCaseServiceName}-icon`;
          }
          return "";
        };

        return (
          <div className="h-100 d-flex align-items-center cell-inner-text">
            <Tooltip title={tooltipContent}>
              <span className={item.senderFriendlyName ? "friendly-name" : ""}>{displayText}</span>
            </Tooltip>
            {item?.serviceName && item.status !== "received" && (
              <Tooltip title={capitalizeFirstLetter(item.serviceName)}>
                <span className={`${getServiceIconClass(item.serviceName)}`} style={{ transform: "scale(0.7)", opacity: "70%" }}></span>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Receiver",
      dataIndex: "receiver",
      key: "receiver",
      className: "message-logs-cells",
      width: "15%",
      ellipsis: true,
      render: (receiver, item) => {
        // Display friendly name if available, otherwise show the number
        let displayText = receiver;
        let tooltipContent = receiver;

        if (item.receiverFriendlyName) {
          // Check if the friendly name is longer than 23 chars
          if (item.receiverFriendlyName.length > 23) {
            displayText = item.receiverFriendlyName.substring(0, 20) + "...";
          } else {
            displayText = item.receiverFriendlyName;
          }
          // Set tooltip to show both original number and full friendly name
          tooltipContent = `${receiver} (${item.receiverFriendlyName})`;
        }

        return (
          <div className="h-100 d-flex align-items-center cell-inner-text">
            <Tooltip title={tooltipContent}>
              <span className={item.receiverFriendlyName ? "friendly-name" : ""}>{displayText}</span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Message",
      dataIndex: "messageBody",
      key: "messageBody",
      className: "message-logs-cells",
      width: "10%", // Slightly wider than other columns
      ellipsis: true,
      render: (messageBody, item) => {
        const maxLength = 30;
        const displayText = messageBody?.length > maxLength ? `${messageBody.slice(0, maxLength)}...` : messageBody;
        const hasMediaFiles = item.mediaFileList && item.mediaFileList.length > 0;

        return (
          <div className="w-100 d-flex align-items-center gap-2 cell-inner-text">
            <Tooltip title={messageBody}>
              <div className="actionIconsSprite bg-white preview-eye-icon" />
            </Tooltip>
            {hasMediaFiles && (
              <Tooltip title="Media attachments">
                <div className="actionIconsSprite conversationPageMediaUploadIcon" />
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Time",
      dataIndex: "createdTime",
      key: "createdTime",
      className: "message-logs-cells",
      width: "15%",
      render: (text) => {
        const formattedTime = dayjs(text).format("YYYY-MM-DD HH:mm"); // 24-hour format
        return (
          <div className="d-flex h-100 align-items-center cell-inner-text">
            <Tooltip title={formattedTime}>
              <Text className="cell-inner-text">{formattedTime}</Text>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "message-logs-cells",
      width: "15%",
      align: "center",
      render: (status) => {
        const statusText = capitalizeFirstLetter(status || "unknown");
        let statusClassName = "";

        switch (status) {
          case "delivered":
            statusClassName = "status-delivered";
            break;
          case "failed":
            statusClassName = "status-failed";
            break;
          case "received":
            statusClassName = "status-received";
            break;
          case "sent":
            statusClassName = "status-sent";
            break;
          case "queued":
            statusClassName = "status-queued";
            break;
          case "read":
            statusClassName = "status-read";
            break;
          case "deleted":
            statusClassName = "status-deleted";
            break;
          default:
            statusClassName = "status-pending";
        }

        return (
          <div className="d-flex align-items-center justify-content-center">
            <div className={`px-3 hs-w-fit hs-border-20 message-status-badge ${statusClassName}`}>{statusText}</div>
          </div>
        );
      },
    },
    {
      title: "Error Code",
      dataIndex: "errorCode",
      key: "errorCode",
      className: "message-logs-cells",
      width: "15%",
      render: (errorCode, record) => {
        // Show statusDetails on hover for failed messages
        const showTooltip = record.status === "failed" && record.statusDetails;

        return (
          <div className="d-flex h-100 align-items-center cell-inner-text">
            {showTooltip ? (
              <Tooltip title={record.statusDetails}>
                <span className="text-danger cursor-pointer">{errorCode || "Error"}</span>
              </Tooltip>
            ) : (
              errorCode || "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Sent Via",
      dataIndex: "sent_via",
      key: "sent_via",
      className: "message-logs-cells",
      width: 120,
      render: (sent_via, record) => {
        let sentViaText = "Unknown";
        let linkUrl = null;

        // Check for sent_via value and map to corresponding text
        if (sent_via || record.sent_via) {
          const sentViaValue = sent_via || record.sent_via;

          switch (Number(sentViaValue)) {
            case 1:
              sentViaText = "DM";
              break;
            case 2:
              sentViaText = "Received";
              break;
            case 3:
              sentViaText = "Automation";
              // If this is an automation message and we have a source ID
              if (record.sentViaSourceId) {
                // Preserve current URL parameters when linking to automation logs
                const currentUrlParams = window.location.search;
                linkUrl = `/messaging/automation/${record.sentViaSourceId}/logs${currentUrlParams}`;
              }
              break;
            case 4:
              sentViaText = "Campaign";
              // If this is a campaign message and we have a campaign ID
              if (record.campaignId) {
                // Link to campaign logs view and maintain current URL params
                const currentUrlParams = window.location.search;
                linkUrl = `/messaging/campaigns/list/${record.campaignId}/logs${currentUrlParams}`;
              }
              break;
            case 5:
              sentViaText = "External device";
              break;
            default:
              sentViaText = "Unknown";
          }
        }

        // If this is a source that should be linked but missing ID, we won't show the link

        return (
          <div className="d-flex h-100 align-items-center cell-inner-text">
            {linkUrl ? (
              <a
                href={linkUrl}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent row click from triggering
                  // Allow default action for Ctrl/Cmd click to open in new tab
                }}
                className="cellInnerText"
                style={{
                  color: "#1890ff",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                onMouseEnter={(e) => (e.target.style.textDecoration = "underline")}
                onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
              >
                {sentViaText}
              </a>
            ) : (
              sentViaText
            )}
          </div>
        );
      },
    },
    {
      title: "User",
      dataIndex: "userName",
      key: "userName",
      className: "message-logs-cells",
      width: 150,
      render: (userName) => {
        return <div className="d-flex h-100 align-items-center cell-inner-text">{userName || "-"}</div>;
      },
    },
  ];

  // Custom empty state display
  const locale = {
    emptyText: (
      <div style={{ height: "65dvh" }}>
        <div className="d-flex flex-grow-* gap-5 flex-column align-items-center justify-content-center h-100">
          <div className="d-flex gap-3 flex-column align-items-center">
            <div className="staticIconsSprite rocketWithAirIcon"></div>
            <div className="d-flex flex-column align-items-center justify-content-center">
              <div className="hs-fs-16">No message logs found</div>
              <div className="hs-fs-12">Messages sent from your account will be listed here.</div>
            </div>
          </div>
        </div>
      </div>
    ),
  };

  // Scroll to top of table function
  const scrollToTop = () => {
    // Find the table body element and scroll to top
    const tableBody = document.querySelector(".ant-table-body");
    if (tableBody) {
      tableBody.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  // Handle page change
  const handlePageChange = async (page) => {
    // Don't do anything if we're trying to go to the current page
    if (page === currentPage) return;

    try {
      setLoadingMore(true);

      // If going to next page and we have a lastKey
      if (page > currentPage && currentLastKey) {
        // This is the key change - we need to explicitly pass a marker to tell the parent component
        // that this is a pagination request, so it will replace the results with the next page
        const result = await fetchFn(filters, {
          limit: initialPaginationSize,
          lastKey: currentLastKey,
          isPaginationRequest: true, // Flag to identify pagination requests
        });

        // Update the lastKey from the response with additional validation
        if (
          result?.pagination?.lastKey &&
          result.pagination.lastKey !== "" &&
          result.pagination.lastKey !== "null" &&
          result.pagination.lastKey !== "undefined"
        ) {
          setCurrentLastKey(result.pagination.lastKey);
          setHasMore(true);
        } else {
          // No more data to load
          setHasMore(false);
          setCurrentLastKey(null);
        }

        setCurrentPage(page);

        // Scroll to top after data is loaded and rendered
        setTimeout(scrollToTop, 100);
      }
      // If going to first page
      else if (page === 1) {
        // Reset and fetch first page
        const result = await fetchFn(filters, { limit: initialPaginationSize, initialLoad: true });

        // Check if we have a valid lastKey in the response
        if (
          result?.pagination?.lastKey &&
          result.pagination.lastKey !== "" &&
          result.pagination.lastKey !== "null" &&
          result.pagination.lastKey !== "undefined"
        ) {
          setCurrentLastKey(result.pagination.lastKey);
          setHasMore(true);
        } else {
          setHasMore(false);
          setCurrentLastKey(null);
        }

        setCurrentPage(1);
        initialLoadRef.current = false;

        // Scroll to top after data is loaded and rendered
        setTimeout(scrollToTop, 100);
      }
    } catch (error) {
      console.error("Error changing page:", error);
      // On error, disable pagination to be safe
      setHasMore(false);
    } finally {
      setLoadingMore(false);
    }
  };

  // Handle sorting if needed
  const handleTableChange = (_, tableFilters, sorter) => {
    // No-op for now, can be expanded if sorting is added later
  };

  // Filter columns based on hideColumns prop
  const filteredColumns = columns.filter((col) => !hideColumns.includes(col.key));

  return (
    <div className="message-logs-container me-3">
      <Table
        columns={filteredColumns}
        dataSource={data}
        loading={loading ? { indicator: <LoadingOutlined spin style={{ color: "#605BFF" }} /> } : false}
        locale={locale}
        rowClassName={"message-logs-row"}
        scroll={{
          x: "100%",
          y: `calc(100dvh - ${filterConfig?.tableSize}px)`, // Adjusted for pagination component
        }}
        tableLayout={"auto"}
        onChange={handleTableChange}
        pagination={false} // Disable default pagination
        className={`message-logs-table px-3`}
        bordered={false}
        rowKey={(record) => {
          // Create a unique key for each row by combining multiple identifiers
          return (
            record.id ||
            record.messageId ||
            record.internalMessageId ||
            `${record.sender}-${record.receiver}-${record.createdTime}-${Math.random().toString(36).substr(2, 9)}`
          );
        }}
        onRow={(record, rowIndex) => ({
          onClick: (event) => {
            // Don't navigate if clicking on interactive elements
            if (
              event &&
              (event.target.tagName === "A" || event.target.closest("a") || event.target.tagName === "BUTTON" || event.target.closest("button"))
            ) {
              return;
            }
            if (onViewMessage) {
              onViewMessage(record);
            }
          },
        })}
        footer={() => (
          <div className="d-flex justify-content-between align-items-center pt-2">
            <div>{data && data.length > 0 && <span className="ps-2">Page {currentPage}</span>}</div>
            <div className="d-flex align-items-center pagination-footer">
              {loadingMore && <Spin size="small" className="me-3" />}
              {data && data.length > 0 && (
                <>
                  <Button disabled={currentPage === 1 || loading || loadingMore} onClick={() => handlePageChange(1)} className="me-2">
                    First
                  </Button>
                  <Button
                    disabled={!hasMore || loading || loadingMore || data.length === 0}
                    className="paginationLogsButton"
                    onClick={() => handlePageChange(currentPage + 1)}
                    type="primary"
                    icon={loadingMore ? <LoadingOutlined /> : null}
                    title={!hasMore || data.length === 0 ? "No more pages" : "Go to next page"}
                  >
                    {loadingMore ? "Loading..." : "Next"}
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      />
    </div>
  );
});

export default MessageLogsTable;
