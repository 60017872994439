import { Checkbox as AntDCheckbox, Col, Row } from "antd";
import React from "react";
import LabelAndValue from "../../form/LabelAndValue";
import Checkbox from "./Checkbox";
import useCheckbox from "./hooks/useCheckbox";

const CheckboxGroup = (props) => {
  const {
    items,
    indexOfDefaultSelectedValue = 1,
    actAsRadioButton,
    groupLabel,
    value,
    handleOnChange,
    key = "hs-checkbox-group",
    ...restProps
  } = props;

  const checkAtLeastOneBoxIsSelected = (values) => {
    return values?.length > 0;
  };

  const { currentSelectedItems, callOnChange } = useCheckbox({
    allowMultiSelect: !actAsRadioButton,
    indexOfDefaultSelectedValue,
    items,
    handleBeforeChange: actAsRadioButton ? checkAtLeastOneBoxIsSelected : null,
    handleOnChange,
  });

  return (
    <LabelAndValue label={groupLabel}>
      <AntDCheckbox.Group
        {...restProps}
        id={"hs-checkbox-group"}
        name={groupLabel}
        key={key}
        value={value ?? currentSelectedItems}
        onChange={callOnChange}
        className={`${restProps?.customClasses}`}
      >
        <Row id={"hs-checkbox-group-inner"} className="w-100" gutter={[16, 16]}>
          {items?.map((item, index) => (
            <Col key={item.value} span={9}>
              <Checkbox key={index} item={item} />
            </Col>
          ))}
        </Row>
      </AntDCheckbox.Group>
    </LabelAndValue>
  );
};

export default React.memo(CheckboxGroup);
