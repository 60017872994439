import React, { useCallback, useContext, useEffect, useState } from "react";
import { useReactFlow } from "reactflow";
import { Button, message } from "antd";
import useHttp from "../../hooks/useHttp";
import { isEqual } from "lodash";
import { useNavigate, useLocation, useBlocker } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { CommonModal } from "../../pages/utils/CommonVessels";
import "../assets/css/nodes.css";
import HButton from "../../components/custom/input/button/Button";
import Input from "../../components/custom/input/input/Input";
const FlowFooter = ({
  initialPhoneNumberData,
  initialBusinessHourData,
  initialWelcomeMusicData,
  initialCallWaitingMusicData,
  initialTeamData,
  initialBusyMusicData,
  initialUnavailableMusicData,
  initialCallRecordingData,
  callFlowId,
  phoneNumber,
  callFlowName,
  setCallFlowName,
  integId,
}) => {
  const [phoneNumberData] = useState(initialPhoneNumberData?.apply);
  const [businessHourData] = useState(initialBusinessHourData?.apply);
  const [welcomeMusicData] = useState(initialWelcomeMusicData?.apply);
  const [callWaitingMusicData] = useState(initialCallWaitingMusicData?.apply);
  const [teamData] = useState(initialTeamData?.apply);
  const [busyMusicData] = useState(initialBusyMusicData?.apply);
  const [unavailableMusicData] = useState(initialUnavailableMusicData?.apply);
  const [callRecordingData] = useState(initialCallRecordingData?.apply);
  const [loading, setLoading] = useState(false);
  const [hasAppliedData, setHasAppliedData] = useState(false);
  const [flowNameProp, setFlowNameProp] = useState(callFlowName);

  const checkForChanges = useCallback(() => {
    return (
      !isEqual(phoneNumberData, initialPhoneNumberData?.apply) ||
      !isEqual(businessHourData, initialBusinessHourData?.apply) ||
      !isEqual(welcomeMusicData, initialWelcomeMusicData?.apply) ||
      !isEqual(callWaitingMusicData, initialCallWaitingMusicData?.apply) ||
      !isEqual(teamData, initialTeamData?.apply) ||
      !isEqual(busyMusicData, initialBusyMusicData?.apply) ||
      !isEqual(unavailableMusicData, initialUnavailableMusicData?.apply) ||
      !isEqual(callRecordingData, initialCallRecordingData?.apply) ||
      !isEqual(flowNameProp, callFlowName)
    );
  }, [
    phoneNumberData,
    initialPhoneNumberData?.apply,
    businessHourData,
    initialBusinessHourData?.apply,
    welcomeMusicData,
    initialWelcomeMusicData?.apply,
    callWaitingMusicData,
    initialCallWaitingMusicData?.apply,
    teamData,
    initialTeamData?.apply,
    busyMusicData,
    initialBusyMusicData?.apply,
    unavailableMusicData,
    initialUnavailableMusicData?.apply,
    callRecordingData,
    initialCallRecordingData?.apply,
    callFlowName,
    flowNameProp,
  ]);

  useEffect(() => {
    setFlowNameProp(callFlowName);
  }, [callFlowName]);

  useEffect(() => {
    setHasAppliedData(checkForChanges());
    setHasUnsavedChanges(checkForChanges());
  }, [checkForChanges]);

  const { fetchData } = useHttp();
  const navigate = useNavigate();
  const location = useLocation();
  const [appProperties] = useContext(AppContext);
  const path = window.location.pathname;
  const { getEdges, getNodes } = useReactFlow();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [stateForCancel, setStateForCancel] = useState(false);
  const [showModel, setShowModel] = useState(false);
  // const blocker = useBlocker(hasUnsavedChanges);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => hasUnsavedChanges && currentLocation.pathname !== nextLocation.pathname);

  const handleCancel = () => {
    setStateForCancel(true);
    if (hasUnsavedChanges) {
      setShowModel(true);
    } else {
      navigate("/settings/number/twilio_voice" + location.search);
    }
  };

  const handleDiscardChanges = () => {
    if (blocker.state === "blocked") {
      setHasUnsavedChanges(false);
      setShowModel(false);
      blocker.proceed();
      navigate("/settings/number/twilio_voice" + location.search);
    } else {
      navigate("/settings/number/twilio_voice" + location.search);
    }
  };

  async function getFileHash(file) {
    const arrayBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest("SHA-256", arrayBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
    return hashHex;
  }
  const handleSAVE = () => {
    setHasUnsavedChanges(false);
    const formData1 = new FormData();
    const files = [
      initialPhoneNumberData,
      initialWelcomeMusicData,
      initialCallRecordingData,
      initialBusyMusicData,
      initialCallWaitingMusicData,
      initialUnavailableMusicData,
    ];

    const requestData = [];
    const promises = files.map((musicFile) => {
      const nodeId = musicFile?.nodeId;
      const file = musicFile?.music;

      if (file instanceof File) {
        const fileExtension = file.name.split(".").pop().toLowerCase();
        if (["mp3", "wav", "ogg"].includes(fileExtension)) {
          formData1.append("files", file);
          return getFileHash(file).then((hash) => {
            requestData.push({
              nodeId: nodeId,
              hashCode: hash,
              text: null,
              url: null,
            });
          });
        }
      } else if (typeof file === "string") {
        const urlPattern = new RegExp("^(https?:\\/\\/)?");
        if (urlPattern.test(file) && file.startsWith("http")) {
          requestData.push({
            nodeId: nodeId,
            hashCode: null,
            text: null,
            url: file,
          });
        } else {
          requestData.push({
            nodeId: nodeId,
            hashCode: null,
            text: file,
            url: null,
          });
        }
      }
      return Promise.resolve();
    });

    Promise.all(promises)
      .then(() => {
        const nodes = getNodes();
        const edges = getEdges();
        const teamNode = nodes.find((node) => node.data && node.data.teamId);
        console.log("requestData", requestData);

        const payload = {
          callFlowData: JSON.stringify({
            nodes: nodes,
            edges: edges,
            callFlowName: flowNameProp,
            callFlowId: callFlowId,
            teamId: teamNode?.data?.teamId,
          }),
          numberVoiceConfig: JSON.stringify({
            phoneNumber: phoneNumber,
            fallBackNumber: initialPhoneNumberData?.fallBackNumber,
            ringingType: initialTeamData?.ringingType,
            ringInterval: initialTeamData?.ringInterval,
            repeatCount: initialTeamData?.repeatCount,
            voiceMailStatus: initialPhoneNumberData?.voiceMailStatus,
            callRecordStatus: initialCallRecordingData?.recordStatus,
            businessHourId: initialBusinessHourData?.businessHourId,
            businessHourType: initialBusinessHourData?.isCalendarHours === true ? 1 : 2,
          }),
          businessHourData: JSON.stringify({
            isCalendarHours: initialBusinessHourData?.isCalendarHours,
            timeZone: initialBusinessHourData?.timeZone,
            businessHourId: initialBusinessHourData?.businessHourId,
            businessDays: initialBusinessHourData?.businessDays,
          }),
          nodeDataList: JSON.stringify(requestData),
        };

        const dataBlobEncodedInUtf8 = new Blob([JSON.stringify(payload)], {
          type: "application/json; charset=UTF-8",
        });
        formData1.append("data", dataBlobEncodedInUtf8);

        setLoading(true);

        return fetchData(`voice/${integId}/callFlow/${callFlowId}`, "POST", formData1, appProperties);
      })
      .then((res) => {
        const response = JSON.parse(res);
        if (response?.data) {
          message.success("Call Flow Updated Successfully");
          navigate("/settings/number/twilio_voice" + location.search);
        }
      })
      .catch((error) => {
        console.error("Error during upload:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  const handleCloseModal = () => {
    setShowModel(false);
    if (blocker.state === "blocked") {
      blocker.reset();
    }
  };

  const modalBody = (
    <>
      <div>
        <div className="ms-2">
          <h5 className="hs-color-dark-blue">Unsaved changes</h5>
          <div className="hs-color-dark-blue">Do you want to save or discard changes?</div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <Button className="mt-2 row-reverse d-inline-block hs-btn-cancel-small w-100 h-45" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button
          className="hs-btn-small ms-3 mt-2 row-reverse d-inline-block w-100 h-45 hs-fw-600 hs-fs-16 confirmDeleteBtn"
          onClick={handleDiscardChanges}
        >
          Discard
        </Button>
      </div>
    </>
  );
  return (
    <>
      <CommonModal
        width={350}
        body={modalBody}
        open={blocker.state === "blocked" || showModel}
        doOnClose={handleCloseModal}
        cancelButtonProps={false}
        okButtonProps={false}
        modalCloseIcon={true}
        header={<div style={{ cursor: "default" }} className="actionIconsSpriteForSideBar unsaved-modal-icon" />}
      />

      <div className={`d-flex flex-row justify-content-between align-items-center w-100 h-100 px-3`}>
        <div className="d-flex  align-items-center pt-2 pb-2">
          <Input
            customClasses="hs-h-40-px"
            value={flowNameProp != null && flowNameProp !== undefined ? flowNameProp : "Call Flow"}
            disabled={path.includes("/flow")}
            onChange={(e) => setFlowNameProp(e.target.value)}
          />
        </div>
        <div className="d-flex justify-content-between hs-w-40">
          <HButton buttonClassName="hs-w-45" onClick={handleCancel} variant="cancel">
            Cancel
          </HButton>
          <HButton buttonClassName="w-50" onClick={handleSAVE} disabled={!hasAppliedData} loading={loading}>
            Save
          </HButton>
        </div>
      </div>
    </>
  );
};

export default FlowFooter;
