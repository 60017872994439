import { Button, Col, Collapse, Divider, List, Row } from "antd";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../assets/css/channelsConfig.css";
import { AppContext } from "../../context/AppContext";
import { handleBuyNumberClick, UpgradeAlertPopup } from "../utils/CommonVessels";
import useHttp from "../../hooks/useHttp";
import BuyNumber from "./BuyNumber";
import { COMPANY_NAME } from "../../constants/AppConstants";
import { useVoiceContext } from "../../voice/context/VoiceContext";
import { getCurrentFeature } from "../../voice/utils/VoiceUtils";
import { useVoiceNumbers } from "../../voice/hooks/useVoiceNumbers";

const { Panel } = Collapse;

const ChannelsConfiguration = () => {
  const navigate = useNavigate();
  const [appProperties, setAppProperties] = useContext(AppContext);
  const [callProperties, setCallProperties] = useVoiceContext();
  const location = useLocation();
  const { fetchData } = useHttp();
  const [smsConnected, setSmsConnected] = useState(false);
  const [smsProviders, setSmsProviders] = useState([]);
  const [callConnected, setCallConnected] = useState(false);
  const [whatsAppConnected, setWhatsAppConnected] = useState(false);
  const [buyNumberLoading, setBuyNumberLoading] = useState(false);
  const [showBuyNumber, setShowBuyNumber] = useState(false);
  const [upgradeAlertPopup, setUpgradeAlertPopup] = useState(false);
  const { getVoiceSavedNumbers } = useVoiceNumbers();
  const voiceIntegrations = useMemo(
    () => appProperties?.installedApps?.filter((integration) => integration.right.serviceType === "VOICE"),
    [appProperties]
  );
  const voiceIntegId = useMemo(() => voiceIntegrations?.[0]?.integProps?.integId, [voiceIntegrations]);

  useEffect(() => {
    const currentPlanName = getCurrentFeature(appProperties);
    if (voiceIntegId && !upgradeAlertPopup && currentPlanName !== "Message" && callProperties?.savedNumbers?.length === 0) {
      getVoiceSavedNumbers("all")
        .then((response) => {
          if (response) {
            setCallProperties((prev) => ({
              ...prev,
              savedNumbers: response,
            }));
          }
        })
        .catch((err) => {
          console.log("Error fetching SavedNumbers:", err);
        });
    }
  }, [getVoiceSavedNumbers, appProperties, voiceIntegId, upgradeAlertPopup, callProperties?.savedNumbers?.length, setCallProperties]);
  useEffect(() => {
    const helloSendIntegId = appProperties?.installedApps?.find((app) => app?.right?.service_display_name === "Hello Send")?.integProps?.integId;
    appProperties?.installedApps?.forEach((app) => {
      const serviceType = app?.right?.serviceType;
      if (serviceType === "VOICE" && app?.right?.auth?.authorized && callProperties?.savedNumbers?.length > 0) {
        setCallConnected(true);
      }
      if (serviceType === "SMS" && app?.right?.auth?.authorized) {
        const isSmsConnected = appProperties?.allNumbers?.some(
          (number) =>
            number?.serviceName === "twilio" ||
            number?.serviceName === "ringcentral" ||
            number?.integId === helloSendIntegId ||
            number?.serviceName === "teams_helloSend"
        );
        setSmsConnected(isSmsConnected);
      }
      if (serviceType === "WHATSAPP" && app?.right?.auth?.authorized) {
        const isSmsConnected = appProperties?.allNumbers?.some((number) => number?.serviceName === "whatsapp");
        setWhatsAppConnected(isSmsConnected);
      }
    });
  }, [appProperties?.allNumbers, appProperties?.installedApps, callProperties?.savedNumbers]);

  const FeatureListTitle = ({ isConnected, title }) => {
    return (
      <div className="d-flex flex-row align-items-center pt-2 pb-1">
        {" "}
        <div className="onboarding-list-title pe-2">{title}</div>
        {isConnected && (
          <div className="hs-bg-light-green1 d-flex flex-row hs-border-1-25-rem p-1 px-2">
            <div className="ob-service-connected-tick-icon actionIconsSprite" />
            <div className="hs-color-light-green2 hs-fw-500">Connected</div>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    const currentParams = new URLSearchParams(window.location.search);
    const newParams = currentParams?.toString();
    if (appProperties?.hideChannels) {
      navigate(`/settings/?${newParams}`);
    }
  }, [appProperties, navigate]);

  const featuresList = [
    {
      key: "1",
      title: <FeatureListTitle isConnected={smsConnected} title={"SMS "} />,
      description: "Easily integrate Twilio or RingCentral accounts, or purchase a dedicated number for Direct, Bulk, and Automated SMS.",
      icon: <div className="onboarding-sms-icon actionIconsSprite " />,
      isAccordion: true,
    },
    {
      key: "2",
      title: <FeatureListTitle isConnected={callConnected} title={"Call "} />,
      description: "Seamlessly connect Twilio numbers to stay in touch with clients via personalized calls.",
      icon: (
        <div className="d-flex align-items-center">
          <div className="onboarding-call-icon call-action-icons" />
        </div>
      ),
      onClick: () => {
        const currentPlanName = getCurrentFeature(appProperties);
        if (currentPlanName === "Message") {
          setUpgradeAlertPopup(true);
        } else {
          navigate("/settings/number/twilio_voice" + location.search);
        }
      },
    },
    {
      key: "3",
      title: <FeatureListTitle isConnected={whatsAppConnected} title={"WhatsApp"} />,
      description:
        "Link a WhatsApp Business account to engage with clients using dynamic, interactive messaging, including campaigns or automated messaging.",
      icon: <div className="onboarding-whatsapp-icon actionIconsSprite mt-2" />,
      onClick: () => {
        navigate("/settings/channels/whatsapp" + location.search);
      },
    },
  ];

  useEffect(() => {
    if (!appProperties?.apps?.SMS) return;

    const serviceConfig = {
      twilio: {
        icon: "staticIconsSprite smallSize-twilio-icon",
        route: "/settings/twilio",
      },
      ringcentral: {
        icon: "staticIconsSprite smallSize-ringcentral-icon",
        route: "/channels/textsms/ringcentral",
      },
      hello_send: {
        icon: "sprite-icon channels-helloSend-icon",
        route: "/channels/textsms/helloSend",
      },
      teams_helloSend: {
        icon: "staticIconsSprite topBarTeams",
        route: "/channels/textsms/teams_helloSend",
      },
    };
    const serviceOrder = ["twilio", "ringcentral", "hello_send", "teams_helloSend"];

    const helloSendIntegId = appProperties?.installedApps?.find((app) => app?.right?.service_display_name === "Hello Send")?.integProps?.integId;
    const filteredSMSServices = appProperties?.apps?.SMS?.filter(
      (service) => !(service.name === "teams_helloSend" && appProperties?.leftServiceName !== "teams")
    );
    const updatedSMSServices = filteredSMSServices
      ?.map((service) => ({
        ...service,
        ...(serviceConfig[service.name] || {}),
        onClick: () => navigate(`${serviceConfig[service.name]?.route || ""}${location.search}`),
        icon: serviceConfig[service.name]?.icon || service.icon,
      }))
      .sort((a, b) => {
        const indexA = serviceOrder.indexOf(a.name);
        const indexB = serviceOrder.indexOf(b.name);

        if (indexA !== -1 && indexB !== -1) return indexA - indexB;
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;
        return 0;
      })
      .map((service) => {
        const installedApp = appProperties?.installedApps?.find((app) => {
          return (
            app?.right?.service_name === service?.name &&
            app?.right?.auth?.authorized &&
            appProperties?.allNumbers?.some((number) => {
              if (service?.name === "hello_send") {
                return number?.integId === helloSendIntegId;
              }
              return number?.serviceName === service?.name;
            })
          );
        });

        return {
          ...service,
          status: installedApp ? "connected" : "not_connected",
        };
      });
    setSmsProviders(updatedSMSServices);
  }, [appProperties?.apps, navigate, location.search, appProperties?.installedApps, appProperties?.allNumbers, appProperties?.leftServiceName]);

  const closeDrawer = () => {
    setShowBuyNumber(false);
  };
  return (
    <div className="d-flex align-items-center flex-column h-100">
      <BuyNumber open={showBuyNumber} closeDrawer={closeDrawer} />
      <div className="d-flex align-items-center flex-column gap-4 hs-h-20 justify-content-center">
        <div className="onBoardingTitle">Welcome to HelloSend!</div>
        <div className="onboarding-description ">Click on the channel below to begin the configuration process.</div>
      </div>

      <List
        itemLayout="horizontal"
        className="rounded-3 onboarding-list border-default"
        dataSource={featuresList}
        style={{ width: "", backgroundColor: "#ffffff" }}
        renderItem={(item) =>
          item.isAccordion ? (
            <Collapse
              ghost
              style={{ width: "100%" }}
              expandIconPosition="end"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <div className="call-action-icons close-arrow me-lg-3 mt-lg-1" />
                ) : (
                  <div className="mt-lg-5 me-lg-4 call-action-icons expand-arrow" />
                )
              }
              className="pt-2  onboarding-collapse"
            >
              <Panel
                className="h-100"
                onClick={(e) => e.stopPropagation()}
                header={
                  <Row className="d-flex align-items-center w-100">
                    <Col span={1}>{item.icon}</Col>
                    <Col span={23}>
                      <div className="d-flex flex-column ms-3 ps-1 mb-2 ">
                        <span>{item.title}</span>
                        <span className="onboardingDescription">{item.description}</span>
                      </div>
                    </Col>
                  </Row>
                }
                key={item?.key}
              >
                {smsProviders.map((provider, idx) => (
                  <div className="ps-4 w-100 py-2" key={idx}>
                    {provider.name === "hello_send" && provider.status === "not_connected" && !showBuyNumber ? (
                      <div className="d-flex justify-content-center align-items-center w-100">
                        <div className="hs-fw-500">
                          Need a number? Get one with {COMPANY_NAME}, starting at just $1.25
                          <span>
                            {" "}
                            <Button
                              type="link"
                              className="text-primary"
                              onClick={() => {
                                handleBuyNumberClick(
                                  appProperties,
                                  setAppProperties,
                                  fetchData,
                                  setBuyNumberLoading,
                                  setShowBuyNumber,
                                  navigate,
                                  "hello_send"
                                );
                              }}
                              loading={buyNumberLoading}
                            >
                              Buy now.
                            </Button>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex ps-4 justify-content-start cursor-pointer align-items-center w-50" onClick={provider.onClick}>
                        <div className="d-flex w-100">
                          <div className="d-flex p-2 provider-collapse-panel w-100 align-items-center">
                            <div className="hs-w-12">{provider.icon && <div className={`${provider.icon}`} />}</div>
                            <div className=" hs-fw-600 ">{provider.displayName}</div>
                            {provider.status === "connected" && (
                              <div className="hs-bg-light-green1 d-flex flex-row hs-border-1-25-rem p-1 px-2 ms-2">
                                <div className="ob-service-connected-tick-icon actionIconsSprite" />
                                <div className="hs-color-light-green2 hs-fw-500">Connected</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </Panel>
              <Divider className="mb-0" />
            </Collapse>
          ) : (
            <List.Item className="cursor-pointer channels-feature-list" onClick={item?.onClick}>
              <List.Item.Meta
                className="ps-3 pb-2 d-flex align-items-center "
                avatar={item.icon}
                title={item.title}
                description={item.description}
              />
              <div className="expand-arrow call-action-icons expandArrowPostion"> </div>
            </List.Item>
          )
        }
      />
      {upgradeAlertPopup && <UpgradeAlertPopup upgradeAlertPopup={upgradeAlertPopup} setUpgradeAlertPopup={setUpgradeAlertPopup} />}
    </div>
  );
};

export default ChannelsConfiguration;
