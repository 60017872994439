import { Tooltip } from "antd";

const icon = <div className="actionIconsSprite pricingFeatureIcon cursor-default"></div>;
const comingSoonIcon = <Tooltip className="actionIconsSprite pricingFeatureComingSoonIcon cursor-default" title="Coming soon"></Tooltip>;

export const messagePlanComparisonData = [
  { key: "0", feature: "", monthly: "Monthly Plan", yearly: "Yearly Plan" },
  { key: "1", feature: "SMS Channel", monthly: icon, yearly: icon },
  { key: "2", feature: "WhatsApp Channel", monthly: icon, yearly: icon },
  { key: "4", feature: "One-on-One messaging", monthly: icon, yearly: icon },
  { key: "5", feature: "SMS / WhatsApp Campaigns", monthly: icon, yearly: icon },
  { key: "6", feature: "Automated Messaging", monthly: icon, yearly: icon },
  { key: "7", feature: "Templates for canned responses", monthly: icon, yearly: icon },
  { key: "8", feature: "Keyword Management", monthly: comingSoonIcon, yearly: comingSoonIcon },
  { key: "9", feature: "Messaging Analytics", monthly: icon, yearly: icon },
  { key: "10", feature: "Inbox", monthly: icon, yearly: icon },
  { key: "12", feature: "Priority Support", monthly: "Standard", yearly: "Faster response time" },
  { key: "13", feature: "Call Assistance", monthly: "No", yearly: "Yes*" },
  { key: "14", feature: "Onboarding Assistance", monthly: "Chargeable", yearly: "Free" },
  { key: "15", feature: "Loyalty Discounts", monthly: "No", yearly: "Yes (10% off)" },
];

export const voicePlanComparisonData = [
  { key: "0", feature: "", monthly: "Monthly Plan", yearly: "Yearly Plan" },
  { key: "1", feature: "Business Hours Management", monthly: icon, yearly: icon },
  { key: "2", feature: "Zoho CRM Integration", monthly: icon, yearly: icon },
  { key: "4", feature: "Pipedrive Integration", monthly: icon, yearly: icon },
  { key: "5", feature: "Inbound Configuration for Teams", monthly: icon, yearly: icon },
  { key: "6", feature: "Intelligent Call Routing", monthly: icon, yearly: icon },
  { key: "7", feature: "Call Flow", monthly: icon, yearly: icon },
  { key: "8", feature: "Pre-Recorded Messages", monthly: icon, yearly: icon },
  { key: "9", feature: "Voicemail Support", monthly: icon, yearly: icon },
  { key: "10", feature: "Call Recording", monthly: icon, yearly: icon },
  { key: "12", feature: "Call Logging", monthly: icon, yearly: icon },
  { key: "13", feature: "Call Notes", monthly: icon, yearly: icon },
  { key: "14", feature: "Call History", monthly: icon, yearly: icon },
  { key: "16", feature: "International Calls", monthly: icon, yearly: icon },
  { key: "18", feature: "Priority Support Response Time", monthly: "Standard", yearly: "Faster response time" },
  { key: "19", feature: "Call Assistance", monthly: "No", yearly: "Yes" },
  { key: "20", feature: "Onboarding Assistance", monthly: "Chargeable", yearly: "Free" },
  { key: "21", feature: "Loyalty Discounts", monthly: "No", yearly: "Yes (10% of on renewals)" },
];

export const messagePlusVoicePlanComparisonData = [
  { key: "0", feature: "", monthly: "Monthly Plan", yearly: "Yearly Plan" },
  { key: "1", feature: "Intelligent Call Routing", monthly: icon, yearly: icon },
  { key: "2", feature: "Zoho CRM & Pipedrive Integration", monthly: icon, yearly: icon },
  { key: "3", feature: "Call Recording ", monthly: icon, yearly: icon },
  { key: "4", feature: "Automated Messaging & SMS Campaigns", monthly: icon, yearly: icon },
  { key: "5", feature: "Business Hours Management", monthly: icon, yearly: icon },
  { key: "6", feature: "Pre-Recorded Messages & Voicemail Support", monthly: icon, yearly: icon },
  { key: "7", feature: "Call Logging, History, & Notes", monthly: icon, yearly: icon },
  { key: "8", feature: "Centralized Inbox for Calls & Messages", monthly: icon, yearly: icon },
  { key: "9", feature: "Opt-In/Opt-Out Management", monthly: comingSoonIcon, yearly: comingSoonIcon },
  { key: "10", feature: "Actionable Analytics & Reporting", monthly: icon, yearly: icon },
  { key: "12", feature: "Priority Support Response Time", monthly: "Standard", yearly: "Faster response time" },
  { key: "13", feature: "Call Assistance", monthly: "No", yearly: "Yes" },
  { key: "14", feature: "Onboarding Assistance", monthly: "Chargeable", yearly: "Free" },
  { key: "15", feature: "Loyalty Discounts", monthly: "No", yearly: "Yes (10% of on renewals)" },
];
