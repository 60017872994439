import { Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import React, { useContext, useEffect, useState } from "react";

import { AppContext } from "../../context/AppContext";

const InfiniteVirtualTable = React.memo((props) => {
  const { data, columns, handleRowClick, fetchFn, loading, noDataComponent, noDataOverlayIcon, headerActions } = props;
  const [appProperties] = useContext(AppContext);
  const [isAllDataAreFetched, setIsAllDataAreFetched] = useState();

  const locale = {
    emptyText: (
      <div style={{ height: "65dvh" }}>
        <div className="d-flex flex-grow-* gap-5 flex-column align-items-center justify-content-center h-100">
          <div className="d-flex gap-3 flex-column align-items-center">
            {noDataOverlayIcon}
            {noDataComponent}
          </div>
        </div>
      </div>
    ),
  };

  const rowRenderer = async (_, rowIndex) => {
    // Only load more if we're not already at the end, not loading, and at the last visible row
    if (!isAllDataAreFetched && !loading && data && rowIndex >= data.length - 3) {
      const isAllDataFetched = await fetchFn();
      setIsAllDataAreFetched(!!isAllDataFetched);
    }
    return {};
  };

  // Initial data load - only load first batch
  useEffect(() => {
    async function fetchData() {
      if (appProperties?.installedApps && !data?.length) {
        const isAllDataFetched = await fetchFn();
        setIsAllDataAreFetched(!!isAllDataFetched);
      }
    }
    fetchData();
  }, [fetchFn, appProperties?.installedApps, data]);

  return (
    <>
      {headerActions && (
        <div className="d-flex justify-content-between align-items-center px-4 py-2">
          <div className="fw-bold fs-5">Campaign</div>
          <div>{headerActions}</div>
        </div>
      )}
      <Table
        pagination={false}
        bordered={false}
        columns={columns}
        loading={loading ? { indicator: <LoadingOutlined spin style={{ color: "#605BFF" }} /> } : false}
        locale={locale}
        rowClassName={"overviewTableRow"}
        scroll={{ x: 700, y: "calc(100vh - 230px)" }}
        onRow={(record, rowIndex) => ({
          ...rowRenderer(record, rowIndex),
          onClick: (event) => (handleRowClick ? handleRowClick(record, event) : undefined),
        })}
        virtual
        className="campaignOverviewTable px-3"
        dataSource={data}
        size="small"
      />
    </>
  );
});

export default InfiniteVirtualTable;
