import React from "react";
import Link from "../../../components/custom/info/Link";
import HButton from "../../../components/custom/input/button/Button";

//####################### SMS #####################//
export const getSmsSteps = () => [
  {
    title: "Create & Send Campaign Messages",
    description: (
      <div>
        Start by reaching a larger audience with SMS campaigns, sending promotions, updates, or important information directly to your contacts.
      </div>
    ),
  },
  {
    title: "Automate Your Messaging",
    description: (
      <div>
        Once your customers engage with your campaigns, set up automated responses based on workflows, ensuring timely updates without extra
        effort.
      </div>
    ),
  },
  {
    title: "Enable 1-1 Personalized Messages",
    description: (
      <div>
        For deeper connections, engage directly with customers through personalized SMS, building stronger relationships with each message.
      </div>
    ),
  },
];

//####################### VOICE #####################//

export const getVoiceSteps = (callFlowEndPoint, appProperties) => [
  // {
  //   title: "Contact Number",
  //   description: "Log in to your Twilio account and activate voice-enabled numbers.",
  //   videoUrl: "https://youtu.be/BDpZwOn6jJY",
  // },
  {
    title: "Set Up Call Teams",
    description: (
      <div>
        Assign users to teams to enable both incoming and outgoing calls. By default, HelloSend adds the current user to the default team with
        full call capabilities. Manage users, call settings, and teams
        <Link appProperties={appProperties} baseUrl={`/voice/teams?`} textClasses="hs-fw-500 hs-color-violet">
          here.
        </Link>
      </div>
    ),
  },
  {
    title: "Set Up Call Flow",
    description: (
      <div>
        A Call Flow manages how incoming calls are routed and handled, including options like Welcome/Busy messages, fallback numbers for
        redirection, and business hours settings. To receive incoming calls on enabled numbers, setting up a Call Flow is mandatory. HelloSend
        creates a default Call Flow with generalized content, which you can verify and customize
        <Link appProperties={appProperties} baseUrl={`${callFlowEndPoint}?`} textClasses="hs-color-violet hs-fw-500">
          here.
        </Link>
      </div>
    ),
  },
];

//####################### WHATSAPP #####################//

export const getWhatsAppSteps = (WHATSAPP_DISPLAY_NAME, appProperties) => [
  {
    title: (
      <>
        Authorization of {WHATSAPP_DISPLAY_NAME} Service -
        <Link externalLink baseUrl={"https://youtu.be/FgJ_OhCa0UA"}>
          How to Authorize
        </Link>
      </>
    ),
  },
  {
    title: (
      <>
        Configuration of {WHATSAPP_DISPLAY_NAME}-Enabled Number -
        <Link externalLink baseUrl={"https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration"}>
          Setup Guide
        </Link>
      </>
    ),
  },
  {
    title: (
      <>
        Importing of {WHATSAPP_DISPLAY_NAME} Templates -
        <Link appProperties={appProperties} baseUrl={"/settings/templates?openTemplate=true&ttype=wp&"}>
          Add Templates
        </Link>
      </>
    ),
  },
];
