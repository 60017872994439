import { Flex } from "antd";
import SavedTemplatesSelect from "../../pages/outbound/SavedTemplatesSelect";
import EmojiWrapper from "../custom/EmojiWrapper";
import MediaUploadIconWrapper from "../custom/MediaUploadIconWrapper";
import VoiceRecordingButton from "./VoiceRecordingButton";

function MessagingAreaHeader(props) {
  const {
    handleMessage,
    source,
    fileList,
    setFileList,
    integrationId,
    chatView,
    iconsSize,
    hiddenItems = [],
    toggleDrawer,
    isContentPresent,
    showPreviewButton,
    contactId,
    appProperties,
  } = props;

  return (
    <Flex justify="center" align="center" className={`${source === "campaign" || source === "add-template" ? "hs-min-h-50-px" : "h-100"}`}>
      <Flex justify={"space-between"} align={"center"} className={`hs-w-98 hs-max-h-50-px rounded-top ${chatView ? "px-1" : "px-3"}`}>
        <div className={`d-flex gap-md-3 gap-sm-2 w-100 h-100`}>
          <div className="d-flex gap-md-3 gap-sm-2 align-items-center">
            <MediaUploadIconWrapper
              source={source}
              showMMS
              fileList={fileList}
              setFileList={setFileList}
              integId={integrationId}
              iconsSize={iconsSize}
              contactId={contactId}
            />
            <EmojiWrapper handleEmoji={handleMessage} iconsSize={iconsSize} chatView={chatView} />
          </div>
          {/* <div className="d-flex w-25 align-items-center">
            <VoiceRecordingButton
              appProperties={appProperties}
              fileList={fileList}
              handleMediaFileList={setFileList}
              integrationId={integrationId}
              contactId={contactId}
            />
          </div> */}
        </div>
        <div className="d-flex gap-2 align-items-center">
          {isContentPresent && showPreviewButton && (
            <div className={`preview-text cursor-pointer`} onClick={toggleDrawer}>
              Preview
            </div>
          )}
          <div>{!hiddenItems.includes("select-template") && <SavedTemplatesSelect {...props} />}</div>
        </div>
      </Flex>
    </Flex>
  );
}

export default MessagingAreaHeader;
